export const termsData2 = [{
    lastUpdated: `February 13th 2025`,
    text: `Thank you for choosing Pdfing. Your privacy is critically important to us. This Privacy Policy outlines our practices for collecting, using, and safeguarding your information when you use our services, products, websites, and applications (collectively, the “Services”). By using Pdfing Services, you agree to the privacy terms outlined below.`
},
{
    heading: ``,
    text: `<strong>1. Information We Collect</strong></br>We collect information in the following ways`
},
{
    heading: ``,
    text: `<strong>a. Information You Provide Directly</strong></br>Account Information: When you register, we may collect your name, email address, password, and other personal details necessary to provide you with a personalized experience.</br>
Payment Information: If you make a purchase, we may collect your billing information, payment card details, and other information necessary to complete transactions.</br>
Support Interactions: If you contact us for customer support, we may collect details about your request to provide you with the best assistance.`
},
{
    heading: ``,
    text: `<strong>b. Information Collected Automatically</strong></br>Device Information: We collect information about the device you use to access our Services, such as the type of device, operating system, and browser type.</br>
Usage Data: We collect data about how you interact with our Services, including logs of when you access our Services, the features you use, and actions you take.</br>
Cookies and Tracking Technologies: We may use cookies and similar technologies to track your activity on our website and within our apps. This information is used to improve your experience and analyze usage patterns.</br>
`
},
{
    heading: ``,
    text: `<strong>c. Information from Third Parties</strong></br>Device Information: We collect information about the device you use to access our Services, such as the type of device, operating system, and browser type.</br>
Usage Data: We collect data about how you interact with our Services, including logs of when you access our Services, the features you use, and actions you take.</br>
Cookies and Tracking Technologies: We may use cookies and similar technologies to track your activity on our website and within our apps. This information is used to improve your experience and analyze usage patterns.</br>
`
},
{
    heading: ``,
    text: `<strong>2. How We Use Your Information</strong></br>We use the information we collect for various purposes, including to:
</br>- Provide and maintain our Services;
</br>- Process and complete transactions, and send related information, including purchase confirmations and invoices;
</br>- Manage your account and respond to inquiries;
</br>- Improve and personalize your experience with our Services;
</br>- Conduct research and analysis to develop and improve our Services;
</br>- Send you information about new features, offers, and updates, with your consent where required;
</br>- Detect, investigate, and prevent fraudulent transactions, abuse, or security threats; and
</br>- Comply with legal obligations and enforce our terms.
`
},
{
    heading: ``,
    text: `<strong>3. How We Share Your Information</strong></br>We may share your information in the following cases:
</br>- With Service Providers: We may share your information with third-party service providers who assist us in delivering, improving, and supporting our Services.
</br>- With Business Partners: We may share aggregated, anonymized data with our business partners to enhance and analyze the performance of our Services.
</br>- For Legal Reasons: We may disclose your information if required by law or in response to a valid legal process, such as a subpoena, court order, or government demand.
</br>- In Business Transfers: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred to the successor company, subject to this Privacy Policy.
</br>
</br>Sale of Personal Information
</br>Under certain circumstances, Pdfing may sell personal information to third parties, such as marketing partners, to enhance our ability to provide relevant offerings and promotions to our users. If we sell your personal information, we will provide you with an option to opt-out of the sale of your personal information, in accordance with applicable data privacy laws. 
</br>
</br>You may exercise your right to opt out of the sale of your personal information by contacting us at [contact@Pdfing.com] or following instructions provided in the relevant communications.
`
},
{
    heading: `<strong>4. Your Choices and Rights</strong>`,
    text: `Depending on your location, you may have certain rights regarding your personal information. These rights may include:
</br></br>- Access, correction, or deletion of your personal information;
</br>- Restriction or objection to processing your information;
</br>- Data portability;
</br>- Withdrawing your consent at any time; and
</br>- Lodging a complaint with a supervisory authority.
</br>
</br>To exercise these rights, please contact us at [contact@Pdfing.com]. We will respond to your request in accordance with applicable laws.
`
},
{
    heading: `<strong>5. Security of Your Information</strong>`,
    text: `We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no security system is completely secure, and we cannot guarantee the absolute security of your information.
`
},
{
    heading: `<strong>6. Data Retention</strong>`,
    text: `We retain your information only as long as necessary for the purposes outlined in this Privacy Policy or as required by law. When we no longer need your information, we will securely delete or anonymize it.
`
},
{
    heading: `<strong>7. International Data Transfers</strong>`,
    text: `Your information may be transferred to and processed in countries outside your own, including countries with data protection laws different from your own. We take appropriate steps to ensure your information remains protected according to this Privacy Policy.
`
},
{
    heading: `<strong>8. Children’s Privacy</strong>`,
    text: `Our Services are not directed toward children under 13, and we do not knowingly collect personal information from children. If we discover that a child has provided us with personal information, we will promptly delete it.
`
},
{
    heading: `<strong>9. Arbitration Clause</strong>`,
    text: `By using Pdfing Services, you agree that any dispute, claim, or controversy arising out of or related to this Privacy Policy, including the breach, termination, enforcement, interpretation, or validity thereof, shall be settled by binding arbitration administered by JAMS or NAM in New York City, New York. The arbitration will be conducted in English and in accordance with the rules of the selected arbitration company (JAMS or NAM). 

</br></br>The arbitration decision shall be final and binding upon the parties, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction. This arbitration clause does not apply to claims for injunctive or equitable relief, which may be brought in a court of competent jurisdiction.
`
},
{
    heading: `<strong>10. Limitation of Liability</strong>`,
    text: `To the fullest extent permitted by law, Pdfing’s liability for any damages arising from or related to your use of the Services, regardless of the form of action, will at all times be limited to $100 or the amount you have paid to Pdfing for the use of Services in the three (3) months preceding the event giving rise to the claim, whichever is less. 

</br></br>Pdfing shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or goodwill, even if Pdfing has been advised of the possibility of such damages.

`
},
{
    heading: `<strong>11. Changes to this Privacy Policy</strong>`,
    text: `We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of significant changes through our Services or other means, and we encourage you to review this policy periodically.
`
},
{
    heading: `<strong>12. Contact Us</strong>`,
    text: `If you have questions or concerns about this Privacy Policy or our data practices, please contact us at:
</br></br>Pdfing  
</br>4815 Ave N
</br>Brooklyn, NY 11234
</br>Email: dweiss@verisage.ai 
</br>Effective Date: 10/31/24
`
}];
