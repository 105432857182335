// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pulsing {
    animation: pulse-animation 1s ease-in-out infinite;
}

@keyframes pulse-animation {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2); /* Adjust this to control the size increase */
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Products/Summeriser/WhisperAi.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;AACtD;;AAEA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,qBAAqB,EAAE,6CAA6C;IACxE;AACJ","sourcesContent":[".pulsing {\r\n    animation: pulse-animation 1s ease-in-out infinite;\r\n}\r\n\r\n@keyframes pulse-animation {\r\n    0%, 100% {\r\n        transform: scale(1);\r\n    }\r\n    50% {\r\n        transform: scale(1.2); /* Adjust this to control the size increase */\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
