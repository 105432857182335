// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/mainLogo2.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Footer_footer__TL9Uz {
    background-color: rgb(16, 94, 212);
    padding: 20px;
    text-align: center;
    justify-content: space-between;
    padding-right:9em;
    width: 100%;
    height: 5em;
    color: aliceblue;
    display: flex;
    align-items: center;
}
.Footer_mainLogoIcon__7sebS {
    width: 14em;
    height: 3em;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
    background-size: contain;
    filter: invert(100%) brightness(100%) saturate(100%);
  }
  .Footer_besideDetails__i9skw{
    display: flex;
    align-items: center;
    gap: 2em;
  }

/* @media (min-height: 800px) {
    .footer {
        
    }
} */
`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.module.css"],"names":[],"mappings":";AACA;IACI,kCAAkC;IAClC,aAAa;IACb,kBAAkB;IAClB,8BAA8B;IAC9B,iBAAiB;IACjB,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,WAAW;IACX,2EAAqE;IACrE,wBAAwB;IACxB,oDAAoD;EACtD;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;;AAEF;;;;GAIG","sourcesContent":["\r\n.footer {\r\n    background-color: rgb(16, 94, 212);\r\n    padding: 20px;\r\n    text-align: center;\r\n    justify-content: space-between;\r\n    padding-right:9em;\r\n    width: 100%;\r\n    height: 5em;\r\n    color: aliceblue;\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n.mainLogoIcon {\r\n    width: 14em;\r\n    height: 3em;\r\n    background: url('../../assets/mainLogo2.svg') no-repeat center center;\r\n    background-size: contain;\r\n    filter: invert(100%) brightness(100%) saturate(100%);\r\n  }\r\n  .besideDetails{\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 2em;\r\n  }\r\n\r\n/* @media (min-height: 800px) {\r\n    .footer {\r\n        \r\n    }\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__TL9Uz`,
	"mainLogoIcon": `Footer_mainLogoIcon__7sebS`,
	"besideDetails": `Footer_besideDetails__i9skw`
};
export default ___CSS_LOADER_EXPORT___;
