import React, { useState } from 'react';
import styles from './PricingModal.module.css';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CrossIcon } from 'lucide-react';
import { Close } from '@mui/icons-material';

const PricingModal = ({premiumError,setPremiumError,isPremium,setIsPremium}) => {
    const navigate = useNavigate();
    const {user} = useAuth0();
    const [isMonth, setIsMonth] = useState(true);

    const PaymentProcess = async (plan) => {
      

      const apiEndpoint = "https://pdfing.ai/api/usage/create-checkout-session";
      console.log(plan)
  
      try {
          const response = await fetch(apiEndpoint, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              // yealy plan B : plan_a_yearly
              // mont plan A : plan_B
              // A : plan_A
              body: JSON.stringify({
                  "lookup_key": plan,
                  "email": user.email,
                  // "email": "test3@gmail.com"
              }),
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          // Parse the JSON response
          const data = await response.json();
          console.log(data, "Response Data");
  
          // Access the checkout_url
          console.log(data.checkout_url, "Checkout URL");
          window.location.href = data.checkout_url;
      } catch (error) {
          console.error("Error during payment process:", error);
      }
  };
  



  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button onClick={()=>{
          setIsPremium(!isPremium)
          setPremiumError('');
          }} className={styles.closeButton}>×</button>
        
        <div className={styles.modalContent}>
          {/* <h3 className={styles.subtitle}>You've Run Out of Words</h3> */}
          <h2 className={styles.title}>Choose the right plan for you</h2>
          
          <p className={styles.description}>
            {premiumError}
            {/* Upgrade your plan to add more storage and keep summarizing your PDFs without limits. */}
          </p>

          <div className={styles.billingToggle}>
            {/* Monthly Button */}
            <button
              className={`${styles.toggleButton} ${isMonth ? styles.active : ""}`}
              onClick={() => setIsMonth(true)}
            >
              Monthly
            </button>

            {/* Yearly Button */}
            <button
              className={`${styles.toggleButton} ${!isMonth ? styles.active : ""}`}
              onClick={() => setIsMonth(false)}
            >
              Yearly
            </button>
          </div>

          <div className={styles.plansContainer}>
            <div className={styles.planCard}>
              <h3 className={styles.planName}>Free</h3>
              <p className={styles.planSubtitle}>Just getting started</p>
              
              <ul className={styles.featuresList}>
                <li>✓ Summarize 1000 words/day</li>
                <li>✓ Chat with Document 10 prompts/ day</li>
                <li className={styles.featuresContainIcon}>
                <div><Close style={{ color: 'red' }}/></div>
                   <div>OCR Processing</div>
                   </li>
                   <li className={styles.featuresContainIcon}>
                <div><Close style={{ color: 'red' }}/></div>
                   <div>Search web</div>
                   </li>
                   <li className={styles.featuresContainIcon}>
                <div><Close style={{ color: 'red' }}/></div>
                   <div>Advanced Al Model</div>
                   </li>
                <li className={styles.featuresContainIcon}>
                <div><Close style={{ color: 'red' }}/></div>
                   <div>Homework Checker</div>
                   </li>
                <li className={styles.featuresContainIcon}>
                <div><Close style={{ color: 'red' }}/></div>
                   <div>Comparison Tool</div>
                   </li>
                
              </ul>

              <div className={styles.pricing}>
                <span className={styles.price}>$0</span>
                <span className={styles.period}>/month</span>
              </div>

              <button onClick={()=>setIsPremium(!isPremium)} className={styles.continueButton}>Continue for free</button>
            </div>
            <div className={styles.planCard}>
              <h3 className={styles.planName}>Student</h3>
              <p className={styles.planSubtitle}>Just getting started</p>
              
              <ul className={styles.featuresList}>
                <li>✓ Summarize 50,000 words/month</li>
                <li>✓ Chat with Document 1000 prompts/ month</li>
                <li>✓ OCR Processing</li>
                <li>✓ Search web</li>
                <li>✓ Advanced Al Model Claude Sonnet 3.5</li>
                <li className={styles.featuresContainIcon}>
                <div><Close style={{ color: 'red' }}/></div>
                   <div>Homework Checker</div>
                   </li>
                
                <li className={styles.featuresContainIcon}>
                <div><Close style={{ color: 'red' }}/></div>
                   <div>Comparison Tool</div>
                   </li>
                   
                
                
              </ul>

              <div className={styles.pricing}>
                <span className={styles.price}>{isMonth ? "$7.99": "$7.99 X 11"}</span>
                <span className={styles.period}>{isMonth ? "/month":"/yearly"}</span>
              </div>

              <button onClick={() =>user && PaymentProcess(isMonth ? "plan_A": "plan_a_yearly")} className={styles.subscribeButton}>Subscribe</button>
            </div>

            <div className={`${styles.planCard} ${styles.highlighted}`}>
              <h3 className={styles.planName}>Professional</h3>
              <p className={styles.planSubtitle}>You have startups</p>
              
              <ul className={styles.featuresList}>
              <li>✓ Summarize 150,000 words/month</li>
              <li>✓ Chat with Document 2500 prompts/ month</li>
                
                <li>✓ OCR Processing</li>
                <li>✓ Search web</li>
                <li>✓ Advanced Al Model Claude Sonnet 3.5</li>
                <li>✓ Homework Checker</li>
                <li>✓ Comparison Tool</li>
                
                <li>✓ 50 document uploads</li>
                
              </ul>

              <div className={styles.pricing}>
                <span className={styles.price}>{isMonth ? "$13.99": "$13.99 X 11"}</span>
                <span className={styles.period}>{isMonth ? "/month":"/yearly"}</span>
              </div>

              <button onClick={() =>user && PaymentProcess(isMonth ? "plan_B": "plan_b_yearly")} className={styles.subscribeButton}>Subscribe</button>
            </div>
          </div>

          <button onClick={()=>setIsPremium(!isPremium)} className={styles.bottomContinueButton}>Continue for free</button>
        </div>
      </div>
    </div>
  );
};

export default PricingModal;
