// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Avatar_imageStyles__E-7Sx img{
    border-radius: 50%;
    transition: border 0.5s ease;
  }

  .Avatar_imageStyles__E-7Sx img:hover{
    border: 2px solid rgb(118, 184, 255);
  }`, "",{"version":3,"sources":["webpack://./src/Components/Common/Avatar/Avatar.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,4BAA4B;EAC9B;;EAEA;IACE,oCAAoC;EACtC","sourcesContent":[".imageStyles img{\r\n    border-radius: 50%;\r\n    transition: border 0.5s ease;\r\n  }\r\n\r\n  .imageStyles img:hover{\r\n    border: 2px solid rgb(118, 184, 255);\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageStyles": `Avatar_imageStyles__E-7Sx`
};
export default ___CSS_LOADER_EXPORT___;
