// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageNotFound_Main__Ux1uA{
    display: flex;
    height: 90vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}
.PageNotFound_Text__XlMKs{
    font-size: 40px;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.475);
}`, "",{"version":3,"sources":["webpack://./src/Components/PageNotFound/PageNotFound.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,2BAA2B;AAC/B","sourcesContent":[".Main{\r\n    display: flex;\r\n    height: 90vh;\r\n    width: 100vw;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.Text{\r\n    font-size: 40px;\r\n    font-weight: 900;\r\n    color: rgba(0, 0, 0, 0.475);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Main": `PageNotFound_Main__Ux1uA`,
	"Text": `PageNotFound_Text__XlMKs`
};
export default ___CSS_LOADER_EXPORT___;
