import React, { useEffect, useState } from 'react'
import AWS from 'aws-sdk';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Button, IconButton, Tooltip } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import zIndex from '@mui/material/styles/zIndex';
import jsPDF from 'jspdf';

import html2pdf from "html2pdf.js";
import { useAuth0 } from '@auth0/auth0-react';
import InputMask from 'react-input-mask';


const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;
const ShareableLink2 = ({ href, children }) => {
  // Construct a Google search URL for the shareableLink
  const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(href)}`;

  return (
    <a href={searchUrl} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const ShareableLink = styled.a`
  margin-bottom: 10px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const CopyButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;


const customStyles = {
  content: {
    width: '700px',
    height: '350px',
    margin: 'auto',
    left: '-20em',
    animation: 'fadeIn 0.3s ease-out forwards', // Inline animation
  },
};

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  region: 'eu-north-1',
});

//const S3_BUCKET = 'parvbucket12';
const S3_BUCKET = 'pdfing.ai';



export const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: 'eu-north-1',
});


function ShareLinkComponent({setPremiumError,setIsPremium,isSelected,type2Value,type1Value,shareableLink, setShareableLink, modalOpen, setModalOpen, response, fileName, file }) {
  const { user } = useAuth0();

  const generateTimestamp = () => {
    const now = new Date();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${month}-${day}_${hours}-${minutes}-${seconds}`;
  };



  const wrapHtmlContent = (htmlContent) => {
    return `
          <!DOCTYPE html>
          <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
          <head><meta charset='utf-8'><title>Document</title></head><body>${htmlContent}</body></html>
        `;
  };

  // const shortenUrl = async (longUrl) => {
  //   try {
  //     console.log("in shortner:", longUrl);
  //     const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  //     const apiUrl = `https://ulvis.net/api.php?url=${encodeURIComponent(longUrl)}`;
  //     const response = await fetch(proxyUrl + apiUrl);
  //     const shortUrl = await response.text(); // Get the short URL as a plain text response
  //     console.log("shortended: ",shortUrl)
  //     return shortUrl;
  //   } catch (error) {
  //     console.error('Error shortening URL:', error);
  //     return null;
  //   }
  // };
  // const createPdfFile = (content, fileName = `${generateTimestamp()}.pdf`) => {
  //   return new Promise((resolve, reject) => {
  //     // Create a hidden container to render the HTML content
  //     const container = document.createElement('div');
  //     container.innerHTML = content;
  //     document.body.appendChild(container); // Temporarily append the container to the body for rendering
  
  //     // Use html2pdf.js to convert the HTML content into a PDF
  //     const options = {
  //       margin: 10,
  //       filename: fileName,
  //       image: { type: 'jpeg', quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  //     };
  
  //     html2pdf()
  //       .from(container)
  //       .set(options)
  //       .get('pdf')
  //       .then(pdf => {
  //         if (pdf) {
  //           // Convert jsPDF output to Blob
  //           const pdfBlob = pdf.output('blob');
  //           // Create a File object from the Blob
  //           const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf' });
  
  //           // Resolve the promise with the PDF file
  //           resolve(pdfFile);
  //         } else {
  //           reject('Failed to generate PDF.');
  //         }
  //       })
  //       .catch(err => {
  //         // Reject the promise if an error occurs
  //         reject(err);
  //       })
  //       .finally(() => {
  //         // Clean up by removing the container from the DOM
  //         document.body.removeChild(container);
  //       });
  //   });
  // };
  

  const handleDownloadDOC = async () => {
    console.log(wrapHtmlContent(response), "typefile");

    const wrappedHtml = wrapHtmlContent(response);
    if (!wrappedHtml) {
        console.error("Wrapped HTML content is empty.");
        return;
    }

    // Create a temporary container to render the HTML
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = wrappedHtml;

    // Options for html2pdf
    const options = {
        margin: 1,
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    try {
        // Generate PDF as a Blob
        const pdfBlob = await html2pdf()
            .set(options)
            .from(tempContainer)
            .outputPdf('blob'); // This returns a Blob

        console.log("Generated PDF Blob:", pdfBlob);

        // Prepare data for API upload
        const apiEndpoint = "https://pdfing.ai/api/usage/upload-img";
        const formData = new FormData();
        formData.append("file", pdfBlob);
        formData.append("email", user.email);
        
        formData.append("filename", isSelected ? `${fileName.replace(/\s/g, '')}_${type1Value}_${type2Value}.pdf`:`${fileName.replace(/\s/g, '')}_OverView.pdf`);

        // Make the API call
        const response = await fetch(apiEndpoint, {
            method: "POST",
            body: formData,
        });
        formData.forEach((value, key) => {
          console.log(`${key}: ${value}`);
        });
        console.log(pdfBlob)
        console.log(user.email)
        console.log(formData,"formdata")
        console.log(`${fileName.replace(/\s/g, '')}_${type1Value}_${type2Value}.pdf`,`${fileName.replace(/\s/g, '')}_OverView.pdf`)

        // Check response status
        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        // Parse and handle the response
        const data = await response.json();
        if(data['status'] == 'error'){
          setPremiumError(
            "Sharing is a Premium feature only available for professional users."
          );
          setIsPremium(true)
          return
      }
        try {
          const response = await fetch('https://api.pxl.to/api/v1/short', {
            method: 'POST',
            headers: {
              // 'Authorization': `Bearer ${process.env.REACT_APP_PXL_TOKEN}`,
              'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJpd0dybHRoS2ZQSjgtWm9WQVhoOV8iLCJpYXQiOjE3NDE5Njc5NTQuOTE0LCJsaW1pdCI6NTAwLCJ0aW1lZnJhbWUiOjg2NDAwLCJvcmdhbmlzYXRpb24iOiJjMDkyNzAwMi1lOTVhLTRkMzEtODZjZC0wMjU1ZDZmMzRkNTkiLCJ3b3Jrc3BhY2UiOjMzNDg5LCJ1c2VyIjoxNTk1OSwiZXhwIjoxNzQ5NzQzOTUwfQ.BLkmuxTP9nRkFnwWxAaHFSmzmAdOWtEoeSJ3eiui9Gw`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              "destination":data.file_url.replace(/\s/g, ''),
              "title":"Redirect",
              "description":"Descritive text",
              "image":"https://exapmle/image.png",
              "favicon":"https://example.com/favicon"
            }),
          });
          console.log(data.file_url.replace(/\s/g, ''))
          console.log(data.file_url)
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data2 = await response.json();
          console.log(data2.data.id)
          setShareableLink(data2.data.id)
        } catch (error) {
          console.error('Error shortening URL:', error);
          throw error;
        }
    } catch (error) {
        // Handle errors
        console.error("Error during the process:", error);
    }

    // Open the modal
    setModalOpen(true);
};



  const uploadToS3 = async (file, filename) => {

    console.log("file name-----", filename)
    if (!file) return;



    const params = {
      Bucket: S3_BUCKET,
      Key: filename,
      Body: file,
      ACL: 'public-read',
      ContentType: 'application/msword',
    };


    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.Location);
        }
      });
    });
  };
  const [maskedLink, setMaskedLink] = useState(shareableLink);

  const maskUrl = (value) => {
    // Custom logic to mimic urlmskr's masking
    return value.replace(/[a-zA-Z0-9]/g, (match, offset) => (offset < 2 || offset > value.length - 3 ? match : '*'));
  };



  return (
    <div>
      <Tooltip title="Share">
        <IconButton onClick={() => handleDownloadDOC()} ><ShareIcon /></IconButton>
      </Tooltip>
      {shareableLink && (
        <Modal style={customStyles} isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
          <ModalWrapper>
            <h2 style={{ marginBottom: "10px" }} >Shareable Link:</h2>
            <ShareableLink href={encodeURIComponent(shareableLink)} target="_blank" rel="noopener noreferrer">
            {shareableLink}
            </ShareableLink>
            <CopyButton onClick={() => navigator.clipboard.writeText(shareableLink)}>
              Copy Link
            </CopyButton>
          </ModalWrapper>
        </Modal>
      )}
    </div>
  )
}

export default ShareLinkComponent
