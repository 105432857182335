// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddItemForm_form__JwX2w {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.AddItemForm_input__aZ2yD {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 1rem;
}

.AddItemForm_button__i823u {
  padding: 0.5rem 1rem;
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.AddItemForm_button__i823u:hover {
  background-color: #40a9ff;
}

`, "",{"version":3,"sources":["webpack://./src/Page/MasterListPage/components/AddItemForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".form {\n  display: flex;\n  gap: 0.5rem;\n  margin-bottom: 1rem;\n}\n\n.input {\n  flex-grow: 1;\n  padding: 0.5rem;\n  border: 1px solid #d1d1d1;\n  border-radius: 4px;\n  font-size: 1rem;\n}\n\n.button {\n  padding: 0.5rem 1rem;\n  background-color: #1890ff;\n  color: #ffffff;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  font-size: 1rem;\n}\n\n.button:hover {\n  background-color: #40a9ff;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `AddItemForm_form__JwX2w`,
	"input": `AddItemForm_input__aZ2yD`,
	"button": `AddItemForm_button__i823u`
};
export default ___CSS_LOADER_EXPORT___;
