// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionList_questionList__rzB1A {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .QuestionList_questionItem__hNdAa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .QuestionList_questionItem__hNdAa:last-child {
    border-bottom: none;
  }
  
  .QuestionList_deleteButton__qXs5t {
    padding: 0.25rem 0.5rem;
    background-color: #ff4d4f;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .QuestionList_deleteButton__qXs5t:hover {
    background-color: #ff7875;
  }
  .QuestionList_actionButton__soJV5 {
    padding: 0.25rem 0.5rem;
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .QuestionList_actionButton__soJV5:hover {
    background-color: #f0f0f0;
  }
  .QuestionList_topicActions__ztl35 {
    display: flex;
    gap: 0.5rem;
  }`, "",{"version":3,"sources":["webpack://./src/Page/MasterListPage/components/QuestionList.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,gCAAgC;EAClC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,uBAAuB;IACvB,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,aAAa;IACb,WAAW;EACb","sourcesContent":[".questionList {\n    list-style-type: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  .questionItem {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0.5rem 0;\n    border-bottom: 1px solid #e0e0e0;\n  }\n  \n  .questionItem:last-child {\n    border-bottom: none;\n  }\n  \n  .deleteButton {\n    padding: 0.25rem 0.5rem;\n    background-color: #ff4d4f;\n    color: #ffffff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 0.875rem;\n  }\n  \n  .deleteButton:hover {\n    background-color: #ff7875;\n  }\n  .actionButton {\n    padding: 0.25rem 0.5rem;\n    background-color: #ffffff;\n    border: 1px solid #d1d1d1;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 0.875rem;\n  }\n  \n  .actionButton:hover {\n    background-color: #f0f0f0;\n  }\n  .topicActions {\n    display: flex;\n    gap: 0.5rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionList": `QuestionList_questionList__rzB1A`,
	"questionItem": `QuestionList_questionItem__hNdAa`,
	"deleteButton": `QuestionList_deleteButton__qXs5t`,
	"actionButton": `QuestionList_actionButton__soJV5`,
	"topicActions": `QuestionList_topicActions__ztl35`
};
export default ___CSS_LOADER_EXPORT___;
