"use client"
import styles from "./subscription-modal.module.css"

const SubscriptionModal = ({ planName = "Premium", planPrice = "9.99", onClose, onStart }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>
          X
        </button>
        <div className={styles.logoContainer}>
          <div className={styles.logo}>P</div>
        </div>

        <h1 className={styles.title}>You have successfully subscribed</h1>
        <p className={styles.subtitle}>Thank you for your support</p>

        <div className={styles.planDetails}>
          <p className={styles.planInfo}>You have selected the {planName} plan</p>
          <p className={styles.planInfo}>You will be billed ${planPrice} each month</p>
        </div>

        <button className={styles.startButton} onClick={onStart}>
          Start PDFing!
        </button>

        <p className={styles.cancelText}>
          You can cancel at any time by clicking{" "}
          <a href="#" className={styles.cancelLink}>
            here
          </a>
        </p>
      </div>
    </div>
  )
}

export default SubscriptionModal

