import React from 'react'
import { termsData } from './dummyData.js'
import { termsData2 } from './dummyData2.js'
import CookieTable from './CookieTable.js';


export default function Content({ isPolicy }) {
    let data = termsData
    if (isPolicy){
        data = termsData2
    }

    const lastUpdated = "Last Updated: 13/02/25 ";
    return (
        <div className="w-3/4 mx-auto bg-white py-4 rounded-lg">
            <h1 className="text-2x2 font-bold mb-4">{lastUpdated}</h1>

            <div className="space-y-6">
                {data.map((val, i) => (
                    <section>
                        {val?.heading ? <> <p dangerouslySetInnerHTML={{ __html: val?.heading }} /> <br />  </> : ""}
                        {val?.subHeading ? <> <p dangerouslySetInnerHTML={{ __html: val?.subHeading }} /> <br /> </> : ""}
                        {val?.points && val?.points?.map((_val, i) => (
                            <div className="flex items-center space-x-2 ml-3 mt-2">
                                <span className="">{i + 1}.</span>
                                <p className="text-gray-800" key="{{ i }}" dangerouslySetInnerHTML={{ __html: _val }}></p>
                            </div>
                        ))}
                        {val?.text && <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: val?.text }} />}
                        {val?.content && <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: val?.content }} />}
                    </section>
                ))}
                {/* {jsonData.length && jsonData?.map((val) => (
                    <CookieTable category={val.category} description={val.description} columns={val.columns} rows={val.rows} />
                ))} */}
            </div>
        </div>
    )
}
