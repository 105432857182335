import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import styles from "./Comparison.module.css";

import analyzeIcon from './icons/analysis 1.svg';
import compareIcon from './icons/analysis 1-2.svg';
import arrowdown from './icons/arrow-down 1.svg';
import Navbar from '../Navbar/Navbar';

import GearGif from "../../GearGif.gif";
import { AddBoxRounded, AddCircleOutline, AddIcCallOutlined, AddOutlined, DataObjectSharp, Delete, Report } from '@mui/icons-material';
import Modal2 from './Modal2';
import { GenericAvatarIcon } from '@chakra-ui/react';
import jsPDF from 'jspdf';
import Chart from "chart.js/auto";
import html2canvas from "html2canvas";
import ChartAnnotation from 'chartjs-plugin-annotation';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

const Comparison = ({setPremiumError,usageData,user, files, setfile,setTopicFromList,topicFromList,showMasterListModal,setShowMasterListModal,fileNames,setFileNames,fileBlobUrls,isAuthenticated,setIsAuthenticated, isLoading2, setIsLoading2,setFileModal, fileModal}) => {

    const [fileTexts, setFileTexts] = useState({});
    const [topics, setTopics] = useState([]);
    const [analysisResults, setAnalysisResults] = useState({});
    const [finalAnalysisResults, setFinalAnalysisResults] = useState({});
    const [modalContent, setModalContent] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTopics, setSelectedTopics] = useState([]);

    const [loadingStates, setLoadingStates] = useState({});
    const [showFullStates, setShowFullStates] = useState({});
    const [showFullStates2, setShowFullStates2] = useState({});
    const [compareLoading, setCompareLoading] = useState({});
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [columns, setColumns] = useState(Object.keys(fileTexts));
    const [newColumn, setNewColumn] = useState(null);
    const [newTopicbyuser, setNewTopicbyuser] = useState("");
    const [chractLoding, setChractLoding] = useState(false)

    const[model2, setModel2] = useState(false)
    const [characterAnswer, setCharacterAnswer] = useState('')
    const [filenameTouch, setFilenameTouch] = useState('')

    const handleAddColumn = () => {
        const isAnyLoading = Object.values(loadingStates).some((state) => state === true);
        console.log(isAnyLoading, "here343"); 
        if (isAnyLoading){
            alert("Can not add topic while processing")
            return
        }
        const newColumnId = `New Column ${columns.length + 1}`;
        setColumns([newColumnId, ...columns]);
        setNewColumn(newColumnId);  // Mark the column for animation
    };
    useEffect(() =>{
        if(topicFromList){
            topicFromList.map((item) => {
                console.log(item.question);
                if (item.question.trim() !== ""){
                    setTopics((prevTopics) => {
                        console.log(`<Topic1>${item.question}</Topic1>`,"lll")
                        const updatedTopics = [`<Topic1>${item.question}</Topic1>`, ...prevTopics];
            
                        // Shift analysisResults down and set the new topic's result as empty
                        setAnalysisResults((prevResults) => {
                            const newResults = {};
                            Object.keys(prevResults).forEach((key) => {
                                const [rowIndex, filename] = key.split('-');
                                const newKey = `${parseInt(rowIndex, 10) + 1}-${filename}`;
                                newResults[newKey] = prevResults[key];
                            });
            
                            // Initialize empty results for the new topic
                            Object.keys(fileTexts).forEach((filename) => {
                                newResults[`0-${filename}`] = ''; // Empty result for the new topic
                            });
            
                            return newResults;
                        });
            
                        setFinalAnalysisResults((prevResults) => {
                            const newResults = {};
                            Object.keys(prevResults).forEach((rowIndex) => {
                                const newKey = `${parseInt(rowIndex, 10) + 1}`;
                                newResults[newKey] = prevResults[rowIndex];
                            });
                        
                            // Initialize new topic's result as an empty string
                            newResults['0'] = ""; 
                        
                            return newResults;
                        });
            
                        // Shift showFullStates down and initialize for the new topic
                        setShowFullStates((prevStates) => {
                            const newStates = {};
                            Object.keys(prevStates).forEach((key) => {
                                const [rowIndex, filename] = key.split('-');
                                const newKey = `${parseInt(rowIndex, 10) + 1}-${filename}`;
                                newStates[newKey] = prevStates[key];
                            });
            
                            // Initialize the new topic's states
                            Object.keys(fileTexts).forEach((filename) => {
                                newStates[`0-${filename}`] = false; // Default state for the new topic
                            });
            
                            return newStates;
                        });
            
                        return updatedTopics;
                    });
                    // setTopicFromList([])
                }
              });
        }
        
    },[ topicFromList])

    useEffect(() => {
        console.log("ooop",fileTexts)
        localStorage.setItem('topics', topics);
    },[topics,fileTexts])


    const handleNewTopicBlur = () => {
        if (newTopicbyuser.trim()) {
            setTopics((prevTopics) => {
                const updatedTopics = [`<Topic1>${newTopicbyuser}</Topic1>`, ...prevTopics];
    
                // Shift analysisResults down and set the new topic's result as empty
                setAnalysisResults((prevResults) => {
                    const newResults = {};
                    Object.keys(prevResults).forEach((key) => {
                        const [rowIndex, filename] = key.split('-');
                        const newKey = `${parseInt(rowIndex, 10) + 1}-${filename}`;
                        newResults[newKey] = prevResults[key];
                    });
    
                    // Initialize empty results for the new topic
                    Object.keys(fileTexts).forEach((filename) => {
                        newResults[`0-${filename}`] = ''; // Empty result for the new topic
                    });
    
                    return newResults;
                });

                setFinalAnalysisResults((prevResults) => {
                    const newResults = {};
                    Object.keys(prevResults).forEach((rowIndex) => {
                        const newKey = `${parseInt(rowIndex, 10) + 1}`;
                        newResults[newKey] = prevResults[rowIndex];
                    });
                
                    // Initialize new topic's result as an empty string
                    newResults['0'] = ""; 
                
                    return newResults;
                });
    
                // Shift showFullStates down and initialize for the new topic
                setShowFullStates((prevStates) => {
                    const newStates = {};
                    Object.keys(prevStates).forEach((key) => {
                        const [rowIndex, filename] = key.split('-');
                        const newKey = `${parseInt(rowIndex, 10) + 1}-${filename}`;
                        newStates[newKey] = prevStates[key];
                    });
    
                    // Initialize the new topic's states
                    Object.keys(fileTexts).forEach((filename) => {
                        newStates[`0-${filename}`] = false; // Default state for the new topic
                    });
    
                    return newStates;
                });
    
                return updatedTopics;
            });
        }
    
        // Reset the input
        setNewTopicbyuser('');
        setNewColumn(null);
    };
    
    

    // Toggle file selection
    const toggleFileSelection = (filename) => {
        setSelectedFiles((prevSelected) =>
            prevSelected.includes(filename) ? [] : [filename] // Deselect if already selected, otherwise select only the new file
          );
          console.log("Selected file:", filename);
    };

    // Trigger character analysis on selected files
    const analyzeSelectedFiles = () => {
        setChractLoding(true);
        selectedFiles.forEach((filename) => handleCharacterAnalysis(filename));
        
    };

    const handleCompareClick = async (topic, rowIndex) => {
        setCompareLoading(prev => ({ ...prev, [rowIndex]: true })); // Set loading for specific row

        await handleCompare(topic, rowIndex); // Perform the comparison

        setCompareLoading(prev => ({ ...prev, [rowIndex]: false })); // Reset loading
    };


    const handleCheckboxChange = (rowIndex) => {
        setSelectedTopics((prevSelected) =>
            prevSelected.includes(rowIndex)
                ? prevSelected.filter((index) => index !== rowIndex)
                : [...prevSelected, rowIndex]
        );
    };
    

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        if(!isLoading2){
            const storedFileTexts = localStorage.getItem('fileTexts');
            const storedTopics = localStorage.getItem('topics');
            console.log("ccc")

            if (storedFileTexts) {
                setFileTexts(JSON.parse(storedFileTexts));
            }

            if (storedTopics) {
                let topicArray = splitIntoTopics(storedTopics);
                
                // topicArray = topicArray.map((topic) => {
                    
                //     return topic.slice(0, -2);
                // });
                // console.log("000")
                setTopics(topicArray);
            }
        }
    }, [isLoading2,fileNames]);

    useEffect(() =>{
        topics.map((topic) => {
            console.log(typeof(topic))
            console.log(topic)
            
            // let cleanedTopic = topic
            // if(cleanedTopic.includes("\n")){
            //     cleanedTopic = topic.slice(0, -2);
            // }
                    
            // console.log(cleanedTopic, "sss");
            
        })
    },[ topics])

    const splitIntoTopics = (response) => {
        const topicRegex = /(<Topic\d+(\.\d+)*>[\s\S]*?)(?=<Topic\d+(\.\d+)*>|$)/g;
        const topicsArray = [];
        let match;
        while ((match = topicRegex.exec(response)) !== null) {
            topicsArray.push(match[1].trim());
        }
        return topicsArray;
    };

    const handleAnalyzeClick = async (topic, rowIndex, filename) => {
        setLoadingStates(prev => ({ ...prev, [`${rowIndex}-${filename}`]: true })); // Set loading state for the specific file
    
        console.log(topic,rowIndex,filename,"ddd")
        await handleAnalyzeTopic(topic, rowIndex, filename); // Fetch data
    
        setLoadingStates(prev => ({ ...prev, [`${rowIndex}-${filename}`]: false })); // Reset loading state
      };
      const handleShowFullClick = (topic, rowIndex, filename) => {
        console.log("Before toggle:", showFullStates); // Log state before update

    setShowFullStates((prev) => {
        const newState = {
        ...prev,
        [`${rowIndex}-${filename}`]:
            prev[`${rowIndex}-${filename}`] === null ? true : !prev[`${rowIndex}-${filename}`], // Toggle between null/true/false
        };
        
        console.log("New state after toggle:", newState); // Log state after update
    return newState;
});
      };
    
      const handleShowFullClick2 = (rowIndex) => {
        console.log("Before toggle:", showFullStates2);
        setShowFullStates2((prev) => ({
            ...prev,
            [rowIndex]: !prev[rowIndex], // Toggle between true/false
            
        }));
        console.log(finalAnalysisResults[rowIndex],"sdd")
    };

    
    const handleAnalyzeTopic = async (topic, topicIndex, filename) => {
        const topicTitleMatch = topic.match(/<Topic\d+>(.*?)<\/Topic\d+>/);
        console.log(topic);
        console.log(topicTitleMatch);
        console.log(topicTitleMatch[1]);
        const topicTitle = topicTitleMatch ? topicTitleMatch[1].trim() : `topic${topicIndex + 1}`;
        
        console.log(topic);
        console.log(topicTitle, "pp");
        console.log(topicTitleMatch);

        try {
            const response = await axios.post('https://pdfing.ai/api/usage/redirect_func', {
                type: "analysis",
                topic: topicTitle,
                email:user.email,
                date:new Date().toISOString().split('T')[0],
                api_name:"depose",
                usagetype: "normal",
                main: { [filename]: fileTexts[filename] },
            });
            let result;
            if (response.data == ""){
                result = "no information found"
            }else{
                result = response.data;
            }
            updateAnalysisResult(topicIndex, filename, result); // Store the analysis result
            console.log(topicIndex, filename, result)
        } catch (error) {
            console.error("Error analyzing topic:", error);
        }
    };

    const updateAnalysisResult = (topicIndex, filename, result) => {
        setAnalysisResults(prevResults => ({
            ...prevResults,
            [`${topicIndex}-${filename}`]: result
        }));
    };
    const renderHtmlContent3 = (content) =>{
        let contents="";
        
        for(let i=0; i< content.length-1; i++){
            if(content[i]=="p" && content[i+1]==">"){
                contents=contents+"div>"
            }else if(content[i]=="<" && content[i+1]=="p"){
                contents = contents+"<div"
                i += 1
            }
            else{
                if (content[i] == "\\"){
                    i = i+1
                    }else{
                        contents = contents+content[i];
                    }
            }
            

        }
        console.log(contents,"oooo");
        return contents;
    }

    // const renderHtmlContent = (htmlContent) => {
    //     console.log("Processing HTML content:", htmlContent);

        
    //     const updatedContent = htmlContent.replace(
    //         /<p class=['"]normal['"][^>]*>\s*<\/p>\s*(<ul>[\s\S]*?<\/ul>)/g,
    //         (match, ulContent) => {
                
    //             const updatedUl = ulContent.replace(
    //                 /<li>/g,
    //                 '<li style="color: yellow;">'
    //             );
      
    //             return htmlContent
    //         }
    //     );
    
    //     return updatedContent;
    
    //   };
    

    const handleOpenModal = (content,filename) => {
        const modalFilteredContent = content.replace(/<p class="info">[\s\S]*?<\/p>/g, '');
        setModalContent(modalFilteredContent);
        setFilenameTouch(filename);
        setIsModalOpen(true);
    };
    const handleOpenModal2 = (content) => {
        const modalFilteredContent = content.replace(/<p class="info">[\s\S]*?<\/p>/g, '');
        setCharacterAnswer(modalFilteredContent);
        setModel2(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setModalContent('');
        setFilenameTouch('')
    };

    const extractInfoContent = (html) => {
        // Check if the response is a simple string
        if (!/<\/?[a-z][\s\S]*>/i.test(html)) {
            return html; // Return the plain string if no HTML tags are present
        }

        // Parse the HTML content
        const div = document.createElement('div');
        div.innerHTML = html;

        // Extract <p class="info"> elements and join them
        const extractedContent = Array.from(div.querySelectorAll('p.info'))
            .map(p => p.outerHTML)
            .join('');

        return extractedContent || 'No information available'; 
    };

    const handleCompare = async (topic, topicIndex) => {
        const topicTitleMatch = topic.match(/<Topic\d+(\.\d+)*>(.*?)<\/Topic\d+(\.\d+)*>/);
        const topicTitle = topicTitleMatch ? topicTitleMatch[2].trim() : `topic${topicIndex + 1}`;

        const requestPayload = {
            type: "fullanalysis",
            topic: topicTitle,
            email:user.email,
            usagetype:"normal",
            date:new Date().toISOString().split('T')[0],
            api_name:"depose",
            main: {}
        };

        for (const filename of Object.keys(fileTexts)) {
            requestPayload.main[filename] = analysisResults[`${topicIndex}-${filename}`] || '';
        }

        try {
            const response = await axios.post('https://pdfing.ai/api/usage/redirect_func', requestPayload);
            const result = response.data;
            updateFinalAnalysisResult(topicIndex, result);
        } catch (error) {
            console.error("Error comparing topics:", error);
        }
    };

    const updateFinalAnalysisResult = (topicIndex, result) => {
        setFinalAnalysisResults(prevResults => ({
            ...prevResults,
            [topicIndex]: result
        }));
    };

    const handleCharacterAnalysis = async (filename) => {
        const requestPayload = {
            type: "character",
            date:new Date().toISOString().split('T')[0],
            api_name:"depose",
            usagetype:"normal",
            email:user.email,
            main: {}
        };

        topics.forEach((topic, topicIndex) => {
            requestPayload.main[`Topic${topicIndex + 1}`] = analysisResults[`${topicIndex}-${filename}`] || '';
        });

        try {
            const response = await axios.post('https://pdfing.ai/api/usage/redirect_func', requestPayload);
            const result = response.data;
            setFinalAnalysisResults(prevResults => ({
                ...prevResults,
                [`characterAnalysis-${filename}`]: result
            }));
            setChractLoding(false);
            setCharacterAnswer(result)
            handleOpenModal2(result)
            setModel2(true);
            console.log("datra22",result); 
            
        } catch (error) {
            console.error("Error performing character analysis:", error);
        }
    };
    const handleEnterPress = (e) => {
        if (e.key === 'Enter') {
          handleNewTopicBlur(); // Call the function to add a new topic
        }
      };

    const handleAddTopic = () => {
        if (newTopicbyuser.trim()) {
            setTopics([...topics, `<Topic${topics.length + 1}>${newTopicbyuser}</Topic${topics.length + 1}>`]);
            
            setNewTopicbyuser('');
        }
    };
    const handleDeleteSelectedTopics = () => {
        const isAnyLoading = Object.values(loadingStates).some((state) => state === true);
        console.log(isAnyLoading, "here343"); 
        if (isAnyLoading){
            alert("Can not delete while processing")
            return
        }

        // handleShowFullClick(selectedTopics, rowIndex, filename)
        setShowFullStates((prev) => {
            const newState = Object.keys(prev).reduce((acc, key) => {
                acc[key] = false; // Set all values to false
                return acc;
            }, {});
            return newState;
        });
        setTopics((prevTopics) => {
            // Filter out selected topics
            const updatedTopics = prevTopics.filter((_, index) => !selectedTopics.includes(index));
    
            // Shift analysisResults after removing selected topics
            setAnalysisResults((prevResults) => {
                const newResults = {};
                let shiftIndex = 0; // To keep track of index shifts
                prevTopics.forEach((_, index) => {
                    if (selectedTopics.includes(index)) {
                        shiftIndex++; // Skip the deleted topic
                    } else {
                        Object.keys(prevResults).forEach((key) => {
                            const [rowIndex, filename] = key.split('-');
                            if (parseInt(rowIndex, 10) === index) {
                                const newKey = `${index - shiftIndex}-${filename}`;
                                newResults[newKey] = prevResults[key];
                            }
                        });
                    }
                });
                return newResults;
            });
    
            // Shift finalAnalysisResults after removing selected topics
            setFinalAnalysisResults((prevResults) => {
                const newResults = {};
                let shiftIndex = 0; // To keep track of index shifts
                prevTopics.forEach((_, index) => {
                    if (selectedTopics.includes(index)) {
                        shiftIndex++; // Skip the deleted topic
                    } else {
                        newResults[index - shiftIndex] = prevResults[index];
                    }
                });
                return newResults;
            });
    
            return updatedTopics;
        });
    
        // Clear selected topics after deletion
        setSelectedTopics([]);
    };
    
    const closeThis =()=>{
        setModel2(!model2)
        setCharacterAnswer('');
    }
    let chartInstance = null; // Declare chart instance globally

    const createChart = (canvasId, data, options) => {
        // Destroy the previous chart instance if it exists
        if (chartInstance) {
            chartInstance.destroy();
        }
    
        // Create a new chart instance
        chartInstance = new Chart(document.getElementById(canvasId), {
            type: "doughnut",
            data,
            options,
        });
    };
    
    const handleGenerateReport = () => {
        console.log("Generating Report");
    
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const margin = 10;
    
        // Title
        doc.setFontSize(18);
        doc.text("COMPARISON REPORT", pageWidth / 2, 20, { align: "center" });
    
        // Analyze finalAnalysisResults
        const contradictions = Object.values(finalAnalysisResults).filter((result) =>
            result.includes("contradiction")
        ).length;
        const totalResults = Object.keys(finalAnalysisResults).length;
        const noContradictions = totalResults - contradictions;
    
        // Debug: Print contradiction analysis
        console.log("Contradictions:", contradictions);
        console.log("Total Results:", totalResults);
        console.log("No Contradictions:", noContradictions);
    
        // Calculate percentages
        const noContradictionsPercent = ((noContradictions / totalResults) * 100).toFixed(0);
        const normalContradictionsPercent = ((contradictions / totalResults) * 100).toFixed(0);
    
        // Debug: Print percentages
        console.log("No Contradictions %:", noContradictionsPercent);
        console.log("Contradictions %:", normalContradictionsPercent);
    
        // Pie Chart Data
        const chartData = {
            labels: ["No Contradictions", "Contradictions"],
            datasets: [
                {
                    data: [noContradictionsPercent, normalContradictionsPercent],
                    backgroundColor: ["#36A2EB", "#FF6384"], // Blue and Red
                },
            ],
        };
    
        // Create a canvas for the chart
        const canvas = document.createElement("canvas");
        canvas.width = 300;
        canvas.height = 300;
    
        // Generate Pie Chart (commented out for now)
        // const chart = new Chart(canvas, {
        //     type: "pie",
        //     data: chartData,
        // });
    
        // Wait for chart to render and add to PDF
        setTimeout(() => {
            // const imgData = canvas.toDataURL("image/png");
            // doc.addImage(imgData, "PNG", margin, 30, pageWidth - 2 * margin, 60);
    
            const extractTopicContent = (topic) => {
                const match = topic.match(/<Topic\d+>(.*?)<\/Topic\d+>/);
                return match ? match[1] : "Unknown Topic"; // Extracted content or fallback
            };
    
            // Topics Section
            if (topics && topics.length > 0) {
                let yOffset = 100; // Initial yOffset
                const lineHeight = 6; // Height per line of text
                const pageHeight = doc.internal.pageSize.getHeight(); // Total page height
            
                topics.forEach((topic, index) => {
                    const comparisonResult = finalAnalysisResults[index];
            
                    // Skip if no comparison result exists for this topic
                    if (!comparisonResult) {
                        console.log(`No comparison result for index ${index}. Skipping topic.`);
                        return;
                    }
            
                    // Extract topic content
                    const extractedContent = extractTopicContent(topic);
                    doc.setDrawColor(0, 0, 0); // Black border
            
                    // Calculate width and height for topic box
                    const textWidth = doc.getTextWidth(extractedContent) + 36; // Add padding to text width
                    const textHeight = 14; // Fixed height for the topic box
            
                    // Check if adding the topic box exceeds the page height
                    if (yOffset + textHeight > pageHeight) {
                        doc.addPage(); // Add a new page
                        yOffset = margin; // Reset yOffset for the new page
                    }
            
                    // Draw rectangle around the topic text
                    doc.rect(margin, yOffset - 8, textWidth, textHeight); // Rectangle around topic
                    doc.setFontSize(12);
                    doc.text(`${index + 1} - Topic : ${extractedContent}`, margin + 2, yOffset); // Slight padding for text position
            
                    // Update yOffset for the next section
                    yOffset += textHeight + 10; // Add space after the topic box
            
                    // File Names and Analysis
                    Object.keys(fileTexts).forEach((filename, colIndex) => {
                        // Check if adding the filename exceeds the page height
                        if (yOffset + lineHeight > pageHeight) {
                            doc.addPage(); // Add a new page
                            yOffset = margin; // Reset yOffset for the new page
                        }
            
                        // Display file name with a label
                        doc.setFontSize(12);
                        doc.text(`FileName : ${filename}:`, margin, yOffset);
            
                        // Update yOffset for the next section
                        yOffset += lineHeight; // Add space after the filename
            
                        // Display file analysis
                        const analysisKey = `${index}-${filename}`;
                        const analysisResult = analysisResults[analysisKey] || "No analysis available";
            
                        // Clean and wrap the analysis result
                        // const cleanedResult = analysisResult.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
                        const cleanedResult2 = extractInfoContent(analysisResult); // Remove HTML tags
                        const cleanedResult = cleanedResult2.replace(/<p class="info">|<\/p>/g, '');
                        const wrappedAnalysis = doc.splitTextToSize(cleanedResult, pageWidth - 2 * margin);
                        console.log("HHSHS", cleanedResult)
                        console.log(extractInfoContent(analysisResult))
            
                        // Calculate required height for the analysis text
                        const requiredHeight = wrappedAnalysis.length * lineHeight;
            
                        // Check if adding the analysis text exceeds the page height
                        if (yOffset + requiredHeight > pageHeight) {
                            doc.addPage(); // Add a new page
                            yOffset = margin; // Reset yOffset for the new page
                        }
            
                        // Display wrapped analysis text
                        doc.setFontSize(10);
                        wrappedAnalysis.forEach((line, lineIndex) => {
                            doc.text(line, margin, yOffset + (lineIndex * lineHeight)); // Line-by-line yOffset adjustment
                        });
            
                        // Update yOffset for the next section
                        yOffset += requiredHeight + 10; // Add extra space after analysis text
                    });
            
                    // Display final analysis result (only once after all file analyses)
                    const finalAnalysis = finalAnalysisResults[index];
                    if (finalAnalysis) {
                        console.log("FINAL", finalAnalysis);
                    
                        // Extract the class from the finalAnalysis string
                        const classMatch = finalAnalysis.match(/class=['"](.*?)['"]/);
                        const className = classMatch ? classMatch[1] : null;
                    
                        // Set text color based on the class
                        let textColor;
                        if (className === 'normal') {
                            textColor = [255, 204, 0]; // Yellow
                        } else if (className === 'factual') {
                            textColor = [255, 0, 0]; // Red
                        } else {
                            textColor = [0, 128, 0]; // Green (default for other cases)
                        }
                    
                        // Remove HTML tags from the finalAnalysis
                        const cleanedFinalAnalysis = finalAnalysis.replace(/<[^>]+>/g, '');
                    
                        // Clean and wrap the final analysis result
                        const wrappedFinalAnalysis = doc.splitTextToSize(cleanedFinalAnalysis, pageWidth - 2 * margin);
                    
                        // Calculate required height for the final analysis text
                        const finalAnalysisHeight = wrappedFinalAnalysis.length * lineHeight;
                    
                        // Check if adding the final analysis text exceeds the page height
                        if (yOffset + finalAnalysisHeight > pageHeight) {
                            doc.addPage(); // Add a new page
                            yOffset = margin; // Reset yOffset for the new page
                        }
                    
                        // Display final analysis label
                        doc.setFontSize(12);
                        doc.setTextColor(...textColor); // Set text color based on class
                        doc.text("Final Analysis:", margin, yOffset); // Label for final analysis
                        yOffset += lineHeight; // Add space after the label
                    
                        // Display wrapped final analysis text
                        doc.setFontSize(10);
                        wrappedFinalAnalysis.forEach((line, lineIndex) => {
                            doc.text(line, margin, yOffset + (lineIndex * lineHeight)); // Line-by-line yOffset adjustment
                        });
                        doc.setTextColor(0, 0, 0)
                    
                        // Update yOffset for the next section
                        yOffset += finalAnalysisHeight + 10; // Add extra space after final analysis text

                        doc.setDrawColor(0, 0, 0); // Red color for the line
                        doc.setLineWidth(0.5); // Set line thickness
                        doc.line(margin, yOffset, pageWidth - margin, yOffset); // Draw horizontal line
                        yOffset += 10; // Add extra space after the line
                        doc.setLineWidth(0.2);
                    }
                    // Debug logs
                    console.log(`Topic ${index + 1}:`, topic);
                    console.log("Comparison Result:", comparisonResult);
                });
            } else {
                // Debug: No topics found
                console.log("No topics found.");
                doc.setFontSize(12);
                doc.text("No topics found for comparison.", margin, 120);
            }
    
            // Save PDF
            doc.save("Comparison_Report.pdf");
    
            // Clean up: destroy the chart instance and remove the canvas
            // chart.destroy();
            canvas.remove();
        }, 500); // Ensure chart renders before generating PDF
    };
    


    const handleDeleteTopic = (topicIndex) => {
        setTopics(topics.filter((_, index) => index !== topicIndex));
        setAnalysisResults(prevResults => {
            const newResults = { ...prevResults };
            Object.keys(newResults).forEach(key => {
                if (key.startsWith(`${topicIndex}-`)) {
                    delete newResults[key];
                }
            });
            return newResults;
        });
        setFinalAnalysisResults(prevResults => {
            const newResults = { ...prevResults };
            delete newResults[topicIndex];
            return newResults;
        });
    };
    const data = "Try for free"
    

    return (
        <div>
            {/* <Navbar showMasterListModal={showMasterListModal} setShowMasterListModal={setShowMasterListModal}  data={data} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setFileModal={setFileModal} fileModal={fileModal}/> */}
            <div className={styles.comparisionHeading}>
            <h2>File Comparison Table</h2>
            </div>
            <div className={styles.mainContainer}>
                
                <div className={styles.dashboardContainer}>
                    <div className={styles.dashboardHeading}>File Comparison Dashboard</div>
                    <div style={{
                    width: '100%',
                    maxHeight: '17em',
                    overflowY: 'auto',
                    // border: '2px solid #ccc',
                    
                }}>
                    {fileNames.some(name => name !== "") ? ( // Check if there are any filenames in the list
                        fileNames.map((filename, index) => (
                            filename && ( // Render only if filename is not an empty string
                                <div
                                    key={index}
                                    className={`${styles.dashboardfile} ${
                                        selectedFiles.includes(filename) ? `${styles.selectedFileDash}` : ""
                                    }`}
                                    onClick={() => toggleFileSelection(filename)}
                                >
                                    {filename.replace(/^File \d+: /, '').length > 22
                                        ? `${filename.replace(/^File \d+: /, '').substring(0, 20)}...`
                                        : filename.replace(/^File \d+: /, '')}
                                </div>
                            )
                        ))
                    ) : (
                        <span className={styles.loadingIcon}>
                            <img src={GearGif} alt="Loading..." style={{ width: "80px", height: "80px", marginRight: "6em" }} />
                        </span>
                    )}
                    </div>
                    <div onClick={() =>!isLoading2 && setFileModal(!fileModal)} style={{backgroundColor:"#fff", border:'2px solid #ccc'}} className={`${styles.dashboardfile} ${isLoading2 ? styles.disabled : ''}`}>Add new file</div>

                    <div className={styles.dashboardanaly}>
                    <div
                        onClick={selectedFiles.length > 0 ? analyzeSelectedFiles : null}
                        className={`${styles.dashboardanaly} ${selectedFiles.length > 0 ? `${styles.analysisActive}` : ""}`}
                        >
                        CHARACTER ANALYSIS
                        </div>

                        {/* <div dangerouslySetInnerHTML={{ __html: finalAnalysisResults[characterAnalysis-${filename}] || '' }} /> */}
                        
                        {/* {Object.keys(fileTexts).map((filename, index) => (
                            <div
                                key={index}
                                dangerouslySetInnerHTML={{
                                __html: finalAnalysisResults[`characterAnalysis-${filename}`] || ''
                                }}
                            />
                            ))} */}
                    </div>
                    {chractLoding && <span className={styles.loadingIcon}><img src={GearGif} alt="Loading..." style={{ width: "120px", height: "120px", position:'absolute', left:'10%', bottom:'19%'}} /></span>}
                </div>
            <div style={{
                    width:'72vw',
                    overflowX:'scroll'
                }}>
            <div style={{
                    
                    height:'50px'
                }} className={styles.menuContent}>
            <div>
                {/* <input
                type="text"
                placeholder="Search topic..."
                value={searchTerm}
                onChange={handleSearch}
                className={styles.inputTopic}   
                
            /> */}
            <div className={`${styles.menuitemsadd} ${isLoading2 ? styles.disabled : ''}`} onClick={!isLoading2 ? handleGenerateReport : null}>
                    <span>Generate Report</span>
                    <span className={styles.iconadd}><GenericAvatarIcon/></span>
                </div>
            </div>
            <div className={styles.menuitems}>
                <div className={`${styles.menuitemsadd} ${isLoading2 ? styles.disabled : ''}`} onClick={!isLoading2 ? handleAddColumn : null}>
                    <span>Add New Topic</span>
                    <span className={styles.iconadd}><AddOutlined/></span>
                </div>
                
                <div className={`${styles.menuitemsdelete} ${isLoading2 ? styles.disabled : ''}`} onClick={() => !isLoading2 && handleDeleteSelectedTopics()}>
                    <span className={styles.textdelete}>Delete Topic</span>
                    <span className={styles.iconadd}><Delete/></span>
                
                </div>
                
            </div>
            </div>
            {isLoading2 ? (
                <span className={styles.loadingIcon}><img src={GearGif} alt="Loading..." style={{ width: "10em", height: "10em", }} /></span>
            ):<div style={{
                width: '100%',
                overflowX: 'auto',
                border: '1px solid #ccc',
            }}><table className={styles.table} border="0" style={{

                    width: '100%', // Fixed width for the table
            // tableLayout: 'fixed',
                }}>
                <thead>
                    <tr className={styles.topicHead}>
                    <th className={styles.topicSelect}>Select</th>
                        <th >TOPIC</th>
                        {Object.keys(fileTexts).map((filename, index) => (
                            <th key={index}>{filename.length > 17 ? `${filename.substring(0, 17)}...` : filename}</th>
                        ))}
                        <th>Final Analysis</th>
                    </tr>
                </thead>
                <thead>
                <tr>
                    <th></th>
                    {columns.map((col, index) => (
                    <th key={index} className={newColumn === col ? styles.animateColumn : ""}>
                        {col === newColumn && (
                        <input
                            type="text"
                            placeholder="Enter Topic Name"
                            value={newTopicbyuser}
                            onChange={(e) => setNewTopicbyuser(e.target.value)} // Capture the input value
                            onBlur={handleNewTopicBlur} // Handle topic addition on blur
                            className={styles.topicInput}
                            onKeyDown={handleEnterPress}
                        />
                        )}
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                    {topics.map((topic, rowIndex) => (
                        <tr key={rowIndex} className={rowIndex % 2 === 0 ? styles.whiteRow : styles.greyRow}>
                            <td className={styles.topicSelect}>
                            <input
                                type="checkbox"
                                className={styles.largeCheckbox}
                                checked={selectedTopics.includes(rowIndex)}
                                onChange={() => handleCheckboxChange(rowIndex)}
                            />
                            {/* {rowIndex} */}
                        </td>
                            <td className={styles.topicColumn}>
                                <div dangerouslySetInnerHTML={{ __html: topic}} />
                                
                                {/* <button onClick={() => handleDeleteTopic(rowIndex)}>Delete</button> */}
                            </td>
                            {Object.keys(fileTexts).map((filename, colIndex) => (
                                <td key={colIndex} className={styles.fileanalaysisColumn}>
                                    <span>
                                    {showFullStates[`${rowIndex}-${filename}`] ?<>
                                    <div style={{ position:'relative',}}>
                                    <span className={styles.fullContent}
                                            dangerouslySetInnerHTML={{
                                                __html: (extractInfoContent(analysisResults[`${rowIndex}-${filename}`] || ''))
                                            }}
                                        /><span className={`${styles.openIcon}`}  onClick={() => handleShowFullClick(topic, rowIndex, filename)}><img src={arrowdown} alt="Analyze" style={{ width: "15px", height: "15px" }} /></span>
                                    </div>
                                    </>
                                        :<span
                                        className={styles.halfContent}
                                        // dangerouslySetInnerHTML={{
                                        //     __html: 
                                        //       analysisResults[`${rowIndex}-${filename}`]?.length > 40
                                        //         ? (analysisResults[`${rowIndex}-${filename}`] || '').slice(0, 143) + '...'
                                        //         : analysisResults[`${rowIndex}-${filename}`] || ''
                                        //   }}
                                          
                                        dangerouslySetInnerHTML={{
                                            __html: analysisResults[`${rowIndex}-${filename}`]?.length > 40
                                              ? extractInfoContent((analysisResults[`${rowIndex}-${filename}`] || '').slice(0, 143) + '...')
                                              : extractInfoContent(analysisResults[`${rowIndex}-${filename}`] || '')
                                          }}
                                          
                                    />}
                                        
                                        {/* {analysisResults[`${rowIndex}-${filename}`] && <span className={`${styles.truncatedContent} ${styles.moreIcon}`}><AddOutlined /></span>} */}
                                        {analysisResults[`${rowIndex}-${filename}`] && analysisResults[`${rowIndex}-${filename}`]?.length > 40 && <span className={`${styles.moreIcon}`} onClick={() => handleShowFullClick(topic, rowIndex, filename)}><img src={arrowdown} alt="Analyze" style={{ width: "15px", height: "15px" }} /></span>}
                                        
                                        
                                    </span>

                                    {!analysisResults[`${rowIndex}-${filename}`] && (
                                    <>
                                        {loadingStates[`${rowIndex}-${filename}`] ? (
                                        <span className={styles.loadingIcon}><img src={GearGif} alt="Loading..." style={{ width: "70px", height: "70px" }} /></span>
                                        ) : (
                                            <div
                                            onClick={() => handleAnalyzeClick(topic, rowIndex, filename)}
                                            className={styles.anaiconButton}
                                          >
                                            <img src={analyzeIcon} alt="Analyze" style={{ width: "2em", height: "3em" }} />
                                            <p>Analyse</p>
                                          </div>
                                        )}
                                    </>
                                    )}
                                    {analysisResults[`${rowIndex}-${filename}`] &&<span className={styles.viewMoreIcon} onClick={() => handleOpenModal(analysisResults[`${rowIndex}-${filename}`], filename)}><AddOutlined /></span>}
                                </td>
                            ))}
                            <td className={styles.finalColumn}>
                            {!finalAnalysisResults[rowIndex] && (
                                <>
                                {compareLoading[rowIndex] ? (
                                    <span className={styles.loadingIcon}><img src={GearGif} alt="Loading..." style={{ width: "70px", height: "70px" }} /></span>
                                ) : (
                                    
                                    <div
                                    onClick={() => {
                                        // Get all filenames from fileTexts
                                        const filenames = Object.keys(fileTexts);
                                      
                                        // Check if every filename has an analysis result for the given rowIndex
                                        const allFilesHaveAnalysisResult = filenames.every(filename => {
                                          const key = `${rowIndex}-${filename}`;
                                          return analysisResults.hasOwnProperty(key);
                                        });
                                      
                                        // If all files have an analysis result for the rowIndex, call handleCompareClick
                                        if (allFilesHaveAnalysisResult) {
                                          handleCompareClick(topic, rowIndex);
                                        }
                                      }}
                                            className={styles.anaiconButton}
                                          >
                                            <img src={compareIcon} alt="Analyze" style={{ width: "2em", height: "2em" }} />
                                            <p>Compare</p>
                                          </div>
                                )}
                                </>
                            )}
                            {/* {finalAnalysisResults[rowIndex] && (
                                <p className={styles.contradict}>Contradiction identified :</p>
                            )} */}
                            {showFullStates2[rowIndex] ? (
                                    <>
                                        <div style={{ position: "relative" }}>
                                        {/* <p>{finalAnalysisResults[rowIndex] || ""}</p> */}
                                        
                                            <span
                                                className={styles.fullContent2}
                                                dangerouslySetInnerHTML={{
                                                    __html:renderHtmlContent3(finalAnalysisResults[rowIndex] || ""),
                                                    // __html: finalAnalysisResults[rowIndex] || "",
                                                }}
                                            />
                                            <span
                                                className={`${styles.openIcon}`}
                                                onClick={() => handleShowFullClick2(rowIndex)}
                                            >
                                                
                                                <img
                                                    src={arrowdown}
                                                    alt="Show Less"
                                                    style={{ width: "15px", height: "15px", transform: "rotate(180deg)" }}
                                                />
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {finalAnalysisResults[rowIndex] &&
                                        // <span className={styles.halfContent2}>{finalAnalysisResults[rowIndex]?.length > 40
                                        //            ? finalAnalysisResults[rowIndex].slice(0, 87) + '...'
                                        //           : finalAnalysisResults[rowIndex] || ""
                                        //       }</span>
                                        <span
                                            className={styles.halfContent2}
                                            dangerouslySetInnerHTML={{
                                                __html: finalAnalysisResults[rowIndex]?.length > 40
                                                  ? renderHtmlContent3(finalAnalysisResults[rowIndex].slice(0, 96) + '...')
                                                  : finalAnalysisResults[rowIndex] || ""
                                              }}
                                        />
                                        }
                                        {finalAnalysisResults[rowIndex] && finalAnalysisResults[rowIndex]?.length > 40 &&(
                                            <span
                                                className={`${styles.moreIcon}`}
                                                onClick={() => handleShowFullClick2(rowIndex)}
                                            >
                                                
                                                <img
                                                    src={arrowdown}
                                                    alt="Show More"
                                                    style={{ width: "15px", height: "15px" }}
                                                />
                                            </span>
                                        )}
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                    {/* <tr>
                        <td>
                            <strong>Transcript Analysis</strong>
                        </td>
                        {Object.keys(fileTexts).map((filename, index) => (
                            <td key={index}>
                                <button onClick={() => handleCharacterAnalysis(filename)}>Character Analysis</button>
                                <div dangerouslySetInnerHTML={{ __html: finalAnalysisResults[`characterAnalysis-${filename}`] || '' }} />
                            </td>
                        ))}
                    </tr> */}
                </tbody>
            </table></div>}
            </div>
            </div>
            
            {/* <div>
                <h3>Add New Topic</h3>
                <input
                    type="text"
                    value={newTopic}
                    onChange={(e) => setNewTopic(e.target.value)}
                    placeholder="Enter new topic"
                />
                <button onClick={handleAddTopic}>Add Topic</button>
            </div> */}
          
            <Modal filenameTouch={filenameTouch} fileBlobUrls={fileBlobUrls} isOpen={isModalOpen} content={modalContent} onClose={handleCloseModal} selectedFiles={selectedFiles}/>
            {model2 && <Modal2 closeThis={closeThis} selectedFiles={selectedFiles} characterAnswer={characterAnswer}/>}
        </div>
    );
};

export default Comparison;
