
export const GLOBAL_DATA_ACTION = 'global_data';
export const GLOBAL_FIRST_KEY_CLICK = 'global_first';

export const globalDataAction = (payload) => ({
  type: GLOBAL_DATA_ACTION,
  payload,
});

export const globalFirstKeyClick = (payload) => ({
  type: GLOBAL_FIRST_KEY_CLICK,
  payload,
});
