import React from 'react';

const VideoPlayer = ({ videoUrl }) => {
  return (
    <video
      autoPlay
      loop
      style={{ width: '100%', height: 'auto' }}
    >
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;