// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Allpagee.css */

.Home {
  /* background-color: green; */
  /* overflow: hidden;  */
  position: relative;
}

.all-page-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* height: 100vh;  */
  padding: 10px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  height: 100%;
}

.leftside {
  position: sticky; 
  top: 6%; 
  width: 40%; 
  /* background-color: #002fd7; */
  /* z-index: 1;  */
  padding: 10px;
  /* height: calc(100vh - 10px); */
}

.rightside {
  width: 60%;
  /* background-color: #c10707; */
  padding: 10px;
  /* height: calc(100vh - 10px);  */
  /* overflow-y: scroll; */
  max-height: auto;
}

@media (min-width: 360px) and (max-width: 740px) {
  
  .leftside {
    display: none !important;;
  }
  .rightside {
    width: 100%;
  }
  
}

`, "",{"version":3,"sources":["webpack://./src/Components/SingleBlog/HomePage/Allpagee.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB;EACE,6BAA6B;EAC7B,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,oCAAoC;EACpC,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,OAAO;EACP,UAAU;EACV,+BAA+B;EAC/B,iBAAiB;EACjB,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,UAAU;EACV,+BAA+B;EAC/B,aAAa;EACb,iCAAiC;EACjC,wBAAwB;EACxB,gBAAgB;AAClB;;AAEA;;EAEE;IACE,wBAAwB;EAC1B;EACA;IACE,WAAW;EACb;;AAEF","sourcesContent":["/* Allpagee.css */\r\n\r\n.Home {\r\n  /* background-color: green; */\r\n  /* overflow: hidden;  */\r\n  position: relative;\r\n}\r\n\r\n.all-page-container {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: flex-start;\r\n  /* height: 100vh;  */\r\n  padding: 10px;\r\n  box-sizing: border-box;\r\n  background-color: rgb(255, 255, 255);\r\n  height: 100%;\r\n}\r\n\r\n.leftside {\r\n  position: sticky; \r\n  top: 6%; \r\n  width: 40%; \r\n  /* background-color: #002fd7; */\r\n  /* z-index: 1;  */\r\n  padding: 10px;\r\n  /* height: calc(100vh - 10px); */\r\n}\r\n\r\n.rightside {\r\n  width: 60%;\r\n  /* background-color: #c10707; */\r\n  padding: 10px;\r\n  /* height: calc(100vh - 10px);  */\r\n  /* overflow-y: scroll; */\r\n  max-height: auto;\r\n}\r\n\r\n@media (min-width: 360px) and (max-width: 740px) {\r\n  \r\n  .leftside {\r\n    display: none !important;;\r\n  }\r\n  .rightside {\r\n    width: 100%;\r\n  }\r\n  \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
