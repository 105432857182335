// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.HeadlineSection1_sectionContainer__9f0up{
    flex: 1 1;
    width: 100%;
    /* margin: 0 10%; */
}
.HeadlineSection1_subHeading__ngTkM{
    width: 70%;
    font-size: 3em;
    font-weight: bold;
    margin: 0 auto; 
    float: left;
}
.HeadlineSection1_tryButton__ubk9x{
    cursor:default;
    margin-top: 3em;
    border-radius: 5px;
    background-color: rgb(26, 106, 226);
    color: #fff;
    padding: 7px 12px;
    width: 50%;
    height: 54px;
    font-weight: 200;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 22px;
  }

  .HeadlineSection1_tryButton__ubk9x a{
    text-decoration: none;
    color: #fff;
  }
.HeadlineSection1_subPara__JEgkY{
    width: 70%;
    margin: 0 auto;
    margin-top: 2em;
    color: rgb(97, 97, 97);
}
.HeadlineSection1_secondHead__BRD33{
    color: rgb(123, 0, 255);
}`, "",{"version":3,"sources":["webpack://./src/Components/Home/HeadlineSection/HeadlineSection1.module.css"],"names":[],"mappings":";AACA;IACI,SAAO;IACP,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,cAAc;IACd,iBAAiB;IACjB,cAAc;IACd,WAAW;AACf;AACA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,mCAAmC;IACnC,WAAW;IACX,iBAAiB;IACjB,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,WAAW;EACb;AACF;IACI,UAAU;IACV,cAAc;IACd,eAAe;IACf,sBAAsB;AAC1B;AACA;IACI,uBAAuB;AAC3B","sourcesContent":["\r\n.sectionContainer{\r\n    flex: 1;\r\n    width: 100%;\r\n    /* margin: 0 10%; */\r\n}\r\n.subHeading{\r\n    width: 70%;\r\n    font-size: 3em;\r\n    font-weight: bold;\r\n    margin: 0 auto; \r\n    float: left;\r\n}\r\n.tryButton{\r\n    cursor:default;\r\n    margin-top: 3em;\r\n    border-radius: 5px;\r\n    background-color: rgb(26, 106, 226);\r\n    color: #fff;\r\n    padding: 7px 12px;\r\n    width: 50%;\r\n    height: 54px;\r\n    font-weight: 200;\r\n    align-items: center;\r\n    justify-content: center;\r\n    display: flex;\r\n    font-size: 22px;\r\n  }\r\n\r\n  .tryButton a{\r\n    text-decoration: none;\r\n    color: #fff;\r\n  }\r\n.subPara{\r\n    width: 70%;\r\n    margin: 0 auto;\r\n    margin-top: 2em;\r\n    color: rgb(97, 97, 97);\r\n}\r\n.secondHead{\r\n    color: rgb(123, 0, 255);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionContainer": `HeadlineSection1_sectionContainer__9f0up`,
	"subHeading": `HeadlineSection1_subHeading__ngTkM`,
	"tryButton": `HeadlineSection1_tryButton__ubk9x`,
	"subPara": `HeadlineSection1_subPara__JEgkY`,
	"secondHead": `HeadlineSection1_secondHead__BRD33`
};
export default ___CSS_LOADER_EXPORT___;
