// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.module.css */

.styles_container__mpXTh {
  display: flex;
  align-items: center;
}

.styles_timeUnit__3j\\+8a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles_timeValue__jAaCL {
  display: flex;
  align-items: center;
}

.styles_timeValue__jAaCL span {
  margin-left: 5px;
}

.styles_progressBar__jkeCY {
  width: 5em;
  margin-right: 8px;
}

.styles_progressBar__jkeCY .styles_MuiLinearProgress-bar__vI2pe {
  height: 10px;
  border-radius: 5px;
  background-color: #4caf50;
}

.styles_tooltip__0i0KF {
  display: none;
  position: absolute;
  bottom: -4em;
  left: 50%;
  transform: translateX(-10%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.styles_container__mpXTh:hover .styles_tooltip__0i0KF {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/Components/Navbar/styles.module.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,sBAAsB;EACtB,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;AACf;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* styles.module.css */\n\n.container {\n  display: flex;\n  align-items: center;\n}\n\n.timeUnit {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.timeValue {\n  display: flex;\n  align-items: center;\n}\n\n.timeValue span {\n  margin-left: 5px;\n}\n\n.progressBar {\n  width: 5em;\n  margin-right: 8px;\n}\n\n.progressBar .MuiLinearProgress-bar {\n  height: 10px;\n  border-radius: 5px;\n  background-color: #4caf50;\n}\n\n.tooltip {\n  display: none;\n  position: absolute;\n  bottom: -4em;\n  left: 50%;\n  transform: translateX(-10%);\n  background-color: #fff;\n  border: 1px solid #ccc;\n  padding: 8px;\n  border-radius: 4px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n  z-index: 1000;\n}\n\n.container:hover .tooltip {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__mpXTh`,
	"timeUnit": `styles_timeUnit__3j+8a`,
	"timeValue": `styles_timeValue__jAaCL`,
	"progressBar": `styles_progressBar__jkeCY`,
	"MuiLinearProgress-bar": `styles_MuiLinearProgress-bar__vI2pe`,
	"tooltip": `styles_tooltip__0i0KF`
};
export default ___CSS_LOADER_EXPORT___;
