// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/generateIcon-cropped.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.custom-container h1 {
    font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  }


  .custom-container h2 {
    font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  }

  .custom-container h3 {
    font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  }

  .custom-container h4 {
    font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  }
  
  .custom-container h5 {
    font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  }

  .custom-container p {
    margin-top: 10px;
    width: 100%;
    padding: 5px;
  }

  .custom-container strong {
    font-weight: bold;
  }

  .custom-container li {
    padding: 5px;
    list-style-type: disc;
  }
  .generateContainer{
    width: 12em;
    height: 5em;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
    background-size: contain;
    position: absolute;
    left:280px;
    top:-28px;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Products/Summeriser/AdvancedSummary.css"],"names":[],"mappings":";;AAEA;IACI,eAAe;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ;;;EAGA;IACE,eAAe;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ;;EAEA;IACE,eAAe;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ;;EAEA;IACE,eAAe;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ;;EAEA;IACE,eAAe;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,qBAAqB;EACvB;EACA;IACE,WAAW;IACX,WAAW;IACX,2EAAmF;IACnF,wBAAwB;IACxB,kBAAkB;IAClB,UAAU;IACV,SAAS;EACX","sourcesContent":["\r\n\r\n.custom-container h1 {\r\n    font-size: 22px;\r\n  font-weight: bold;\r\n  margin-top: 10px;\r\n  padding: 5px;\r\n  }\r\n\r\n\r\n  .custom-container h2 {\r\n    font-size: 22px;\r\n  font-weight: bold;\r\n  margin-top: 10px;\r\n  padding: 5px;\r\n  }\r\n\r\n  .custom-container h3 {\r\n    font-size: 20px;\r\n  font-weight: bold;\r\n  margin-top: 10px;\r\n  padding: 5px;\r\n  }\r\n\r\n  .custom-container h4 {\r\n    font-size: 18px;\r\n  font-weight: bold;\r\n  margin-top: 10px;\r\n  padding: 5px;\r\n  }\r\n  \r\n  .custom-container h5 {\r\n    font-size: 16px;\r\n  font-weight: bold;\r\n  margin-top: 10px;\r\n  padding: 5px;\r\n  }\r\n\r\n  .custom-container p {\r\n    margin-top: 10px;\r\n    width: 100%;\r\n    padding: 5px;\r\n  }\r\n\r\n  .custom-container strong {\r\n    font-weight: bold;\r\n  }\r\n\r\n  .custom-container li {\r\n    padding: 5px;\r\n    list-style-type: disc;\r\n  }\r\n  .generateContainer{\r\n    width: 12em;\r\n    height: 5em;\r\n    background: url('../../../assets/generateIcon-cropped.svg') no-repeat center center;\r\n    background-size: contain;\r\n    position: absolute;\r\n    left:280px;\r\n    top:-28px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
