import React, { useEffect } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import styles from './styles.module.css';

const TimeProgressBar = ({ usageData }) => {
  const readingTime = Math.floor(usageData["monthlywordused"] / 250 * 60); // Convert words to seconds
  const writingTime = Math.floor(usageData["monthlywordused"] / 250) * 3600; // Convert words to seconds
  const totalSeconds = readingTime + writingTime;
  const readingPercentage = (readingTime / totalSeconds) * 100;

  useEffect(() => {
    console.log("FDDD",readingTime,writingTime)
  }, [usageData]);
  

  return (
    <div>
      {(readingTime>0 || writingTime>0) && <div className={styles.container}>
        <div className={styles.timeUnit} style={{ marginRight: '8px' }}>
          {/* {Math.floor(usageData["dailywordsused"] / 3600) > 0 
            ? (
              <div className={styles.container} style={{ gap: '5px' }}>
                <div className={styles.timeUnit}>
                  <div className={styles.timeValue}>
                    <span>{Math.floor(usageData["dailywordsused"] / 3600)}</span>
                    <span>hr</span>
                  </div>
                </div>
                <div className={styles.timeUnit}>
                  <div className={styles.timeValue}>
                    <span>{Math.floor((usageData["dailywordsused"] % 3600) / 60)}</span>
                    <span>min</span>
                  </div>
                </div>
              </div>
            ) 
            : Math.floor((usageData["dailywordsused"] % 3600) / 60) > 0 
              ? (
                <div className={styles.container} style={{ gap: '5px' }}>
                  <div className={styles.timeUnit}>
                    <div className={styles.timeValue}>
                      <span>{Math.floor((usageData["dailywordsused"] % 3600) / 60)}</span>
                      <span>min</span>
                    </div>
                  </div>
                </div>
              ) 
              : (
                <div className={styles.container} style={{ gap: '5px' }}>
                  <div className={styles.timeUnit}>
                    <div className={styles.timeValue}>
                      <span>{Math.floor(usageData["dailywordsused"] % 60)}</span>
                      <span>sec</span>
                    </div>
                  </div>
                </div>
              )
          } */}
        </div>
        {/* <div className={styles.progressBar} style={{ marginRight: '8px' }}>
          <LinearProgress
            variant="determinate"
            value={readingPercentage}
          />
        </div> */}
        <div className={styles.timeUnit} style={{ marginLeft: '8px' }}>
        {Math.floor(usageData["dailywordsused"] / 250)+Math.floor(usageData["dailywordsused"] / 3600) > 0 
            ? (
              <div className={styles.container} style={{ gap: '5px' }}>
                <div className={styles.timeUnit}>
                  <div className={styles.timeValue}>
                    <span>{Math.floor(usageData["dailywordsused"] / 250)+Math.floor(usageData["dailywordsused"] / 3600)}</span>
                    <span>hr</span>
                  </div>
                </div>
                <div className={styles.timeUnit}>
                  <div className={styles.timeValue}>
                    <span>{Math.floor((usageData["dailywordsused"] % 250) / (250 / 60))+Math.floor((usageData["dailywordsused"] % 3600) / 60)}</span>
                    <span>min</span>
                  </div>
                </div>
              </div>
            ) 
            : Math.floor((usageData["dailywordsused"] % 250) / (250 / 60))+Math.floor((usageData["dailywordsused"] % 3600) / 60) > 0 
              ? (
                <div className={styles.container} style={{ gap: '5px' }}>
                  <div className={styles.timeUnit}>
                    <div className={styles.timeValue}>
                      {/* <span>{Math.floor((usageData["dailywordsused"] % 250) / (250 / 60))}</span> */}
                      <span>{Math.floor((usageData["dailywordsused"] % 250) / (250 / 60))+Math.floor((usageData["dailywordsused"] % 3600) / 60)}</span>
                      <span>min</span>
                    </div>
                  </div>
                </div>
              ) 
              : (
                <div className={styles.container} style={{ gap: '5px' }}>
                  <div className={styles.timeUnit}>
                    <div className={styles.timeValue}>
                      <span>{Math.floor(usageData["dailywordsused"] % (250 / 60)) + Math.floor(usageData["dailywordsused"] % 60)}</span>
                      <span>sec</span>
                    </div>
                  </div>
                </div>
              )
          }
        </div>
        <div className={styles.tooltip}>
        The average person can read and summarize a page in 5 minutes.<br/>
        By using PDFING you have saved {Math.floor(usageData["dailywordsused"] / 250)+Math.floor(usageData["dailywordsused"] / 3600)} hours {Math.floor((usageData["dailywordsused"] % 250) / (250 / 60))+Math.floor((usageData["dailywordsused"] % 3600) / 60)} mins
        . You're Welcome!<br />
        </div>
      </div>}
    </div>
  );

};

export default TimeProgressBar;