// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rightMain {
  text-align: justify;
  /* background-color: rgb(255, 255, 255); */
  color: rgb(0, 0, 0);
  padding: 10px;
  width: 100%; 
  position: relative; 
}

.upperDes {
  position: relative;
  text-align: justify;
  padding: 10px;
  line-height: 1.667em;
  font-size: 24;
  font-family: sans-serif;
}
.heading {
  color: black;
  font-size: 29px;
  font-family: sans-serif;
  font-weight: 600;
  margin: 10px;
  width: 700px;
  text-align: justify;
  text-align: left;
  position: relative;
}

.description {
  position: relative;
  text-align: justify;
  font-family: sans-serif;
  padding: 10px;
  font-size: 18px;
  line-height: 1.667em;
}
.clas2{
  padding-top: 80px;
  margin-top: -80px; 
}

.des {
  font-size: 18px;
  margin: 10px;
  width: 700px;
  line-height: 1.667em;
  font-family: sans-serif;
}

@media (min-width: 360px) and (max-width: 740px) {
  .heading {
    font-size: 20px;
    text-align: justify;
    width: 100%;
    
  }
}

`, "",{"version":3,"sources":["webpack://./src/Components/SingleBlog/Allin/Description/Descriptionright.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,0CAA0C;EAC1C,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EAEnB,aAAa;EACb,oBAAoB;EACpB,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,eAAe;EACf,oBAAoB;AACtB;AACA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,oBAAoB;EACpB,uBAAuB;AACzB;;AAEA;EACE;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;;EAEb;AACF","sourcesContent":[".rightMain {\r\n  text-align: justify;\r\n  /* background-color: rgb(255, 255, 255); */\r\n  color: rgb(0, 0, 0);\r\n  padding: 10px;\r\n  width: 100%; \r\n  position: relative; \r\n}\r\n\r\n.upperDes {\r\n  position: relative;\r\n  text-align: justify;\r\n  font-family: sans-serif;\r\n  padding: 10px;\r\n  line-height: 1.667em;\r\n  font-size: 24;\r\n  font-family: sans-serif;\r\n}\r\n.heading {\r\n  color: black;\r\n  font-size: 29px;\r\n  font-family: sans-serif;\r\n  font-weight: 600;\r\n  margin: 10px;\r\n  width: 700px;\r\n  text-align: justify;\r\n  text-align: left;\r\n  position: relative;\r\n}\r\n\r\n.description {\r\n  position: relative;\r\n  text-align: justify;\r\n  font-family: sans-serif;\r\n  padding: 10px;\r\n  font-size: 18px;\r\n  line-height: 1.667em;\r\n}\r\n.clas2{\r\n  padding-top: 80px;\r\n  margin-top: -80px; \r\n}\r\n\r\n.des {\r\n  font-size: 18px;\r\n  margin: 10px;\r\n  width: 700px;\r\n  line-height: 1.667em;\r\n  font-family: sans-serif;\r\n}\r\n\r\n@media (min-width: 360px) and (max-width: 740px) {\r\n  .heading {\r\n    font-size: 20px;\r\n    text-align: justify;\r\n    width: 100%;\r\n    \r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
