import React, { useEffect, useState } from 'react';
import { Typography, Select, MenuItem, Button, AppBar, Toolbar, Grid, TextField, Tabs, Tab } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from './HeldHinesLogo.jpg'

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';



const Header = ({ name, Cases, setChat, email }) => {
    const [selectedCase, setSelectedCase] = useState({S:"Select a case"});
    const [value, setValue] = React.useState("");
    const [caseValue,setCaseValue]=React.useState('');

    const navigate = useNavigate();

    const handleCaseChange = (newValue) => {

      if (newValue){
        setChat(newValue.S);
        setCaseValue(newValue.S)
        localStorage.setItem("selectedCase",JSON.stringify(newValue))
        setSelectedCase(newValue)
      }    
    
    };

    useEffect(() => {
      let item = localStorage.getItem("selectedCase");

      if (item) {
        let parsedItem = JSON.parse(item);

          setSelectedCase(parsedItem);
          setChat(parsedItem.S);
          setCaseValue(parsedItem.S);
      }
    }, []);
    

    const handleDownload = () => {
        const uploadPageUrl = `https://upload.verisage.ai:3000?email=${encodeURIComponent(email)}&name=${encodeURIComponent(name)}`;
        window.location.href = uploadPageUrl;
    };

    

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    const settings = [`${name}`, 'Settings','Logout'];


    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };




    const handleSettingClick = (setting) => {
      handleCloseUserMenu(); 
    
      
      if (setting === "Logout") {
        localStorage.removeItem("name")
        localStorage.removeItem("accessToken")
        localStorage.removeItem("email")


        window.location.href = 'https://verisage.auth.us-east-1.amazoncognito.com/login?client_id=6ej0b5k8ljvmfd6vndgaokeb8b&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fverisage.ai%3A3000'

        
      } 
    };

    return (
     <>
        <AppBar sx={{backgroundColor:"#0066cc"}}  position="fixed">
          <Container  maxWidth="100%">
            <Toolbar   disableGutters>
              <Typography
                variant="h5"
                noWrap
                
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  width:"fit-content",
                  width:"20%",
                  letterSpacing: ".3rem",
                  // color: "inherit",
                  textDecoration: "none",
                }}
              >
                Pdfing.Ai
              </Typography>

                
                <div  style={{fontFamily: "monospace",paddingLeft:"20px",width:"80%"}} >
                    Fast, free and secure when you're f*ing with your pdfs 
                </div>
              

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <Typography
                variant="h6"
                noWrap
                
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Pdfing.Ai
              </Typography>

              
              </Box>

              {/* <Typography
                variant="h5"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                VERISss
              </Typography> */}
              {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{
                    maxWidth: "200px",
                    maxHeight: "100px",
                    width: "auto",
                    height: "auto",
                  }}
                />
              </Box> */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "30%",
                  marginRight: "10px",
                }}
              >
               
              </Box>


  <Box
    sx={{
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      width: "22%",
      marginRight: "40px",
      display: { xs: "none", md: "flex" },
      color: "#fff",
      borderRadius: "8px",
      padding: "5px",
      paddingBottom:"5px"
    }}
  >
    {/* <div style={{ width: "16%" }}>Case </div>
    <Autocomplete
      id="caseSelect"
      options={Cases}
      getOptionLabel={(option) => option.S}
      value={selectedCase}
      onChange={(event, newValue) => handleCaseChange(newValue)}
      sx={{
        borderRadius: "70px",
        height: "50px",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        overflow: "hidden",
        borderBlockColor: "white",
        border: 0,
        backgroundColor: "white",
        borderRadius: "70px",
        "& .MuiAutocomplete-inputRoot": { textAlign: "center" },
        "& .MuiAutocomplete-input": { textAlign: "center" },
        "& .MuiAutocomplete-tag": {
          justifyContent: "center",
          alignItems: "center",
        },
        "& input": { color: "black" },
        "& .MuiAutocomplete-endAdornment": {
          justifyContent: "center", // Center align horizontally
          alignItems: "center", // Center align vertically
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            style: { fontWeight: "bold", color: "black" },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
    
              </>
            ),
          }}
        />
      )}
      style={{  width: "80%" }}
    /> */}
  </Box>



{/* 
              <Box sx={{ flexGrow: 0 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                    <Typography>Welcome {name}</Typography>
                  </Box>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={name} src="" />
                  </IconButton>
                </Box>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={() => handleSettingClick(setting)}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box> */}
            </Toolbar>
          </Container>
        </AppBar>
          {/* to prevent from content being hidden behind the appbar */}
          <Toolbar /> 
      </>
    );
};

export default Header;




