import { Alert, AppBar, Container } from "@mui/material";
import styles from "./Navbar.module.css";
import { NavLink, useNavigate,useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "../Common";
import mainLogo from "../../assets/mainLogo.svg"
import PricingModal from "../PricingModal/PricingModal";
import MenuPage from "./MenuPage";
import TimeProgressBar from "./TimeProgressBar";


function Navbar({couponModal,setCouponModel,setPremiumError, premiumError, isHomeWork, planName, checkUsage, isPremium, setIsPremium, usageData,setUserEmail, setIsHomeWork,showMasterListModal,setShowMasterListModal, setIsAuthenticated, data, setFileModal,fileModal }) {
  const { isAuthenticated, loginWithRedirect, logout, user, loginWithPopup } = useAuth0();
  const [loading, setLoading] = useState(false);
  console.log("useruser", user);
  if(user){
    setUserEmail(user.email)
  }
  const navigate = useNavigate(); 
  const location = useLocation(); 
  const [showMenu, setShowMenu] = useState(false);

  // const handleNavigate = () => {
  //   navigate("/summariser");
  // };

  // useEffect(() => {
  //   const isTryForFree = sessionStorage.getItem("isTryForFree");
  //   console.log("isTryForFree: 1", isTryForFree);

  //   if ((isAuthenticated && isTryForFree) === true) {
  //   console.log("isTryForFree:2 ", isAuthenticated, isTryForFree);

  //     sessionStorage.setItem("isTryForFree", false);
  //     console.log("isTryForFree: ", isAuthenticated, isTryForFree);
  //     if (isTryForFree) {
  //       handleNavigate();
  //     }
  //   }
  // }, [isAuthenticated]);

  function handleLogout() {
    localStorage.removeItem("authToken");
    // setIsAuthenticated(false);
    // navigate('/summeriser');
  }
  const handleCompariosion = ()=>{
    if (!isAuthenticated){
      loginWithPopup();
      return
    }
    setFileModal(!fileModal);
    setShowMenu(false)
    if (window.location.pathname === "/comparison") {
      // window.location.reload();
      navigate('/comparison');
  }
    
  }
  const handleButtonClick = () => {
    console.log("gg6")
    setIsPremium(true);
    // if (location.pathname === '/summariser'|| location.pathname === '/comparison') {
    //     setIsPremium(true); // Update the state when "Get Premium" is clicked
    // } else {
    //   setIsHomeWork(false)
    //     setIsPremium(false); // Reset the state if navigating to "Summarizer"
    //     navigate('/summariser');
    // }
};

useEffect(() => {
  if (isAuthenticated) {
    const registerUser = async () => {
      try {
        const response = await fetch("https://www.pdfing.ai:2000/user-register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
          }),
        });

        if (!response.ok) {
          throw new Error(`RegisterUser API Error: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("RegisterUser API response:", responseData);
      } catch (error) {
        console.error("Error in RegisterUser API:", error);
      }
    };

    registerUser(); // Call the async function
  }
}, [isAuthenticated, user?.email]);

  return (
    <>
    <AppBar className={styles.appbarContainer}>
      <Container className={styles.appbarSub}>
      <div className={styles.appbarSub}>
        <div>
          <a href="/">
            <div className={styles.mainLogoIcon}></div>
          </a>
        </div>

        <div className={styles.navbarContainer}>
          <a href="/" className={styles.navLink}>
            Home
          </a>

          <NavLink
            to="/blogs"
            className={styles.navLink}
            style={{ color: "rgb(99, 99, 99)", textDecoration: "none" }}
            onMouseEnter={(e) => (e.target.style.textDecoration = "none")}
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          >
            Blogs
          </NavLink>

          <a
            onClick={() => {
              setIsHomeWork(false);
              navigate("/summariser")
              setFileModal(false)
              setShowMenu(false)
            }}
            className={styles.navLink}
          >
            Summarizer
          </a>

          <div
            onClick={handleCompariosion}
            className={styles.navLink}
            style={{ color: "rgb(99, 99, 99)", textDecoration: "none" }}
            onMouseEnter={(e) => (e.target.style.textDecoration = "none")}
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          >
            Comparision Tool
          </div>

          <div
            onClick={() => {
              setIsHomeWork(true);
              navigate("/check-homeworks");
              setFileModal(false)
              setShowMenu(false)
            }}
            className={styles.navLink}
            style={{ color: "rgb(99, 99, 99)", textDecoration: "none" }}
            onMouseEnter={(e) => (e.target.style.textDecoration = "none")}
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
          >
            Check Homework
          </div>

          {isAuthenticated ? (
            <div
              className={styles.logout}
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Logout
            </div>
          ) : (
            <div
              onClick={() => {
                sessionStorage.setItem("isTryForFree", true);
                console.log("ppp2")
      loginWithPopup();
              }}
              className={styles.navLink}
            >
              Sign up / Sign in
            </div>
          )}
          <TimeProgressBar usageData={usageData}/>
          {/* <div>{Math.floor(usageData["dailywordsused"] / 3600) > 0 ? `${Math.floor(usageData["dailywordsused"] / 3600)} hr, ${Math.floor((usageData["dailywordsused"] % 3600) / 60)} min` : Math.floor((usageData["dailywordsused"] % 3600) / 60) > 0 ? `${Math.floor((usageData["dailywordsused"] % 3600) / 60)} min` : `${Math.floor(usageData["dailywordsused"] % 60)} sec`}</div>
          <div>{Math.floor(usageData["dailywordsused"] / 250) > 0 ? `${Math.floor(usageData["dailywordsused"] / 250)} hours, ${Math.floor((usageData["dailywordsused"] % 250) / (250 / 60))} minutes` : Math.floor((usageData["dailywordsused"] % 250) / (250 / 60)) > 0 ? `${Math.floor((usageData["dailywordsused"] % 250) / (250 / 60))} minutes` : `${Math.floor(usageData["dailywordsused"] % (250 / 60))} seconds`}</div> */}
        </div>
        {isAuthenticated && <div>
          <button onClick={()=>{
            setCouponModel(!couponModal)
          }} className={styles.tryButton}>
            Apply coupon code
          </button>
          </div>}

        <div className={styles.Userimage}>
          <div className={styles.subBtnGetstarted}>
            {isAuthenticated ? (
              planName !== "B" && (
                <button
                  className={styles.tryButton}
                  onClick={handleButtonClick}
                >
                  {planName === "A" ? 'Upgrade' : 'Get Premium'}
                </button>
              )
            ) : (
              <div
                className={styles.fileLabel}
                onClick={() => {
                  sessionStorage.setItem('isTryForFree', true);
                  loginWithPopup();
                }}
              >
                <div className={styles.tryButton}>{data}</div>
              </div>
            )}
          </div>
          <div
            onClick={() => {
              checkUsage();
              setShowMenu(!showMenu);
              setFileModal(false)
            }}
            className={styles.avatarImg}
          >
            <Avatar src={user?.picture} />
          </div>
        </div>

        {showMenu && (
          <MenuPage
            usageData={usageData}
            setFileModal={setFileModal}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            showMasterListModal={showMasterListModal}
            setShowMasterListModal={setShowMasterListModal}
            logout={logout}
            user={user}
          />
        )}
      </div>
      </Container>
    </AppBar>
    {isPremium && <PricingModal setPremiumError={setPremiumError} premiumError={premiumError} isPremium={isPremium} setIsPremium={setIsPremium} />}
    </>
  );
}
export default Navbar;
