// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PopModal_containerPopup__UwHj7{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    z-index: 1;
    background-color: aliceblue;
    padding: 1em 2em;
    border-radius: 10px;
    
}
.PopModal_containerPopup__UwHj7 h2{
    margin-bottom: 20px;
}
.PopModal_containerPopup__UwHj7 p{
    margin-bottom: 15px;
}
.PopModal_acceptBtn__oo\\+G7{
    padding: 10px 20px;
    background-color: #000000;
    border-radius: 10%;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Components/PopModal/PopModal.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,OAAO;IACP,SAAS;IACT,gCAAgC;IAChC,UAAU;IACV,UAAU;IACV,2BAA2B;IAC3B,gBAAgB;IAChB,mBAAmB;;AAEvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".containerPopup{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: fixed;\n    top:50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 70%;\n    z-index: 1;\n    background-color: aliceblue;\n    padding: 1em 2em;\n    border-radius: 10px;\n    \n}\n.containerPopup h2{\n    margin-bottom: 20px;\n}\n.containerPopup p{\n    margin-bottom: 15px;\n}\n.acceptBtn{\n    padding: 10px 20px;\n    background-color: #000000;\n    border-radius: 10%;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerPopup": `PopModal_containerPopup__UwHj7`,
	"acceptBtn": `PopModal_acceptBtn__oo+G7`
};
export default ___CSS_LOADER_EXPORT___;
