// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Testimonial_headLine__1cjNv{
    margin-top: 2rem;
    padding:5% 0;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 36px;
    color: #333;
    position: relative;
}
.Testimonial_headLine__1cjNv::after{
    position: absolute;
    content: "";
    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    height: 4px;
    width: 60px;
    bottom: 2.5rem;
    margin-left: -30px;
    left: 50%;
}
.Testimonial_blogSection__lEgvm{
    display: flex;
    flex-direction: row;
    gap: 3em;
}
.Testimonial_contentContainer__x2z\\+e{
    display: flex;
    gap: 2em;
    align-items: center;
    justify-content: center;
    padding: 3em 9px;
}
.Testimonial_imageStyles__gwjvh img{
    border-radius: 50%;
}
.Testimonial_textTestimonial__Z3KJQ{
    overflow: hidden;
    
}
.Testimonial_textTestimonial__Z3KJQ p{
    padding-bottom: 4px;
    margin-bottom: 0;
    font-size: 14px;
    font-style: italic;
    color: #646464;
}
.Testimonial_testMeta__rLe9B{
    margin-bottom: 10px; 
    font-size: 15px;
    font-weight: 600;
    color: #666;
}
.Testimonial_container__QfkFU{
    padding: 0 15rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Testmonials/Testimonial.module.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,+DAA+D;IAC/D,WAAW;IACX,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,SAAS;AACb;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;;AAEpB;AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,gBAAgB;AACpB","sourcesContent":["\r\n.headLine{\r\n    margin-top: 2rem;\r\n    padding:5% 0;\r\n    text-transform: uppercase;\r\n    font-weight: 800;\r\n    font-size: 36px;\r\n    color: #333;\r\n    position: relative;\r\n}\r\n.headLine::after{\r\n    position: absolute;\r\n    content: \"\";\r\n    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);\r\n    height: 4px;\r\n    width: 60px;\r\n    bottom: 2.5rem;\r\n    margin-left: -30px;\r\n    left: 50%;\r\n}\r\n.blogSection{\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 3em;\r\n}\r\n.contentContainer{\r\n    display: flex;\r\n    gap: 2em;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 3em 9px;\r\n}\r\n.imageStyles img{\r\n    border-radius: 50%;\r\n}\r\n.textTestimonial{\r\n    overflow: hidden;\r\n    \r\n}\r\n.textTestimonial p{\r\n    padding-bottom: 4px;\r\n    margin-bottom: 0;\r\n    font-size: 14px;\r\n    font-style: italic;\r\n    color: #646464;\r\n}\r\n.testMeta{\r\n    margin-bottom: 10px; \r\n    font-size: 15px;\r\n    font-weight: 600;\r\n    color: #666;\r\n}\r\n.container{\r\n    padding: 0 15rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headLine": `Testimonial_headLine__1cjNv`,
	"blogSection": `Testimonial_blogSection__lEgvm`,
	"contentContainer": `Testimonial_contentContainer__x2z+e`,
	"imageStyles": `Testimonial_imageStyles__gwjvh`,
	"textTestimonial": `Testimonial_textTestimonial__Z3KJQ`,
	"testMeta": `Testimonial_testMeta__rLe9B`,
	"container": `Testimonial_container__QfkFU`
};
export default ___CSS_LOADER_EXPORT___;
