import React, { useEffect, useRef, useState } from "react";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import pdfToText from "react-pdftotext";
import "./AdvancedSummary.css";
import { Box, Button, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import ShareLinkComponent from "./ShareLinkComponent";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import GearGif from "../../../GearGif.gif";
import { background } from "@chakra-ui/react";
import { Margin } from "@mui/icons-material";
import UploadCloud from "../../../UploadCloud.png";
import styles from "./FileUpload.module.css";
import AWS from 'aws-sdk';
import * as pdfjsLib from 'pdfjs-dist';
import styles2 from './Workoption.module.css'
import sourceImg from "../../../assets/Vector.svg"
import cloudImg from "../../../assets/clodImg.svg"
import generateIcon from "../../../assets/generateIcon-cropped.svg"
// const pdfjs = await import('pdfjs-dist/build/pdf');
// const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;




// import htmlToDocx from 'html-to-docx';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';



import { pdfjs } from 'react-pdf';

import MathList from "./Mathlist";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const smallTabStyle = {
  paddingLeft: "5px",
  paddingRight: "5px",
  Margin: "10px",
  borderRadius: "20px",
}




AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  region: 'eu-north-1',
});

//const S3_BUCKET = 'parvbucket12';
const S3_BUCKET = 'pdfing.ai';



export const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: 'eu-north-1',
});

function FileUploadComponent({setPremiumError, premiumError,setuploadError,setIsPremium,handleReset,isSelected,setHasMath,setUsageData,usageData,setUserFile,userFile,sourceFileName,setSourceFileName,assigFileName,setAssigFileName,isHomeWork, shareableLink, setShareableLink, modalOpen, setModalOpen, setSelectedMath, hasMath, isSelectedMath, setWordsCount, type1Value, type2Value, urlStats, response, setResponse, file, setFile, fileUrl, setFileUrl, fileContent, setFileContent, isLoading, ocrProcessing,
  setIsLoading, setUploadedfile, setShowSummaryChatbot,useOCR,setUseOCR,
  callOverviewOnce, fileName, setFileName, uploadError, wordsCount, curSummaryname, errorMessage, setErrorMessage }) {
  const [dropdown, setdropdown] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [images, setImages] = useState([]);
  const { user } = useAuth0();
  // const [fileName,setFileName] = useState(null);
  const inputRef = useRef(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setdropdown(null)
  };
  const toggleOCR = (name) => {
    console.log(`Toggling OCR for: ${name}`);
    if (name.trim() !== "") {
      if (useOCR.includes(name)) {
        // Remove the name from the array
        const updatedUseOCR = useOCR.filter(fileName => fileName !== name);
        setUseOCR(updatedUseOCR);
      } else {
        // Add the name to the array
        const updatedUseOCR = [...useOCR, name];
        setUseOCR(updatedUseOCR);
      }
    }
  };
const handleRadioChange = (event) => {
  if(hasMath){
    setHasMath(false);
    setSelectedMath(false)
  }else{ 
    setHasMath(true)
    setSelectedMath(true)
  }
};

  const handleMenuClick = (event, index) => {

    setAnchorEl(event.currentTarget);
    setdropdown(!dropdown)
  };
  const navigate = useNavigate();


  const uploadToS3 = async (file, filename) => {

    console.log("file name-----", filename)
    if (!file) return;



    const params = {
      Bucket: S3_BUCKET,
      Key: filename,
      Body: file,
      ACL: 'public-read',
      ContentType: 'application/msword',
    };


    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.Location);
        }
      });
    });
  };

  async function convertPdfToPng(file) {


    if (file.type !== 'application/pdf') {
      throw new Error("File type is not PDF");
    }

    const reader = new FileReader();

    reader.onload = async function () {
      const typedArray = new Uint8Array(this.result);

      // Load PDF document using pdfjsLib
      const loadingTask = pdfjsLib.getDocument(typedArray);
      try {
        const pdf = await loadingTask.promise; // Wait for the PDF to load
        const numPages = pdf.numPages;

        alert(`Number of pages: ${numPages}`);

        for (let i = 1; i <= numPages; i++) { // Pages are 1-indexed
          const page = await pdf.getPage(i);
          const canvas = document.createElement('canvas');
          const viewport = page.getViewport({ scale: 1 });

          const ctx = canvas.getContext('2d');
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderContext = {
            canvasContext: ctx,
            viewport: viewport,
          };

          await page.render(renderContext).promise; // Wait for rendering to complete

          canvas.toBlob(function (blob) {
            const link = document.createElement('a');
            link.download = `page-${i}.jpg`;
            link.href = URL.createObjectURL(blob);
            link.click();
          }, 'image/jpeg');
        }
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer
  }


  //   async function convertPdfToPng(file) {
  //     if (file.type !== 'application/pdf') {
  //         throw new Error("File type is not PDF");
  //     }

  //     // Load PDF document from file
  //     // const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
  //     const reader = new FileReader()
  //     reader.onload = function(){
  //       const typedArray = new Uint8Array(this.result)

  //       pdfjsLib.getDocument((typedArray).promise.then(function(pdf){
  //         const numPages = pdf.numPages

  //         alert(numPages)
  //         for (let i=0; i < numPages ; i++){
  //           pdf.getPage(i).then((page)=>{
  //             const canvas = document.createElement('canvas')
  //             const viewport = page.getViewport({scale:1})

  //             const ctx = canvas.getContext('2d')
  //             canvas.width = viewport.width
  //             canvas.height = viewport.height

  //             const renderContext ={
  //               canvasContext:ctx,
  //               viewport:viewport
  //             }

  //             page.render(renderContext).promise.then(function(){
  //               canvas.toBlob(function(blob){
  //                 const link = document.createElement('a')
  //                 link.download = 'page-' + i + '.jpg'
  //                 link.href = URL.createObjectURL(blob)
  //                 link.click()
  //               },'image/jpeg')
  //             })
  //           })
  //         }
  //       }))
  //     }


  //     reader.readAsArrayBuffer(file)

  //     // const pngImages = [];
  //     // for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
  //     //     const page = await pdf.getPage(pageNum);

  //     //     // Set scale for high-quality output (e.g., 2 for twice the resolution)
  //     //     const scale = 2;
  //     //     const viewport = page.getViewport({ scale });

  //     //     // Create a canvas to render the page
  //     //     const canvas = document.createElement('canvas');
  //     //     const context = canvas.getContext('2d');
  //     //     canvas.width = viewport.width;
  //     //     canvas.height = viewport.height;

  //     //     const renderContext = {
  //     //         canvasContext: context,
  //     //         viewport: viewport,
  //     //     };

  //     //     await page.render(renderContext).promise;

  //     //     // Convert canvas to PNG
  //     //     const pngDataUrl = canvas.toDataURL('image/png');
  //     //     pngImages.push(pngDataUrl);

  //     //     // Optional: if you need to clear or remove the canvas
  //     //     canvas.remove();
  //     // }

  //     // return pngImages;  // Array of PNG data URLs for each page
  // }




  const handleFileUpload2 = async (event) => {

    const file = event.target.files[0]; // Ensure only one file is uploaded
    if (!file) return;
    console.log("file is of type", file.type)
    // Determine ContentType and filename
    const fileType = file.type;
    setFile(file)
    setIsLoading(true);

    if (!isSelectedMath && file.type === 'application/pdf') {
      console.log("Processing non-math file...");
        setShowSummaryChatbot(false);
        setFileUrl(URL.createObjectURL(file));
        setFileName(file.name);
        setUploadedfile(file);
  
        const reader = new FileReader();
        reader.onload = async (e) => {
          const content = e.target.result;
  
          if (file.type === "application/pdf") {
            setIsLoading(true);
            if (ocrProcessing || useOCR.includes(file.name)) {
              console.log("Calling OCR processing...");
              await handleOcrCall(file);
            } else {
              console.log("Extracting text from PDF...");
              const loadingTask = pdfjs.getDocument({ data: content });
              const pdf = await loadingTask.promise;
  
              let pageTexts = "";
              for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                const page = await pdf.getPage(pageNumber);
                const textContent = await page.getTextContent();
                const pageText = textContent.items.map((item) => item.str).join(" ");
                pageTexts += `#-Page: ${pageNumber}-#${pageText}`;
              }
  
              console.log("Extracted PDF text:", pageTexts);
            
              
              setFileContent(pageTexts);
              setWordsCount(pageTexts.trim().split(/\s+/).length);
              console.log("HERE11");
              callOverviewOnce(pageTexts,pageTexts.trim().split(/\s+/).length);
            }
          } else if (
            file.type === "application/msword" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            setIsLoading(true)
            try {
              console.log("Processing Word document...");
            
              // Create FormData for file upload
              const formData = new FormData();
              formData.append("file", file);
              formData.append("email",user.email);

              // Make API call to convert Word to PDF
              const response = await fetch("https://www.pdfing.ai:2000/convert", {
                method: "POST",
                body: formData,
              });
            
              if (response.ok) {
                console.log("Received converted PDF. Extracting text...");
            
                // Convert the response into a Blob
                const blob = await response.blob();
            
                // Use FileReader to convert Blob to ArrayBuffer
                const reader = new FileReader();
                reader.onload = async (e) => {
                  const content = e.target.result; // ArrayBuffer
            
                  try {
                    if (ocrProcessing || useOCR.includes(file.name)) {
                      console.log("Calling OCR processing...");
                      await handleOcrCall(blob);
                    }else{
                      // Load the PDF using pdf.js
                      const loadingTask = pdfjs.getDocument({ data: content });
                      const pdf = await loadingTask.promise;
              
                      let pageTexts = "";
                      for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                        const page = await pdf.getPage(pageNumber);
                        const textContent = await page.getTextContent();
                        const pageText = textContent.items.map((item) => item.str).join(" ");
                        pageTexts += `#-Page: ${pageNumber}-#${pageText}`;
                      }
              
                      console.log("Extracted PDF text:", pageTexts);
              
                      // Update the state with extracted content
                      
                      
                      setFileContent(pageTexts);
                      setWordsCount(pageTexts.trim().split(/\s+/).length);
                      
                      callOverviewOnce(pageTexts,pageTexts.trim().split(/\s+/).length);
                    }
                  } catch (pdfError) {
                    console.error("Error extracting text from PDF:", pdfError);
                    setErrorMessage("Failed to extract text from the PDF. Please try again.");
                  }
                };
            
                reader.onerror = (error) => {
                  console.error("Error reading the PDF Blob:", error);
                  setErrorMessage("Failed to read the converted PDF. Please try again.");
                };
            
                reader.readAsArrayBuffer(blob); // Trigger FileReader to process the Blob
              } else {
                const errorText = await response.text();
                console.error("Word document conversion failed:", response.statusText, errorText);
                setErrorMessage("Failed to convert the Word document. Please try again.");
              }
            } catch (error) {
              console.error("Error during Word document conversion:", error);
              setErrorMessage("An unexpected error occurred. Please try again.");
            }
            
          } else {
            setFileContent("");
            setErrorMessage(
              "Unsupported file format. Please upload a PDF or Document file."
            );
          }          
          
        };
  
        reader.readAsArrayBuffer(file);
        setIsLoading(false);
    }
    if (
      file.type === "application/msword" ||
      file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      && !isSelectedMath
    ) {
      setIsLoading(true)
      try {
        console.log("Processing Word document...");
      
        // Create FormData for file upload
        const formData = new FormData();
        formData.append("file", file);
        formData.append("email",user.email);
      
        // Make API call to convert Word to PDF
        const response = await fetch("https://www.pdfing.ai:2000/convert", {
          method: "POST",
          body: formData,
        });
      
        if (response.ok) {
          console.log("Received converted PDF. Extracting text...");
      
          // Convert the response into a Blob
          const blob = await response.blob();
      
          // Use FileReader to convert Blob to ArrayBuffer
          const reader = new FileReader();
          reader.onload = async (e) => {
            const content = e.target.result; // ArrayBuffer
      
            try {
              if (ocrProcessing || useOCR.includes(file.name)) {
                console.log("Calling OCR processing...");
                await handleOcrCall(blob);
              }else{
                // Load the PDF using pdf.js
                const loadingTask = pdfjs.getDocument({ data: content });
                const pdf = await loadingTask.promise;
        
                let pageTexts = "";
                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                  const page = await pdf.getPage(pageNumber);
                  const textContent = await page.getTextContent();
                  const pageText = textContent.items.map((item) => item.str).join(" ");
                  pageTexts += `#-Page: ${pageNumber}-#${pageText}`;
                }
        
                console.log("Extracted PDF text:", pageTexts);
        
                // Update the state with extracted content
                
                
                setFileContent(pageTexts);
                setWordsCount(pageTexts.trim().split(/\s+/).length);
                
                callOverviewOnce(pageTexts,pageTexts.trim().split(/\s+/).length);
              }
            } catch (pdfError) {
              console.error("Error extracting text from PDF:", pdfError);
              setErrorMessage("Failed to extract text from the PDF. Please try again.");
            }
          };
      
          reader.onerror = (error) => {
            console.error("Error reading the PDF Blob:", error);
            setErrorMessage("Failed to read the converted PDF. Please try again.");
          };
      
          reader.readAsArrayBuffer(blob); // Trigger FileReader to process the Blob
        } else {
          const errorText = await response.text();
          console.error("Word document conversion failed:", response.statusText, errorText);
          setErrorMessage("Failed to convert the Word document. Please try again.");
        }
      } catch (error) {
        console.error("Error during Word document conversion:", error);
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
      
    }
    if (isSelectedMath && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'application/pdf')) {
      try {
        setIsLoading(true);
        setSelectedMath(true);
        console.log("Processing math file...");

        setFileContent("parsedContent");
        setFileName(file.name);
        setFileUrl(URL.createObjectURL(file));
        if (file.type === "application/pdf") {
          if (ocrProcessing || useOCR.includes(file.name)) {
            console.log("Calling OCR processing...");
            await handleOcrCall(file);
          }
          const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise;
          const pages = [];
    
          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
    
            // Create a canvas to render the PDF page
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const viewport = page.getViewport({ scale: 2 }); // Adjust scale for higher quality
    
            canvas.width = viewport.width;
            canvas.height = viewport.height;
    
            await page.render({ canvasContext: context, viewport }).promise;
    
            // Convert canvas content to a JPG data URL
            const jpgData = canvas.toDataURL("image/jpeg", 1.0); // High-quality JPG
            pages.push(jpgData);
          }
    
            // Process each page
            for (let i = 0; i < pages.length; i++) {
              const jpgData = pages[i];

              // Convert data URL to a Blob for upload
              const blob = await (await fetch(jpgData)).blob();
              const fileForUpload = new File([blob], `page-${i + 1}.jpg`, {
                type: "image/jpeg",
              });

              try {
                // Upload the page (JPG file)
                const uploadData = await uploadFileToAPI(fileForUpload);
                console.log(`Uploaded page ${i + 1} response:`, uploadData);
                

                // Call the do_mathematics API
                const formatType = fileForUpload.type.split("/")[1];
                console.log(`page: ${i + 1} mathresponse222`, uploadData.file_url,formatType);
                const mathResponse = await fetch("https://pdfing.ai/api/pdfing/do_mathematics", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    image_url: uploadData.file_url,
                    format_type: formatType,
                  }),
                  mode: 'cors'
                });

                const mathResult = await mathResponse.json();
                console.log(`Math response for page ${i + 1}:`, mathResult);
                const renderedComponent = <MathList Items={mathResult} />;

                setResponse((prevResponse) => (
                  <>
                    {prevResponse}
                    {renderedComponent}
                  </>
                ));
            
                // Append mathResult to fileContent
                setFileContent((prevContent) => prevContent + JSON.stringify(mathResult));

                setShowSummaryChatbot(true);
                setIsLoading(false)
              } catch (error) {
                console.error(`Error processing page ${i + 1}:`, error);
              }
            }
        } else {
          // Upload file to API
          const uploadData = await uploadFileToAPI(file);
          console.log("Uploaded file response:", uploadData);
          const formatType = file.type.split("/")[1];
          const mathResponse = await fetch("https://pdfing.ai/api/pdfing/do_mathematics", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              image_url: uploadData.file_url,
              format_type: formatType,
            }),
          });

          if (!mathResponse.ok) {
            throw new Error(`Math API Error: ${mathResponse.statusText}`);
          }

          const responseData = await mathResponse.json();
          console.log("Mathematics API response:", responseData);

        // Update state with results
        const renderedComponent = <MathList Items={responseData} />;
        setResponse(renderedComponent);
        setFileContent(JSON.stringify(responseData));
        setShowSummaryChatbot(true);
        }
      } catch (error) {
        console.error("Error processing math file:", error);
        if (error.message === "Failed to fetch") {
          alert("Internet connection is lost. Please check your network.");
          navigate("/check-homework");
        } else {
          alert("An unexpected error occurred.");
        }
      } finally {
        setIsLoading(false); // Reset loading state
      }
    }
    else {
      console.log("fileconten44", fileContent)
      const file = event.target.files[0];
      setFileName(file.name);
      setFile(event.target.files[0])

      setFileUrl(URL.createObjectURL(file))
      console.log(event.target.files[0])
      setIsLoading(true)
      setUploadedfile(file);
    }
    if (!file) return;
  };
  async function uploadFileToAPI(file, fileName) {
    const apiEndpoint = "https://pdfing.ai/api/pdfing/upload-img";
    const formData = new FormData();

    // Append the file and filename to the FormData object
    formData.append("file", file);
    formData.append("filename", `${file.name}_image.jpg`);
    formData.append("email", user.email);

    try {
        const response = await fetch(apiEndpoint, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Upload successful. Response:", data);
        return data; // Handle the response data
    } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
    }
}


  useEffect(() => {
    const processFile = async () => {
      if (!file) return;
  
      if (
        hasMath &&
        ["image/jpeg", "image/png", "image/jpg","application/pdf"].includes(file.type)
      ) {
        try {
          setIsLoading(true);
          setSelectedMath(true);
          console.log("Processing math file...");
  
          setFileContent("parsedContent");
          setFileName(file.name);
          setFileUrl(URL.createObjectURL(file));
  
          if (file.type === "application/pdf") {
            const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise;
            const pages = [];
      
            for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
              const page = await pdf.getPage(pageNum);
      
              // Create a canvas to render the PDF page
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              const viewport = page.getViewport({ scale: 2 }); // Adjust scale for higher quality
      
              canvas.width = viewport.width;
              canvas.height = viewport.height;
      
              await page.render({ canvasContext: context, viewport }).promise;
      
              // Convert canvas content to a JPG data URL
              const jpgData = canvas.toDataURL("image/jpeg", 1.0); // High-quality JPG
              pages.push(jpgData);
            }
      
              // Process each page
              for (let i = 0; i < pages.length; i++) {
                const jpgData = pages[i];
  
                // Convert data URL to a Blob for upload
                const blob = await (await fetch(jpgData)).blob();
                const fileForUpload = new File([blob], `page-${i + 1}.jpg`, {
                  type: "image/jpeg",
                });
  
                try {
                  // Upload the page (JPG file)
                  const uploadData = await uploadFileToAPI(fileForUpload);
                  console.log(`Uploaded page ${i + 1} response:`, uploadData);
                  
  
                  // Call the do_mathematics API
                  const formatType = fileForUpload.type.split("/")[1];
                  console.log(`page: ${i + 1} mathresponse222`, uploadData.file_url,formatType);
                  const mathResponse = await fetch("https://pdfing.ai/api/pdfing/do_mathematics", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      image_url: uploadData.file_url,
                      format_type: formatType,
                    }),
                    mode: 'cors'
                  });
  
                  const mathResult = await mathResponse.json();
                  console.log(`Math response for page ${i + 1}:`, mathResult);
                  const renderedComponent = <MathList Items={mathResult} />;
  
                  setResponse((prevResponse) => (
                    <>
                      {prevResponse}
                      {renderedComponent}
                    </>
                  ));
              
                  // Append mathResult to fileContent
                  setFileContent((prevContent) => prevContent + JSON.stringify(mathResult));
  
                  setShowSummaryChatbot(true);
                  setIsLoading(false)
                } catch (error) {
                  console.error(`Error processing page ${i + 1}:`, error);
                }
              }
          } else {
            // Upload file to API
            const uploadData = await uploadFileToAPI(file);
            console.log("Uploaded file response:", uploadData);
            const formatType = file.type.split("/")[1];
            const mathResponse = await fetch("https://pdfing.ai/api/pdfing/do_mathematics", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                image_url: uploadData.file_url,
                format_type: formatType,
              }),
            });
  
            if (!mathResponse.ok) {
              throw new Error(`Math API Error: ${mathResponse.statusText}`);
            }
  
            const responseData = await mathResponse.json();
            console.log("Mathematics API response:", responseData);
  
          // Update state with results
          const renderedComponent = <MathList Items={responseData} />;
          setResponse(renderedComponent);
          setFileContent(JSON.stringify(responseData));
          setShowSummaryChatbot(true);
          }
        } catch (error) {
          console.error("Error processing math file:", error);
          if (error.message === "Failed to fetch") {
            alert("Internet connection is lost. Please check your network.");
            navigate("/check-homework");
          } else {
            alert("An unexpected error occurred.");
          }
        } finally {
          setIsLoading(false); // Reset loading state
        }
      } else if (!hasMath && file && !isSelectedMath) {
        console.log("Processing non-math file...");
        setShowSummaryChatbot(false);
        setFileUrl(URL.createObjectURL(file));
        setFileName(file.name);
        setUploadedfile(file);
  
        const reader = new FileReader();
        reader.onload = async (e) => {
          const content = e.target.result;
  
          if (file.type === "application/pdf") {
            // setIsLoading(true);
            if (ocrProcessing) {
              console.log("Calling OCR processing...");
              await handleOcrCall(file);
            } else {
              console.log("Extracting text from PDF...");
              const loadingTask = pdfjs.getDocument({ data: content });
              const pdf = await loadingTask.promise;
  
              let pageTexts = "";
              for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                const page = await pdf.getPage(pageNumber);
                const textContent = await page.getTextContent();
                const pageText = textContent.items.map((item) => item.str).join(" ");
                pageTexts += `#-Page: ${pageNumber}-#${pageText}`;
              }
  
              console.log("Extracted PDF text:", pageTexts);
              
              setFileContent(pageTexts);
              setWordsCount(pageTexts.trim().split(/\s+/).length);
              
              callOverviewOnce(pageTexts,pageTexts.trim().split(/\s+/).length);
            }
          } else if (
            file.type === "application/msword" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            setIsLoading(true)
            try {
              console.log("Processing Word document...");
            
              // Create FormData for file upload
              const formData = new FormData();
              formData.append("file", file);
              formData.append("email",user.email);
            
              // Make API call to convert Word to PDF
              const response = await fetch("https://www.pdfing.ai:2000/convert", {
                method: "POST",
                body: formData,
              });
            
              if (response.ok) {
                console.log("Received converted PDF. Extracting text...");
            
                // Convert the response into a Blob
                const blob = await response.blob();
            
                // Use FileReader to convert Blob to ArrayBuffer
                const reader = new FileReader();
                reader.onload = async (e) => {
                  const content = e.target.result; // ArrayBuffer
            
                  try {
                    if (ocrProcessing || useOCR.includes(file.name)) {
                      console.log("Calling OCR processing...");
                      await handleOcrCall(blob);
                    }else{
                      // Load the PDF using pdf.js
                      const loadingTask = pdfjs.getDocument({ data: content });
                      const pdf = await loadingTask.promise;
              
                      let pageTexts = "";
                      for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                        const page = await pdf.getPage(pageNumber);
                        const textContent = await page.getTextContent();
                        const pageText = textContent.items.map((item) => item.str).join(" ");
                        pageTexts += `#-Page: ${pageNumber}-#${pageText}`;
                      }
              
                      console.log("Extracted PDF text:", pageTexts);
              
                      // Update the state with extracted content
                      
                      
                      setFileContent(pageTexts);
                      setWordsCount(pageTexts.trim().split(/\s+/).length);
                      
                      callOverviewOnce(pageTexts,pageTexts.trim().split(/\s+/).length);
                    }
                  } catch (pdfError) {
                    console.error("Error extracting text from PDF:", pdfError);
                    setErrorMessage("Failed to extract text from the PDF. Please try again.");
                  }
                };
            
                reader.onerror = (error) => {
                  console.error("Error reading the PDF Blob:", error);
                  setErrorMessage("Failed to read the converted PDF. Please try again.");
                };
            
                reader.readAsArrayBuffer(blob); // Trigger FileReader to process the Blob
              } else {
                const errorText = await response.text();
                console.error("Word document conversion failed:", response.statusText, errorText);
                setErrorMessage("Failed to convert the Word document. Please try again.");
              }
            } catch (error) {
              console.error("Error during Word document conversion:", error);
              setErrorMessage("An unexpected error occurred. Please try again.");
            }
            
          } else {
            setFileContent("");
            setErrorMessage(
              "Unsupported file format. Please upload a PDF or Document file."
            );
          }          
          
        };
  
        reader.readAsArrayBuffer(file);
        setIsLoading(false);
      }
    };
  
    if(!isHomeWork && !isLoading && !userFile){
      processFile();
    }else if (!isHomeWork && hasMath && !isLoading){
      processFile();
    }else if (isHomeWork && hasMath){
      processFile()
    }
    else if(isHomeWork && !hasMath && userFile && file){
      // processFile()
    }
  }, [file, hasMath, isSelectedMath,userFile]);
  

  useEffect(() => {
    console.log("9900",usageData)
    // if(uploadError){
    //   navigate(-1);
    // }
  },[usageData])



  const handleOcrCall = async (uploadedFile) => {
    console.log("CallinfOCR", uploadedFile)
    const apiLink = "https://pdfing.ai/api/usage/ocr";
    const formData = new FormData();
    formData.append('pdfFile', uploadedFile);
    formData.append('email', user.email);
    formData.append('date', new Date().toISOString().split('T')[0],);


    const requestOptions = {
      method: "POST",
      body: formData,
    };



    try {
      setIsLoading(true);
      let ocrResponse = await fetch(apiLink, requestOptions);
      let parsedOcrResponse = await ocrResponse.json();
      if(parsedOcrResponse['status'] == 'error'){
        setPremiumError(
          "Share is Premium feature only available for student and professsional users. "
        );
        setIsPremium(true)
        handleReset()
        setErrorMessage("")
        return
    }
      // setOcrResponse(parsedOcrResponse);
      console.log("ocr response: ", parsedOcrResponse.content)
      setFileContent(parsedOcrResponse.content)
      setWordsCount(parsedOcrResponse.content.trim().split(/\s+/).length)
      console.log("HERE55");
      callOverviewOnce(parsedOcrResponse.content,parsedOcrResponse.content.trim().split(/\s+/).length)


      // setIsLoading(false)
      console.log("ocr response", parsedOcrResponse)

    } catch (error) {
      console.log("error", error)
      setIsLoading(false)
    }
  };




  const handleDownloadPDF = async () => {
    setdropdown(false);

    const input = inputRef;
    console.log(input);
    console.log(input.current);

    // const canvas = await html2canvas(input);
    // const imgData = canvas.toDataURL('image/png');
    // const pdf = new jsPDF();
    // const imgWidth = 210;
    // const imgHeight = (canvas.height * imgWidth) / canvas.width;
    // pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    // pdf.save(`${fileName}_summary_${type1Value}_${type2Value}.pdf`);
  };

  // const handleDownloadWord = async () => {
  //   setdropdown(false);

  //   const input = inputRef.current; // Your HTML content

  //   // Simple text extraction (adjust this as per your needs)
  //   const htmlContent = input.innerHTML; // Extract innerHTML

  //   // Create a new Document
  //   const doc = new Document({
  //     sections: [
  //       {
  //         children: [
  //           new Paragraph({
  //             children: [new TextRun(htmlContent)],
  //           }),
  //         ],
  //       },
  //     ],
  //   });

  //   // Generate the document as a Blob
  //   const blob = await Packer.toBlob(doc);

  //   // Save the file
  //   saveAs(blob, `${fileName}_summary_${type1Value}_${type2Value}.docx`);
  // };


  const wrapHtmlContent = (htmlContent) => {
    return `
        <!DOCTYPE html>
        <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        <head><meta charset='utf-8'><title>Document</title></head><body>${htmlContent}</body></html>
      `;
  };

  const handleDownloadDOC = (response, filename) => {
    setdropdown(false);

    const wrappedHtml = wrapHtmlContent(response);
    const blob = new Blob(['\ufeff', wrappedHtml], {
      type: 'application/msword',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}_summary_${curSummaryname.val1}_${curSummaryname.val2}.doc`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleCopy = (response) => {
    let html = `
      <!DOCTYPE html>
      <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
      <head><meta charset='utf-8'><title>Document</title></head><body>${response}</body></html>
    `

    const container = document.createElement('div');
    document.body.appendChild(container);
    container.innerHTML = html;

    const textarea = document.createElement('textarea');
    textarea.value = container.innerText;
    document.body.appendChild(textarea);
    textarea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {

    }
    document.body.removeChild(textarea);
    document.body.removeChild(container);
  }

  // useEffect(() => {
  //   // Add the AdSense script to the document
  //   if (!document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]')) {
  //     const script = document.createElement('script');
  //     script.async = true;
  //     script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  //     script.crossOrigin = 'anonymous';
  //     script.onload = () => {
  //       (window.adsbygoogle = window.adsbygoogle || []).push({});
  //     };
  //     console.log("addsense-script",script)
  //     document.head.appendChild(script);
  //   } else {
  //     // If the script is already present, initialize the ad slot
  //     (window.adsbygoogle = window.adsbygoogle || []).push({});
  //   }}, []);


  const handlePageClick = (pageNum) => {
    // Create the URL for opening the PDF at the specific page
    const pdfPageUrl = `${fileUrl}#page=${pageNum}`;
    // Open the PDF in a new tab
    window.open(pdfPageUrl, '_blank');
  };


  const renderHtmlContent = (htmlContent) => {
    console.log("regexing:", htmlContent, "Type:", typeof htmlContent);

    // Ensure htmlContent is a string
    if (typeof htmlContent !== "string") {
      console.error("htmlContent is not a string:", htmlContent);
      return ""; // Return an empty string or a fallback value
    }
    console.log("regexing :", htmlContent)
    if (isSelectedMath) {
      return htmlContent;
    }
    // console.log(htmlContent.replace(/<p>(\d+)(?:[,-].*)?<\/p>/g, (match, firstNum,fullContent)=>{return `${match},${firstNum},${fullContent}`}))
    // let answer =  htmlContent.replace(/<p>(\d+)(?:[,-].*)?<\/p>/g, (match, firstNum, fullContent) => {      // Replace <p> tags with clickable spans that open the PDF
    //   return `<span class="page-link" style="display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // color: #0066cc;
    // cursor: pointer; " onClick="window.handlePageClick(${firstNum})">Page &nbsp ${match}</span>`;
    // });
    let answer = htmlContent.replace(/<p>(\d+)(.*?<\/p>)/g, (match, firstNum, restContent) => {
      return `<span class="page-link" style="display: flex; flex-direction: row; flex-wrap: wrap; color: #0066cc; cursor: pointer;" onclick="window.handlePageClick(${firstNum})">Page &nbsp ${match}</span>`;
    });
    console.log(answer,"3er")
    return answer
  };

  const [supported_types, setSupported_types] = useState([{ name: "DOCX", color: "#B3ECEF" }, { name: "DOC", color: "#A2D9CE" }, { name: "PDF", color: "#D7BDE2" }])

  useEffect(() => {
    if (isSelectedMath === true) {
      setSupported_types([{ name: "JPG", color: "#B3ECEF" }, { name: "PNG", color: "#A2D9CE" }, { name: "PDF", color: "#D7BDE2" }])
    }
    else {
      setSupported_types([{ name: "DOCX", color: "#B3ECEF" }, { name: "DOC", color: "#A2D9CE" }, { name: "PDF", color: "#D7BDE2" }])
    }
  }, [isSelectedMath])


  // Attach the click handler globally
  window.handlePageClick = handlePageClick;

  console.log("window.adsbygoogle", window.adsbygoogle)
  return (
    <div
      style={{
        width: "100%",
        // display:"flex",
        // flexDirection:"column",
        // alignItems:"center",
        // justifyContent:"center"
        // textAlign: !fileContent && "center",
        // maxWidth: "90%",
        // backgroundColor: !fileContent && "#EAEDED",
        // height: "90%",
        // borderRadius: "20px",
        // marginTop: "10px",
        // marginLeft: "30px",
      }}
    >
      {/* <div style={{height:"100%",backgroundColor:"pink"}}>


      <ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-5467337913252726"
         data-ad-slot="8101520442"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>

          </div> */}
          {!fileContent && !isLoading && isHomeWork ? 
          (
            <>
            
            <div className={styles.mathUploadContainer}>
            
            
            {hasMath ? 
            
              <div className={styles2.uploadbox}>
                <div className={styles2.boxContent}>
                  <div>
                  <div className={styles2.sourceText2}>SOURCE FILE</div>
                  <span>The document which contains the material which the </span>
                  <span>assignment is based upon</span>
                    <label
                      htmlFor="file-upload-1" // Unique id for the first file input
                      className={styles2.fileLabel}
                      
                    >
                      <div className={styles2.uploadIcon}></div>
                      <span className={styles2.textFile3}>SELECT FILE</span>
                      <span>Add PDFS, WORD files</span>
      
                      <input
                          type="file"
                          accept=".jpg, .jpeg, .png, .pdf,.doc,.docx"
                          onChange={(e) => {
                            const uploadedFile = e.target.files[0];
                            setFile(uploadedFile); // Update the state for the first file
                            console.log("File uploaded:", uploadedFile);
                            setFileName(uploadedFile.name)
                          }}
                          className={styles2.fileInput}
                          id="file-upload-1" // Unique id for the input
                        />
      
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#555",
                          display: "flex",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        Supported format:{" "}
                        <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }}>
                          PDF
                        </div>
                        <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }}>
                          Docx
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                
                {/* {!file ?
                 <div className={styles2.boxContent}>
                  <div>
                  <div className={styles2.sourceText2}>SOURCE FILE</div>
                    <label
                      htmlFor="file-upload-1" // Unique id for the first file input
                      className={styles2.fileLabel}
                      
                    >
                      <div className={styles2.uploadIcon}></div>
                      <span className={styles2.textFile3}>SELECT FILE</span>
                      <span>Add PDFS, WORD files</span>
      
                      <input
                          type="file"
                          accept=".jpg, .jpeg, .png, .pdf"
                          onChange={(e) => {
                            const uploadedFile = e.target.files[0];
                            setFile(uploadedFile); // Update the state for the first file
                            console.log("File uploaded:", uploadedFile);
                            setFileName(uploadedFile.name)
                          }}
                          className={styles2.fileInput}
                          id="file-upload-1" // Unique id for the input
                        />
      
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#555",
                          display: "flex",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        Supported format:{" "}
                        <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }}>
                          PDF
                        </div>
                        <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }}>
                          Docx
                        </div>
                      </div>
                    </label>
                  </div>
                </div>:<><div className={styles2.sourceContainer}>
                <div className={styles2.sourceText}>SOURCE FILE</div>
      
                  <div><img className={styles2.sourceImg} src={sourceImg}></img></div>
                  <label>
                    <input
                        type="checkbox"
                        checked={false}
                        onChange={() => toggleOCR(fileName)}
                    />
                    Use OCR
                    </label>
                  <p>{fileName.length > 10
                      ? `${fileName.slice(0, 10)}...`
                      : fileName}</p>
                  </div>
                  
                    </>
                    } */}
              </div>
                : 
                <div className={styles2.contaoner}>
                  <div className={styles2.uploadbox}>
                  
                    
                    {!file ?
                     <div className={styles2.boxContent}>
                      
                      <div>
                  <div className={styles2.sourceText2}>SOURCE FILE</div>
                  <div className={styles2.centeredText}>
                    <div>The document which contains the material which the </div>
                    <div>assignment is based upon</div>
                  </div>
                        <label
                          htmlFor="file-upload-1" // Unique id for the first file input
                          className={styles2.fileLabel}
                        >
                          <div className={styles2.uploadIcon}></div>
                          <span className={styles2.textFile}>SELECT FILE</span>
                          <span>Add PDFS, WORD files</span>
      
                          <input
                              type="file"
                              accept=".jpg, .jpeg, .png, .pdf,.doc,.docx"
                              onChange={(e) => {
                                const uploadedFile = e.target.files[0];
                                setFile(uploadedFile);
                                setSourceFileName(uploadedFile.name)
                              }}
                              className={styles2.fileInput}
                              id="file-upload-1" // Unique id
                            />
      
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#555",
                              display: "flex",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            Supported format:{" "}
                            <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }}>
                              PDF
                            </div>
                            <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }}>
                              Docx
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>:
                    <div className={styles2.sourceContainer}>
                    <div className={styles2.sourceText}>SOURCE FILE</div>
                    <div className={styles2.sourceImgcont}><img className={styles2.sourceImg} src={sourceImg} alt="Source" /></div>
                    <div className={styles2.fileCon}>
                    <label>
                      <input
                        type="checkbox"
                        checked={useOCR.includes(sourceFileName) || false}
                        onChange={() => toggleOCR(sourceFileName)}
                      />
                      Use OCR
                    </label>
                    <p className={styles2.wrapText}>
                      {sourceFileName}
                    </p>
                      </div>
                  </div>
                    }
                  </div>
                  <div className={styles2.uploadbox}>
                  {/* <div className={styles2.boxContent}>
                      <div>
                      <div className={styles2.sourceText3}>ASSIGNMENT FILE</div>
                        <label
                          htmlFor="file-upload-2" // Unique id for the second file input
                          className={styles2.fileLabel}
                        >
                          <div className={styles2.uploadIcon}></div>
                          <span className={styles2.textFile}>SELECT FILE</span>
                          <span>Add PDFS, WORD files</span>
      
                          <input
                              type="file"
                              accept=".jpg, .jpeg, .png, .pdf"
                              onChange={(e) => {
                                const uploadedFile = e.target.files[0];
                                setAssigFileName(uploadedFile.name)
                                setUserFile(uploadedFile); // Update the state for the second file
                                console.log("Second file uploaded:", uploadedFile);
                              }}
                              className={styles2.fileInput}
                              id="file-upload-2" // Unique id
                            />
      
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#555",
                              display: "flex",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            Supported format:{" "}
                            <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }}>
                              PDF
                            </div>
                            <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }}>
                              Docx
                            </div>
                          </div>
                        </label>
                      </div>
                    </div> */}
                    {!userFile ? 
                    <div className={styles2.boxContent}>
                      <div>
                      <div className={styles2.sourceText3}>ASSIGNMENT FILE</div>
                      <div className={styles2.centeredText}>
                        <div>The file with questions which must be answered</div>
                      </div>
                        <label
                          htmlFor="file-upload-2" // Unique id for the second file input
                          className={styles2.fileLabel}
                        >
                          <div className={styles2.uploadIcon}></div>
                          <span className={styles2.textFile2}>SELECT FILE</span>
                          <span>Add PDFS, WORD files</span>
      
                          <input
                              type="file"
                              accept=".jpg, .jpeg, .png, .pdf, .doc,.docx"
                              onChange={(e) => {
                                
                                const uploadedFile = e.target.files[0];
                                setAssigFileName(uploadedFile.name)
                                setUserFile(uploadedFile); // Update the state for the second file
                                console.log("Second file uploaded:", uploadedFile);
                              }}
                              className={styles2.fileInput}
                              id="file-upload-2" // Unique id
                            />
      
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#555",
                              display: "flex",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            Supported format:{" "}
                            <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }}>
                              PDF
                            </div>
                            <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }}>
                              Docx
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>:
                    <div className={styles2.sourceContainer}>
                    <div className={styles2.sourceText}>ASSIGNMENT FILE</div>
                    <div className={styles2.sourceImgcont}><img className={styles2.sourceImg} src={sourceImg}></img></div>
                    <div className={styles2.fileCon}>
                    <label>
                    <input
                        type="checkbox"
                        // checked={false}
                        checked={useOCR.includes(assigFileName)|| false}
                        onChange={() => toggleOCR(assigFileName)}
                    />
                    Use OCR
                    </label>
                    
                    <p className={styles2.wrapText}>{assigFileName}</p>
                      </div>
                    </div>
                    }
                  </div>
                </div>
                
                
              }
              {/* <div className={styles2.questionCard}>
                <p className={styles2.question}>My assignment has Mathematics</p>
                <div className={styles2.radioGroup}>
                  <label className={styles2.radioLabel}>
                    <input
                      type="radio"
                      name="hasMath"
                      value="yes"
                      defaultChecked={hasMath}
                      onChange={handleRadioChange}
                      className={styles2.radioInput}
                    />
                    <span className={styles2.radioText}>Yes</span>
                  </label>
                  <label className={styles2.radioLabel}>
                    <input
                      type="radio"
                      name="hasMath"
                      value="no"
                      defaultChecked={!hasMath}
                      onChange={handleRadioChange}
                      className={styles2.radioInput}
                    />
                    <span className={styles2.radioText}>No</span>
                  </label>
                </div>
              </div> */}
      
      
              
      
              {/* <button onClick={handleFileUpload2} className={styles2.submitButton}>
                SUBMIT
                <span className={styles2.arrow}>›</span>
              </button> */}
              </div>
              {uploadError !== "" && (
                <div
                  className={styles.wordCount}
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "18px",
                  }}
                >
                  {uploadError}
                </div>
              )}
              
              </>
            )
            :<div>
      {!fileContent && !isLoading && (
        <>
          {!file && (
            <div className={styles.boxContent}>
              {/* <div className={styles.tryButton}>
                    <a>Try for Free</a>
                    <input
                                    type="file"
                                    accept=".pdf,.doc,.docx"
                                    onChange={handleFileUpload2}
                                    className={styles.fileInput}
                                    id="file-upload"
                                />
                    </div>      */}
              <div>
                <label htmlFor="file-upload" className={styles.fileLabel}>
                  <div className={styles.uploadIcon}></div>
                  <span className={styles.textFile}>SELECT FILE</span>
                  <span>Add {supported_types.map((item) => {
                    return (
                      <>{item.name} </>
                    )
                  })} files</span>
                  <input
                    type="file"
                    accept={isSelectedMath ? '.jpg, .jpeg, .png, .pdf, .doc,.docx' : '.jpg, .jpeg, .png, .pdf, .doc,.docx'}
                    onChange={handleFileUpload2}
                    className={styles.fileInput}
                    id="file-upload"
                  />
                  <div style={{ fontSize: "12px", color: "#555", display: "flex", justifyContent: "center", gap: "5px" }} >
                    Supported format:
                    <>{supported_types.map((item) => {
                      return (
                        <div style={{ ...smallTabStyle, backgroundColor: item.color }} key={item.name} >{item.name}</div>
                      )
                    })}</>

                    {/* <div style={{ ...smallTabStyle, backgroundColor: "#D7BDE2" }} >PDF</div>
                    <div style={{ ...smallTabStyle, backgroundColor: "#A2D9CE" }} >Docx</div> */}
                  </div>
                </label>

              </div>
            </div>
          )}
          {/* <input
            type="file"
            id="fileInput"
            accept=".pdf, .doc, .docx"
            style={{ display: "none" }}
            onChange={handleFileUpload2}
          /> */}
        </>

      )}
      {file && !isLoading && !response && (
        <div
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            borderRadius: "5px",
            backgroundColor: "#e0e0e0",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <strong>Selected File:</strong> {file.name}
        </div>
      )}

      {(response && isSelectedMath === true) && (
        // <div
        //   style={{
        //     marginTop: "20px",
        //     padding: "10px",
        //     border: "1px solid #ddd",
        //     borderRadius: "5px",
        //     backgroundColor: "#fff",
        //     overflow: "auto",
        //     maxHeight: "100%",
        //   }}
        // >
        //   {response}
        // </div>

        <div

          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            backgroundColor: "#fff",
            overflowY: "scroll",
            maxHeight: "55vh",
          }}
          ref={inputRef}

        >
          {response}
        </div>
      )}


      {(response && isSelectedMath === false) && (
        // <div
        //   style={{
        //     marginTop: "20px",
        //     padding: "10px",
        //     border: "1px solid #ddd",
        //     borderRadius: "5px",
        //     backgroundColor: "#fff",
        //     overflow: "auto",
        //     maxHeight: "100%",
        //   }}
        // >
        //   {response}
        // </div>

        <div

          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid #ddd",
            borderRadius: "5px",
            backgroundColor: "#fff",
            overflowY: "scroll",
            maxHeight: "55vh",
          }}
          ref={inputRef}

          dangerouslySetInnerHTML={{
            __html: renderHtmlContent(response) || "",

          }}
        // dangerouslySetInnerHTML={{ __html: response }}
        />
      )}

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "55vh",
          }}
        >
          <img src={GearGif} alt="Loading..." style={{ width: "160px", height: "160px" }} />
          <div>Processing File...</div>
        </div>
      ) : (
        <div className={styles.Errorandword} style={{ padding: "20px", overflowY: "auto" }}>
          {response && (
            <>
              {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "2em",
                  }}
                > */}
              {/* <div className={styles.wordCount}> */}
              <div className={styles.wordCount} style={{ color: usageData?.plan_name !== 'B' ? (usageData?.dailywordlimit && Math.floor(wordsCount / usageData?.dailywordlimit) > 1 ? "red" : "") : (usageData?.monthlywordlimit && Math.floor(wordsCount / usageData?.monthlywordlimit) > 1 ? "red" : "") }}>
                Word Count: {wordsCount}/{usageData?.plan_name !== 'B' ? (usageData?.dailywordlimit - usageData?.dailywordsused) : (usageData?.monthlywordlimit - usageData?.monthlywordused)}
              </div>
              
              
              {/* <div></div> */}
              {/* </div> */}
              <Box

                className={styles.Box}
                sx={{
                  position: "relative",
                  // bottom: 5,
                  // left: { xs: "5%", md: "70%" },
                  display: "flex",
                  gap: "5px",
                  // width: { xs: "90%", md: "20%" },
                  borderRadius: "12px",
                  backgroundColor: "#B2BABB",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <ShareLinkComponent
                 response={response}
                 setPremiumError={setPremiumError}
                 setIsPremium={setIsPremium}
                 isSelected={isSelected}
                 type2Value={type2Value}
                 type1Value={type1Value}
                 shareableLink={shareableLink}
                  setShareableLink={setShareableLink}
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                   fileName={fileName} file={file} />

                <Tooltip title="Copy to Clipboard">
                  <IconButton onClick={() => handleCopy(response)}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Download">
                  <IconButton onClick={() => handleDownloadDOC(response)}>
                    <DownloadOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {/* <Menu
                  anchorEl={anchorEl}
                  open={dropdown}
                  onClose={handleCloseMenu}

                >
                  <MenuItem onClick={handleDownloadPDF}>Download .pdf</MenuItem>
                  <MenuItem onClick={() => handleDownloadDOC(response )}>
                    Download .doc
                  </MenuItem>
                </Menu> */}
              </Box>
            </>
          )}
          {premiumError && wordsCount >0 &&<div className={styles.wordCount} style={{ color: usageData?.plan_name !== 'B' ? (usageData?.dailywordlimit && Math.floor(wordsCount / usageData?.dailywordlimit) > 1 ? "red" : "") : (usageData?.monthlywordlimit && Math.floor(wordsCount / usageData?.monthlywordlimit) > 1 ? "red" : "") }}>
                Word Count: {wordsCount}/{usageData?.plan_name !== 'B' ? (usageData?.dailywordlimit - usageData?.dailywordsused) : (usageData?.monthlywordlimit - usageData?.monthlywordused)}
              </div>}
          {uploadError !== "" && (
                <div
                  className={styles.wordCount}
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "18px",
                  }}
                >
                  {uploadError}
                </div>
              )}
        </div>
      )}

      {errorMessage && (
        <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
      )}
      
      </div>}
    </div>
  );
}

export default FileUploadComponent;
