// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.masterList_container__9U-sk {
  min-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 4rem;
  top:10%;
  position: absolute;
  z-index: 3;
  
}
.masterList_uploadBox__j-8Ar{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:2em;
}

.masterList_card__W2DIe {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 70vh;
  overflow-y: auto;  /* Enable vertical scrolling */
  overflow-x: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.masterList_cardHeader__mjEx- {
  padding: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.masterList_cardTitle__54FMb {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.masterList_cardContent__bBSlM {
  padding: 1.5rem;
}



.masterList_button__n50oD {
  padding: 0.5rem 3rem;
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 22px;
}

.masterList_button__n50oD:hover {
  background-color: #40a9ff;
}

`, "",{"version":3,"sources":["webpack://./src/Page/MasterListPage/masterList.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,OAAO;EACP,kBAAkB;EAClB,UAAU;;AAEZ;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,OAAO;AACT;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC,YAAY;EACZ,gBAAgB,GAAG,8BAA8B;EACjD,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,gCAAgC;EAChC,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;;;AAIA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,cAAc;EACd,YAAY;EAEZ,eAAe;EACf,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\n  min-width: 800px;\n  width: 100%;\n  margin: 0 auto;\n  padding: 2rem 4rem;\n  top:10%;\n  position: absolute;\n  z-index: 3;\n  \n}\n.uploadBox{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap:2em;\n}\n\n.card {\n  background-color: #ffffff;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  height: 70vh;\n  overflow-y: auto;  /* Enable vertical scrolling */\n  overflow-x: hidden;\n  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);\n}\n\n.cardHeader {\n  padding: 1.5rem;\n  border-bottom: 1px solid #e0e0e0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.cardTitle {\n  font-size: 1.5rem;\n  font-weight: bold;\n  margin: 0;\n}\n\n.cardContent {\n  padding: 1.5rem;\n}\n\n\n\n.button {\n  padding: 0.5rem 3rem;\n  background-color: #1890ff;\n  color: #ffffff;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  font-size: 1rem;\n  border-radius: 22px;\n}\n\n.button:hover {\n  background-color: #40a9ff;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `masterList_container__9U-sk`,
	"uploadBox": `masterList_uploadBox__j-8Ar`,
	"card": `masterList_card__W2DIe`,
	"cardHeader": `masterList_cardHeader__mjEx-`,
	"cardTitle": `masterList_cardTitle__54FMb`,
	"cardContent": `masterList_cardContent__bBSlM`,
	"button": `masterList_button__n50oD`
};
export default ___CSS_LOADER_EXPORT___;
