// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopicList_topicList__KwatP {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.TopicList_topicCard__ESAWf {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.TopicList_topicHeader__X0Snp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
}

.TopicList_topicName__YmHSe {
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
}

.TopicList_topicActions__amfL8 {
  display: flex;
  gap: 0.5rem;
}

.TopicList_actionButton__ZenlO {
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
}

.TopicList_actionButton__ZenlO:hover {
  background-color: #f0f0f0;
}

.TopicList_topicContent__ZVih4 {
  padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/Page/MasterListPage/components/TopicList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".topicList {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.topicCard {\n  border: 1px solid #e0e0e0;\n  border-radius: 4px;\n  overflow: hidden;\n}\n\n.topicHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1rem;\n  background-color: #f5f5f5;\n}\n\n.topicName {\n  font-size: 1.1rem;\n  font-weight: 500;\n  cursor: pointer;\n}\n\n.topicActions {\n  display: flex;\n  gap: 0.5rem;\n}\n\n.actionButton {\n  padding: 0.25rem 0.5rem;\n  background-color: #ffffff;\n  border: 1px solid #d1d1d1;\n  border-radius: 4px;\n  cursor: pointer;\n  font-size: 0.875rem;\n}\n\n.actionButton:hover {\n  background-color: #f0f0f0;\n}\n\n.topicContent {\n  padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topicList": `TopicList_topicList__KwatP`,
	"topicCard": `TopicList_topicCard__ESAWf`,
	"topicHeader": `TopicList_topicHeader__X0Snp`,
	"topicName": `TopicList_topicName__YmHSe`,
	"topicActions": `TopicList_topicActions__amfL8`,
	"actionButton": `TopicList_actionButton__ZenlO`,
	"topicContent": `TopicList_topicContent__ZVih4`
};
export default ___CSS_LOADER_EXPORT___;
