// import React, { useEffect, useState } from 'react';
// import Chatbot from './Components/Products/Summeriser/Chatbot';
// import AdvancedSummary from './Components/Products/Summeriser/AdvancedSummary';
// import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// import Home from './Components/Home/Home';
// import Header from './Components/Products/Summeriser/Header';
// import Navbar from './Components/Navbar/Navbar';
// import Footer from './Components/Footer/Footer';

// import styles from './App.module.css';
// import { jwtDecode } from "jwt-decode";
// import Login from './Components/LoginPage/Login';
// import TermService from './Components/TermServices/TermService';
// import BlogsPage from './Components/Blogs/BlogsPage';
// import ContactPage from './Components/ContactPage/ContactPage';
// import PrivacyPolicyPage from './Components/TermServices/PrivacyPolicyPage';
// import AllPageee from './Components/SingleBlog/HomePage/Allpagee';
// import ReportBug from './Components/ReportBug/ReportBug';

// function App() {
//   const [password, setPassword] = useState('');
//   const [pdfUrls, setPdfUrls] = useState([]);
//   const [chat, setChat] = useState("");
//   const [fileContent, setFileContent] = useState("")
//   const [fileName,setFileName]= useState("");
//   const [showSummaryChatbot, setShowSummaryChatbot]=useState(false);
//   const [chatHistory, setChatHistory] = useState([{
//     content:"",
//     role:""
//   }]);

//   const [file, setFile] = useState(null);
//   const [isAuthenticated, setIsAuthenticated] = useState(true);

//   const [urlStats, setUrlStats] = useState("")
//   const [response, setResponse] = useState();
//   const [isLoading, setIsLoading] = useState(false);
//   const [ocrProcessing, setOcrProcessing] = useState(false);
//   const [isSelected, setIsSelected] = useState(false);

//   const [uploadError, setuploadError] = useState("");

//   const [wordsCount, setWordsCount] = useState(0);

//   useEffect(() => {

//     // const token = localStorage.getItem('authToken');
//     // if (token) {
//     //   try {
//     //     // const decoded = jwtDecode(token);
//     //     setIsAuthenticated(true);
//     //   } catch (error) {
//     //     console.error('Token decoding failed:', error);
//     //   }
//     // }
//     // const storedId = localStorage.getItem('userID');
//     // const encryptedPassword = localStorage.getItem('userPassword');
//   }, []);

//   const handleReset = () => {
//     setWordsCount(0);
//     setuploadError("");
//     setResponse("");
//     setFileContent("");
//     setIsLoading(false);
//     setIsSelected(null);
//     setOcrProcessing(false);
//     setFileName("");
//     setShowSummaryChatbot(false);
//     setFile(null);
//     document.body.classList.remove('show-chatbot');
//     setChatHistory([{
//       content:"",
//       role:""
//     }]);
//   }

//   // const handleLogin = () => {
//   //   // Check if the entered password is correct
//   //   if (password === 'milestone-2') {
//   //     setAuthenticated(true);
//   //   } else {
//   //     alert('Incorrect password. Please try again.');
//   //   }
//   // };

//   // const handleLogout = () => {
//   //   setAuthenticated(false);
//   //   setPassword('');
//   // };

//   return (
//     <Router>
//       <div className={styles.container}>
//         <div>
//           <Routes>
//             <Route
//               path="/login"
//               element={
//                 !isAuthenticated ? (
//                   <Login
//                     isAuthenticated={isAuthenticated}
//                     setIsAuthenticated={setIsAuthenticated}
//                   />
//                 ) : (
//                   <Navigate to="/" />
//                 )
//               }
//             />
//             <Route
//               path="/"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <Home handleReset ={handleReset} urlStats={urlStats} setUrlStats={setUrlStats} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} file={file} setFile={setFile} />
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/terms-of-sevices"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <TermService isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/blogs"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <BlogsPage urlStats={urlStats} setUrlStats={setUrlStats} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/contactus"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <ContactPage/>
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/reportbug"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <ReportBug/>
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/blog/:id"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <AllPageee isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} file={file} setFile={setFile} />
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="privacypolicy"
//               element={
//                 isAuthenticated ? (
//                   <div className={styles.content}>
//                     <PrivacyPolicyPage/>
//                   </div>
//                 ) : (
//                   <Navigate to="/login" />
//                 )
//               }
//             />
//             <Route
//               path="/summariser"
//               element={
//                 !isAuthenticated ? (
//                   <Navigate to="/login" />
//                 ) : (
//                   <AdvancedSummary
//                   showSummaryChatbot={showSummaryChatbot}
//                   setPdfUrls={setPdfUrls}
//                   setuploadError={setuploadError}
//                   uploadError={uploadError}
//                   setWordsCount={setWordsCount}
//                   wordsCount={wordsCount}
//                   chat={chat}
//                   setChat={setChat}
//                     chatHistory={chatHistory}
//                     setIsLoading={setIsLoading}
//                     isLoading={isLoading}
//                     ocrProcessing={ocrProcessing}
//                     setOcrProcessing={setOcrProcessing}
//                     response={response}
//                     setResponse={setResponse}
//                     isSelected={isSelected}
//                     setIsSelected={setIsSelected}
//                     urlStats={urlStats}
//                     handleReset={handleReset}
//                     setUrlStats={setUrlStats}
//                     isAuthenticated={isAuthenticated}
//                     setIsAuthenticated={setIsAuthenticated}
//                     setChatHistory={setChatHistory}
//                     file={file}
//                     setFile={setFile}
//                     fileName={fileName}
//                     setFileName={setFileName}
//                     fileContent={fileContent}
//                     setFileContent={setFileContent}
//                     setShowSummaryChatbot={setShowSummaryChatbot}
//                     caseName={chat}
//                   />

//                 )
//               }
//             />
//           </Routes>
//         </div>
//         <div>
//           <Footer />
//         </div>
//       </div>
//     </Router>
//   );
// }

// export default App;

import React, { useEffect, useState,useRef } from "react";
import Chatbot from "./Components/Products/Summeriser/Chatbot";
import AdvancedSummary from "./Components/Products/Summeriser/AdvancedSummary";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import Home from "./Components/Home/Home";
import Header from "./Components/Products/Summeriser/Header";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import { CSSTransition } from 'react-transition-group';

import styles from "./App.module.css";
import { jwtDecode } from "jwt-decode";
import Login from "./Components/LoginPage/Login";
import TermService from "./Components/TermServices/TermService";
import BlogsPage from "./Components/Blogs/BlogsPage";
import ContactPage from "./Components/ContactPage/ContactPage";
import PrivacyPolicyPage from "./Components/TermServices/PrivacyPolicyPage";
import AllPageee from "./Components/SingleBlog/HomePage/Allpagee";
import ReportBug from "./Components/ReportBug/ReportBug";
import { useAuth0 } from "@auth0/auth0-react";
import PageNotFound from "./Components/PageNotFound/PageNotFound"
import PopModal from "./Components/PopModal/PopModal";
import Comparison from "./Components/Comparision/Comparison";
import FileUpload from "./Components/ModalFileUpload/FileUpload";
import HomeworkPage from "./Components/HomeworkPage/HomeworkPage";
import PaymentForm from "./Components/PaymentForm/PaymentForm";
import MasterListPage from "./Page/MasterListPage/MasterListPage";

const PrivateRoutes = () => {
  const { isAuthenticated, loginWithPopup,loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithPopup();
    }
  }, [isAuthenticated, loginWithPopup]);

  return isAuthenticated ? <Outlet /> : null;
};

function App() {
  const [userEmail, setUserEmail] = useState('');
  const [topicFromList, setTopicFromList] = useState([]);
  const [selectedTopicInMasterList, SetSelectedTopicInMasterList] = useState("")
  const [password, setPassword] = useState("");
  const [pdfUrls, setPdfUrls] = useState([]);
  const [chat, setChat] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [fileName, setFileName] = useState("");
  const [showSummaryChatbot, setShowSummaryChatbot] = useState(false);
  const [chatHistory, setChatHistory] = useState([
    {
      content: "",
      role: "",
    },
  ]);
  const [premiumError, setPremiumError] = useState('')

  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [simpleAuth, setSimpleAuth] = useState(false);

  const [userFile, setUserFile] = useState(null);

  const [sourceFileName, setSourceFileName] = useState('')
  const [assigFileName, setAssigFileName] = useState('')
  const [fileName2, setFileName2] = useState('')

  const [urlStats, setUrlStats] = useState("");
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [ocrProcessing, setOcrProcessing] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const [uploadError, setuploadError] = useState("");
  const [selectedModel, setSelectedModel] = useState("good");

  const [wordsCount, setWordsCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [showMasterListModal, setShowMasterListModal] = useState(false);

  const [fileBlobUrls, setFileBlobUrls] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [useOCR, setUseOCR] = useState([]);
  const [files, setFiles] = useState([]);
  const [uploadedFile, setUploadedfile] = useState();

  const [hasMath, setHasMath] = useState(false);

  const [isHomeWork, setIsHomeWork] = useState(false)
  const { user } = useAuth0();
  const [usageData, setUsageData] = useState({});
  const [planName, setPlanName] = useState("");
  const [isPremium, setIsPremium] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [isLoading3, setIsLoading3] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const existingData = localStorage.getItem('fileTexts');
    const existingTopic = localStorage.getItem('topics');

    if (existingData) {
        console.log("Existing fileTexts data:", JSON.parse(existingData));
        // Completely remove the fileTexts data
        localStorage.removeItem('fileTexts');
        localStorage.removeItem('topics');
        console.log("fileTexts data has been deleted from localStorage.");
    } else {
        console.log("No fileTexts data found in localStorage.");
    }
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      setShowPopup(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);
  const handleClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (showSummaryChatbot === true) {
      // Add the class if it's not already present
      if (!document.body.classList.contains('show-chatbot')) {
        document.body.classList.add('show-chatbot');
      }
    } else {
      // Remove the class if it's present
      if (document.body.classList.contains('show-chatbot')) {
        document.body.classList.remove('show-chatbot');
      }
    }
  }, [showSummaryChatbot])

  const handleReset = () => {
    if (fileUrl) {
      URL.revokeObjectURL(fileUrl);  // Cleanup the URL to prevent memory leaks
    }
    setFileUrl(null);
    setWordsCount(0);
    setuploadError("");
    setResponse("");
    setFileContent("");
    setIsLoading(false);
    setIsSelected(null);
    setOcrProcessing(false);
    setFileName("");
    setShowSummaryChatbot(false);
    setFile(null);
    setUserFile(null);
    setAssigFileName('')
    setFileName2('')
    setSourceFileName('')
    setUseOCR([])
    document.body.classList.remove("show-chatbot");
    setChatHistory([
      {
        content: "",
        role: "",
      },
    ]);
  };
  const callOverviewOnce = async (content,wordCount) => {
    // const apiLink = "https://pdfing.ai:2000/redirect_func";
    const apiLink = "https://pdfing.ai/api/usage/redirect_func";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      
      body: JSON.stringify({
        content: content,
        category: "overview",
        length: "",
        model: selectedModel,
        email:user.email,
        date:new Date().toISOString().split('T')[0],
        api_name:"parse",
        word_count: wordCount,
      }),
    };

    try {
      setIsLoading(true)
      const response = await fetch(apiLink, requestOptions);
      const parsedResponse = await response.json();
      setResponse(parsedResponse.answer);
      // setuploadError("");
      if(parsedResponse['status'] == 'error'){
        setIsLoading(false)
        if (fileUrl) {
          URL.revokeObjectURL(fileUrl);  // Cleanup the URL to prevent memory leaks
        }
        setFileUrl(null);
        setuploadError("");
        setResponse("");
        setFileContent("");
        setIsLoading(false);
        setIsSelected(null);
        setOcrProcessing(false);
        setFileName("");
        setShowSummaryChatbot(false);
        setFile(null);
        setUserFile(null);
        setAssigFileName('')
        setFileName2('')
        setSourceFileName('')
        setUseOCR([])
        document.body.classList.remove("show-chatbot");
        setChatHistory([
          {
            content: "",
            role: "",
          },
        ]);
          setPremiumError(
            "Your word limit has exceeded.Please upgrade the plan to continue."
          );
          const timeoutId = setTimeout(() => {
            console.log("gg4")
            setIsPremium(true);
            setWordsCount(0);
          }, 5000);
    
          // Clean up the timeout on component unmount or when wordsCount changes
          return () => clearTimeout(timeoutId);
      }else{
        if (parsedResponse.answer.includes("try again")) {
          console.log("iii2");
          setIsLoading(false)
          setResponse("");
          setFileContent("");
          setFileName("");
          setUserFile(null)
          setFile(null);
          setuploadError(
            "Your file is a scanned document. It requires the OCR function to be enabled. Please enable OCR and try again."
          );
          setTimeout(handleReset, 6000);
          return;
        }
      }
      console.log("API response---", parsedResponse);
      setIsLoading(false);
      setIsSelected(false);
      // document.body.classList.toggle("show-chatbot");
      setShowSummaryChatbot(true);
    } catch (error) {
      handleReset();
      setWordsCount(0);
      if (error.message === "Failed to fetch") {
        setuploadError(
          "Internet connection is lost. Please check your network."
        );
        alert("Internet connection is lost. Please check your network.")
      } else {
        setuploadError("An unexpected error occurred.");
      }
      console.log("ERROR:", error.message);
      setIsLoading(false);
      
      return;
    }
  };
  const data = "Try for free"
  const handleApplyCoupon = async () => {
    setIsLoading3(true);
    setMessage(null);
    try {
      const response = await fetch('https://pdfing.ai/api/usage/increase_limits', {
      // const response = await fetch('https://www.pdfing.ai:2000/increase_limits', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: user.email,
          date: new Date().toISOString().split('T')[0],
          couponcode: couponCode
        }),
      });

      if (!response.ok) {
        throw new Error('Coupon application failed');
      }

      const data = await response.json();
      console.log(`${data["status"]}res444`);
      console.log(data["message"]);
      if (data["status"] == 'success') {
        setMessage('Coupon successfully applied');
        setTimeout(() => {
          setCouponModal(false);
          setMessage(null);
        }, 3000);
      } else {
        setMessage('Try again');
      }
      setCouponCode('')
      setIsLoading3(false);
    } catch (error) {
      console.error('Error applying coupon:', error);
    }
  };
  const checkUsage = async () => {
    try {
      const response = await fetch("https://pdfing.ai/api/usage/check_usage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
          date: new Date().toISOString().split('T')[0],
        }),
      });

      if (!response.ok) {
        throw new Error(`checkUsage API Error: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log("checkUsage API response:", responseData);
      responseData.plan_name = planName;
      setUsageData(responseData);
    } catch (error) {
      console.error("Error in checkUsage API:", error);
    }
  };

  useEffect(() => {

    const checkPlan = async () => {
      try {
        const response = await fetch("https://pdfing.ai/api/usage/checkstripe-frontend", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
          }),
        });

        if (!response.ok) {
          throw new Error(`checkPlan API Error: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("planTYPE:", responseData["plan_type"]);
        console.log("checkPlan API response:", responseData.message);
        setPlanName(responseData["plan_type"])
      } catch (error) {
        console.error("Error in checkPlan API:", error);
      }
    };

    if(user && planName ==""){
      checkPlan();
      checkUsage();

    }
  },[user])

  const handleOutsideClick = (e) => {
    // Check if the click is outside the modal content
    console.log("HITS33")
    if (e.target.className === 'modal-overlay') {
      setFileModal(false);
    }
  };
  
  // Add useEffect to handle the click listener
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []); // Empty dependency array means it only runs once on mount
  const handleOutsideClick2 = (e) => {
    // Use a ref to check if click is outside the modal content
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowMasterListModal(false);
    }
  };
  
  // Create a ref for the modal content
  const modalRef = useRef(null);
  
  // Add event listener with useEffect
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick2);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick2);
    };
  }, []);

  return (
    <>
    
    <Router>
    <Navbar couponModal={couponModal} setCouponModel={setCouponModal} setPremiumError={setPremiumError} premiumError={premiumError} isHomeWork={isHomeWork} checkUsage={checkUsage} planName={planName} isPremium={isPremium} setIsPremium={setIsPremium} usageData={usageData} setUserEmail={setUserEmail} setIsHomeWork={setIsHomeWork} showMasterListModal={showMasterListModal} setShowMasterListModal={setShowMasterListModal} data={data} file={file} setFile={setFile} setFileModal = {setFileModal} fileModal={fileModal}/>
      {showPopup && <PopModal onClose={handleClose} />}
      <div className={styles.container}>
        <div className={styles.NavbarandBody}>
        {couponModal && (
          <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
              <button 
                className={styles.closeButton} 
                onClick={() => setCouponModal(false)}
                disabled={isLoading3}
              >
                ×
              </button>
              <h2 className={styles.modalTitle}>Apply Coupon Code</h2>
              <input
                type="text"
                placeholder="Enter your coupon code"
                className={styles.couponInput}
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                disabled={isLoading3}
              />
              {message && (
                <div className={`${styles.message} ${
                  message === 'Coupon successfully applied' ? 'success' : 'error'
                }`}>
                  {message}
                </div>
              )}
              <div className={styles.buttonContainer}>
                <button 
                  className={styles.cancelButton} 
                  onClick={() => setCouponModal(false)}
                  disabled={isLoading3}
                >
                  Cancel
                </button>
                <button 
                  className={styles.applyButton}
                  onClick={handleApplyCoupon}
                  disabled={isLoading3}
                >
                  {isLoading ? 'Applying...' : 'Apply Coupon'}
                </button>
              </div>
            </div>
          </div>
        )}
        {fileModal && (
            <div className={styles.modal_overlay}>
              <div className={styles.modal_content2}>
                <FileUpload
                  setPremiumError={setPremiumError}
                  setIsPremium={setIsPremium}
                  planName={planName}
                  usageData={usageData}
                  selectedTopicInMasterList={selectedTopicInMasterList}
                  SetSelectedTopicInMasterList={SetSelectedTopicInMasterList}
                  setTopicFromList={setTopicFromList}
                  topicFromList={topicFromList}
                  files={files}
                  setFiles={setFiles}
                  fileNames={fileNames}
                  setFileNames={setFileNames}
                  fileBlobUrls={fileBlobUrls}
                  setFileBlobUrls={setFileBlobUrls}
                  fileModal={fileModal}
                  setFileModal={setFileModal}
                  isLoading2={isLoading2}
                  setIsLoading2={setIsLoading2}
                />
              </div>
            </div>
          )}
          {showMasterListModal && (
            <CSSTransition
            in={showMasterListModal}
            timeout={300} // Matches your 0.3s
            classNames="fade"
            unmountOnExit
          >
            <div ref={modalRef}>
              <MasterListPage 
                showMasterListModal={showMasterListModal} 
                setShowMasterListModal={setShowMasterListModal}
                topicFromList={topicFromList}
                setTopicFromList={setTopicFromList}
              />
            </div>
          </CSSTransition>
          )}
          
          <Routes>
            <Route
              path="/"
              element={
                <div className={styles.content}>
                  <Home
                  showMasterListModal={showMasterListModal}
                  setShowMasterListModal={setShowMasterListModal}
                    handleReset={handleReset}
                    fileModal={fileModal}
                    setFileModal={setFileModal}
                    urlStats={urlStats}
                    setUrlStats={setUrlStats}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    file={file}
                    setFile={setFile}

                    fileUrl={fileUrl}
                    setFileUrl={setFileUrl}

                  />
                </div>
              }
            />
            <Route
              path="/login"
              element={
                <Login
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    simpleAuth={simpleAuth}
                    setSimpleAuth={setSimpleAuth}
                  />}
            />
            <Route
              path="/payment-accepted"
              element={
                <Login
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    simpleAuth={simpleAuth}
                    setSimpleAuth={setSimpleAuth}
                  />}
            />
            <Route
              path="/blogs"
              element={
                <div className={styles.content}>
                  <BlogsPage
                    setFileModal={setFileModal}
                    showMasterListModal={showMasterListModal}
                    setShowMasterListModal={setShowMasterListModal}
                    fileModal={fileModal}
                    urlStats={urlStats}
                    setUrlStats={setUrlStats}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                  />
                </div>
              }
            />
            <Route
              path="/master-list"
              element={
                <div className={styles.content}>
                  <MasterListPage />
                </div>
              }
            />
            <Route 
              path="/check-homework"
              element={
                <div className={styles.content}>
                  <HomeworkPage
                  useOCR={useOCR}
                  isHomeWork={isHomeWork}
                  setIsHomeWork={setIsHomeWork}
                  showMasterListModal={showMasterListModal}
                  setShowMasterListModal={setShowMasterListModal}
                  setUseOCR={setUseOCR}
                    setFileModal={setFileModal}
                    fileModal={fileModal}
                    hasMath={hasMath}
                    setHasMath={setHasMath}
                    file={file}
                    setFile={setFile}
                    userFile={userFile}
                    fileUrl={fileUrl}
                    setUserFile={setUserFile}
                    setFileUrl={setFileUrl}
                    setFileName={setFileName2}
                    fileName={fileName2}
                    sourceFileName={sourceFileName}
                    setSourceFileName={setSourceFileName}
                    assigFileName={assigFileName}
                    setAssigFileName={setAssigFileName}

                  />
                </div>
              }
            />
            <Route element={<PrivateRoutes />}>
              <Route
                path="/comparison"
                element={
                  <div className={styles.content}>
                    <Comparison
                      isLoading2={isLoading2}
                      setPremiumError={setPremiumError}
                      usageData={usageData}
                      files={files}
                      user={user}
                      setFile={setFile}
                      setTopicFromList={setTopicFromList}
                      topicFromList={topicFromList}
                      showMasterListModal={showMasterListModal}
                    setShowMasterListModal={setShowMasterListModal}
                      setFileModal={setFileModal}
                      fileModal={fileModal}
                      setFileNames={setFileNames}
                      fileNames={fileNames}
                      fileBlobUrls={fileBlobUrls}
                      setIsLoading2={setIsLoading2}
                      isAuthenticated={isAuthenticated}
                      setIsAuthenticated={setIsAuthenticated}
                    />
                  </div>
                }
              />
            </Route>
            <Route
                path="/payment-form"
                element={
                  <div className={styles.content}>
                    {/* <PaymentForm/> */}
                    <PaymentForm email = {userEmail}/>
                  </div>
                }
                />
            <Route
              path="/terms-of-sevices"
              element={
                <div className={styles.content}>
                  <TermService
                  showMasterListModal={showMasterListModal}
                  setShowMasterListModal={setShowMasterListModal}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                  />
                </div>
              }
            />

            <Route
              path="/contactus"
              element={
                <div className={styles.content}>
                  <ContactPage />
                </div>
              }
            />
            <Route
              path="/reportbug"
              element={
                <div className={styles.content}>
                  <ReportBug />
                </div>
              }
            />
            <Route
              path="/blog/:id"
              element={
                <div className={styles.content}>
                  <AllPageee
                  showMasterListModal={showMasterListModal}
                  setShowMasterListModal={setShowMasterListModal}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    file={file}
                    setFile={setFile}
                  />
                </div>
              }
            />
            <Route
              path="privacypolicy"
              element={
                <div className={styles.content}>
                  <PrivacyPolicyPage />
                </div>
              }
            />
            <Route element={<PrivateRoutes />}>
              <Route
                path="/summariser"
                element={
                  <AdvancedSummary
                  showMasterListModal={showMasterListModal}
                  planName={planName}
                  setIsPremium={setIsPremium}
                  setPremiumError={setPremiumError} 
                  premiumError={premiumError}
                  isHomeWork={isHomeWork}
                  usageData={usageData}
                  setUsageData={setUsageData}
                  setIsHomeWork={setIsHomeWork}
                  sourceFileName={sourceFileName}
                  setSourceFileName={setSourceFileName}
                  assigFileName={assigFileName}
                  setAssigFileName={setAssigFileName}
                  setHasMath={setHasMath}
                    setShowMasterListModal={setShowMasterListModal}
                    setFileModal={setFileModal}
                    useOCR={useOCR}
                  setUseOCR={setUseOCR}
                    fileModal={fileModal}
                    hasMath={hasMath}
                    userFile={userFile}
                    setUserFile={setUserFile}
                    selectedModel={selectedModel}
                    setSelectedModel={setSelectedModel}
                    showSummaryChatbot={showSummaryChatbot}
                    callOverviewOnce={callOverviewOnce}
                    uploadedFile={uploadedFile}
                    setUploadedfile={setUploadedfile}
                    setPdfUrls={setPdfUrls}
                    setuploadError={setuploadError}
                    uploadError={uploadError}
                    setWordsCount={setWordsCount}
                    wordsCount={wordsCount}
                    chat={chat}
                    setChat={setChat}
                    chatHistory={chatHistory}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    ocrProcessing={ocrProcessing}
                    setOcrProcessing={setOcrProcessing}
                    response={response}
                    setResponse={setResponse}
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                    urlStats={urlStats}
                    handleReset={handleReset}
                    setUrlStats={setUrlStats}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    setChatHistory={setChatHistory}
                    file={file}
                    setFile={setFile}
                    fileName={fileName}
                    setFileName={setFileName}
                    fileContent={fileContent}
                    setFileContent={setFileContent}
                    setShowSummaryChatbot={setShowSummaryChatbot}
                    caseName={chat}

                    fileUrl={fileUrl}
                    setFileUrl={setFileUrl}
                  />
                }
              />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route
                path="/check-homeworks"
                element={
                  <AdvancedSummary
                  showMasterListModal={showMasterListModal}
                  planName={planName}
                  setIsPremium={setIsPremium}
                  setPremiumError={setPremiumError} 
                  premiumError={premiumError}
                  usageData={usageData}
                  isHomeWork={isHomeWork}
                  setIsHomeWork={setIsHomeWork}
                  sourceFileName={sourceFileName}
                  setSourceFileName={setSourceFileName}
                  assigFileName={assigFileName}
                  setAssigFileName={setAssigFileName}
                  setHasMath={setHasMath}
                    setShowMasterListModal={setShowMasterListModal}
                    setFileModal={setFileModal}
                    useOCR={useOCR}
                  setUseOCR={setUseOCR}
                    fileModal={fileModal}
                    hasMath={hasMath}
                    userFile={userFile}
                    setUserFile={setUserFile}
                    selectedModel={selectedModel}
                    setSelectedModel={setSelectedModel}
                    showSummaryChatbot={showSummaryChatbot}
                    callOverviewOnce={callOverviewOnce}
                    uploadedFile={uploadedFile}
                    setUploadedfile={setUploadedfile}
                    setPdfUrls={setPdfUrls}
                    setuploadError={setuploadError}
                    uploadError={uploadError}
                    setWordsCount={setWordsCount}
                    wordsCount={wordsCount}
                    chat={chat}
                    setChat={setChat}
                    chatHistory={chatHistory}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    ocrProcessing={ocrProcessing}
                    setOcrProcessing={setOcrProcessing}
                    response={response}
                    setResponse={setResponse}
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                    urlStats={urlStats}
                    handleReset={handleReset}
                    setUrlStats={setUrlStats}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    setChatHistory={setChatHistory}
                    file={file}
                    setFile={setFile}
                    fileName={fileName}
                    setFileName={setFileName}
                    fileContent={fileContent}
                    setFileContent={setFileContent}
                    setShowSummaryChatbot={setShowSummaryChatbot}
                    caseName={chat}

                    fileUrl={fileUrl}
                    setFileUrl={setFileUrl}
                  />
                }
              />
            </Route>
            <Route path="*" element={<PageNotFound></PageNotFound>} />
          </Routes>
        </div>
        <div className={styles.FooterFloat}>
          <Footer />
        </div>
      </div>
    </Router>
    </>
  );
}

export default App;
