import React, { useEffect, useState } from 'react';
import styles from './payment-form.module.css';
import { CheckBoxRounded, PaymentOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Check, TicketCheck } from 'lucide-react';
import { useAuth0 } from '@auth0/auth0-react';
import SubscriptionModal from './subscription-modal';

export default function PaymentForm(email) {
  const location = useLocation();
  const [redirecting, setRedirecting] = useState(false);

    // Extract query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    const sessionId = searchParams.get('session_id');
    const canceled = searchParams.get('canceled');
    const {user} = useAuth0();
    const navigate = useNavigate();

    const storePaymentSucess = async(sessionId) => {
      
      const CheckStripe = await fetch("https://pdfing.ai/api/usage/checkstripe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user.email,
        }),
      });

      if (!CheckStripe.ok) {
        throw new Error(`CheckStripe API Error: ${CheckStripe.statusText}`);
      }

      const responseData = await CheckStripe.json();
      console.log("CheckStripe API response:", responseData);

    }

    useEffect(() =>{
      console.log("email1",user?.email);
      
      if(success && user?.email){
        storePaymentSucess(sessionId);
        setRedirecting(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);

      }else{
        console.log("PymentFail",success,canceled)
      }
    },[user?.email,success,canceled])
  return (
    <div className={styles.container}>
      <div style={{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        gap:"4em",
        flexDirection:"column"
      }}>
            {success && (
                <SubscriptionModal planName="Premium" planPrice="0.00"/>
            )}
            {canceled && <p>Payment Canceled. Please try again.</p>}
            {!success && !canceled && <p>No payment status available.</p>}
            {redirecting && <p>Redirection to home page ....</p>}
            
        
      </div>

      {/* <div className={styles.paymentMethods}>
        <div className={styles.methodCard}>
          <div className={styles.methodHeader}>
            <span className={styles.cardIcon}>□</span>
            <span>Credit card</span>
            <div className={styles.cardLogos}>
              <span className={styles.visaLogo}>VISA</span>
              <span className={styles.amexLogo}>AMEX</span>
              <span className={styles.more}>+3</span>
            </div>
          </div>

          <div className={styles.cardInputs}>
            <input
              type="text"
              placeholder="Card number"
              className={styles.fullWidth}
            />
            <div className={styles.cardDetails}>
              <input
                type="text"
                placeholder="Expiration date (MM / YY)"
                className={styles.halfWidth}
              />
              <input
                type="text"
                placeholder="Security code"
                className={styles.halfWidth}
              />
            </div>
            <input
              type="text"
              placeholder="Name on card"
              className={styles.fullWidth}
            />
          </div>
        </div>

        <div className={styles.methodOption}>
          <input type="radio" name="payment" id="paypal" />
          <label htmlFor="paypal">PayPal</label>
          <span className={styles.paypalLogo}>PayPal</span>
        </div>

        <div className={styles.methodOption}>
          <input type="radio" name="payment" id="stripe" />
          <label htmlFor="stripe">Stripe</label>
          <span className={styles.stripeLogo}>stripe</span>
        </div>
      </div>

      <div className={styles.remember}>
        <label>
          Remember me
          <input type="checkbox" />
          <span className={styles.toggle}></span>
        </label>
      </div>

      <div className={styles.phoneInput}>
        <span className={styles.phoneIcon}>□</span>
        <input type="tel" placeholder="Mobile phone number" />
      </div>

      <button className={styles.subscribeButton}>
        Subscribe now
      </button> */}
    </div>
  );
}
