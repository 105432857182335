'use client'

import { useEffect, useState } from 'react'
import TopicList from './components/TopicList'
import AddItemForm from './components/AddItemForm'
import styles from './masterList.module.css'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import { CloseButton } from '@chakra-ui/react'
import { CloseFullscreen, CloseOutlined } from '@mui/icons-material'
import pdfToText from 'react-pdftotext'

export default function MasterListPage({showMasterListModal,setShowMasterListModal,topicFromList,setTopicFromList}) {
  const [topics, setTopics] = useState([])
  const {user} = useAuth0();
  const [newTopicAdded, setNewTopicAdded] = useState(false);
  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState("");
  const [uploadTopicIndex, setUploadTopicIndex] = useState("");
  

  const apiLink = "https://pdfing.ai/api/pdfing/masterlist";

  function handleFileUpload3(e,topicIndex) {
    e.preventDefault();
    
    setFile(e.target.files[0]);
    setUploadTopicIndex(topicIndex)
    console.log("uploa3",topicIndex)
    e.target.value = null;
    
    // setFileUrl(URL.createObjectURL(e.target.files[0]))
  }


  const addTopic = (topicName) => {
    setTopics([...topics, { topic_id: topics.length, topic: topicName, questions: [] }])
    setNewTopicAdded(true);
  }
  const saveTopic = async () => {
    
    // Payload for the API request
    const payload = {
      email: user?.email,
      topics: topics,
    };
    console.log("payload",payload)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
  
    try {
      // Make the API call
      const response = await fetch(apiLink, requestOptions);
  
      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Parse the response JSON
      const responseData = await response.json();
      console.log(responseData.message); // Log success message
    } catch (error) {
      // Handle any errors
      console.error("Error adding topic:", error.message);
    }
  };

  const updateTopic = async (index,topic_name) =>{
    const apiLink = `https://pdfing.ai/api/pdfing/masterlist/${user?.email}/topics`;

  // Define the request body based on whether the topic is new or an update
  const requestBody = {
    topic_id: index,
    topic_name: topic_name,
  };

  try {
    const response = await fetch(apiLink, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`Error adding/updating topic: ${response.statusText}`);
    }

    // Parse the response to check the success message
    const result = await response.json();
    console.log(result.message); // Success message from the backend
  } catch (error) {
    console.error("Error adding/updating topic:", error);
  }
  }
  const deleteTopicinDB = async (topicId) => {
    // Construct the API endpoint with the user's email and topic_id
    const apiLink = `https://pdfing.ai/api/pdfing/masterlist/${user?.email}/topics/${topicId}`;
  
    try {
      const response = await fetch(apiLink, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error deleting topic: ${response.statusText}`);
      }
  
      // Parse the response to confirm the deletion
      const result = await response.json();
      console.log(result.message); // Success message from the backend
  
      // Update the topics in the state to reflect the deletion
      setTopics((prevTopics) => prevTopics.filter((topic) => topic.topic_id !== topicId));
    } catch (error) {
      console.error("Error deleting topic:", error);
    }
  };

  const deleteQuestionDB = async (topicId, questionId) => {
    // Construct the API endpoint with email, topic_id, and question_id
    const apiLink = `https://pdfing.ai/api/pdfing/masterlist/${user?.email}/topics/${topicId}/questions/${questionId}`;
  
    try {
      const response = await fetch(apiLink, {
        method: 'DELETE', // DELETE method as per API documentation
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error deleting question: ${response.statusText}`);
      }
  
      // Parse the response to confirm the deletion
      const result = await response.json();
      console.log(result.message); // Success message from the backend
  
      // Update the topics and questions in the state
      setTopics((prevTopics) =>
        prevTopics.map((topic) =>
          topic.topic_id === topicId
            ? {
                ...topic,
                questions: topic.questions.filter((q) => q.question_id !== questionId),
              }
            : topic
        )
      );
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };
  const addOrUpdateQuestion = async (topicId, questionId, questionText) => {
    const apiLink = `https://pdfing.ai/api/pdfing/masterlist/${user?.email}/topics/${topicId}/questions`;
  
    // Define the request payload
    const requestBody = {
      question_id: questionId, // Pass the ID if updating, or a new one for adding
      question: questionText,  // The question text
    };
  
    try {
      const response = await fetch(apiLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error(`Error adding/updating question: ${response.statusText}`);
      }
  
      // Parse and log the success message
      const result = await response.json();
      console.log(result.message); // e.g., "Question added/updated successfully."
    } catch (error) {
      console.error("Error adding/updating question:", error);
    }
  };
  
  
  
  

  const deleteTopic = (index) => {
    
    const newTopics = [...topics]
    newTopics.splice(index, 1)
    setTopics(newTopics)
    setNewTopicAdded(true);
    // deleteTopicinDB(index)
  }

  const addQuestion = (topicIndex, questionText) => {
    // Get the selected topic using the topicIndex
  const selectedTopic = topics[topicIndex];

  // Determine the new question ID based on the length of the questions array
  const questionId = selectedTopic.questions.length;
    const newQuestion = {
      question_id: questionId,
      question: questionText,
    };
    const newTopics = [...topics]
    newTopics[topicIndex].questions.push(newQuestion)
    setTopics(newTopics)
    setNewTopicAdded(true);
    // addOrUpdateQuestion(topicIndex,questionId,questionText)
  }

  const deleteQuestion = (topicIndex, questionIndex) => {
    const newTopics = [...topics]
    newTopics[topicIndex].questions.splice(questionIndex, 1)
    setTopics(newTopics)
    setNewTopicAdded(true);
    // deleteQuestionDB(topicIndex,questionIndex)
    
  }
  const editQuestion = (topicIndex, questionIndex, newQuestionText) => {
    const newTopics = [...topics]; // Copy the current topics state
    newTopics[topicIndex].questions[questionIndex].question = newQuestionText; // Update the specific question
    setTopics(newTopics); // Update the topics state
    setNewTopicAdded(true);
    // addOrUpdateQuestion(topicIndex,questionIndex,newQuestionText)
    
  };

  const editTopic = (index, newName) => {
    const newTopics = [...topics]
    newTopics[index].topic = newName
    setTopics(newTopics)
    setNewTopicAdded(true);
    // updateTopic(index, newName)
    
  }
  const fetchMasterlist = async (email) => {
    const apiLink = `https://pdfing.ai/api/pdfing/masterlist/${email}`;
  
    try {
      // Make the GET request
      const response = await fetch(apiLink, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error fetching masterlist: ${response.statusText}`);
      }
  
      // Parse the JSON response
      const masterlist = await response.json();
      console.log("Masterlist retrieved:", masterlist);
  
      // Assuming you want to set this data into the topics state
      if (masterlist.topics) {
        setTopics(masterlist.topics);
      }
    } catch (error) {
      console.error("Error retrieving masterlist:", error);
    }
  };
  useEffect(() => {
    fetchMasterlist(user?.email)
    }, []);
  useEffect(() => {
    if(newTopicAdded){
      saveTopic()
      setNewTopicAdded(false)
    }
  }, [topics,newTopicAdded])

  useEffect(() => {
    const fetchFileContent = async () => {
      if (file) {
        const text = await pdfToText(file);
        setFileContent(text);
        console.log(text,"rrr22")
      }
    };
  
    fetchFileContent();
  }, [file]);
  
  const uploadLink = "https://pdfing.ai/api/pdfing/questionlist";

useEffect(() => {
  const masterListTopicFetch = async () => {
    if (fileContent.length > 0) {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            
            "assignmenttext": fileContent,
            // "context": fileContent,
          }),
        };

        const response = await fetch(uploadLink, requestOptions);

        // Check if the response status is OK (200-299)
        if (!response.ok) {
          console.error(`Server responded with status: ${response.status}`, response.statusText);
          return;
        }

        const data = await response.json();
        console.log("Response Data:", data);

        if (data) {
          data.map((item, index) => {
            // setTopics((prevTopics) => [
            //   ...prevTopics,
            //   { topic_id: prevTopics.length, topic: item, questions: [] },
            // ]);
            const selectedTopic = topics[uploadTopicIndex];
            const questionId = selectedTopic.questions.length;
            const newQuestion = {
              question_id: questionId,
              question: item,
            };
            const newTopics = [...topics]
            newTopics[uploadTopicIndex].questions.push(newQuestion)
            setTopics(newTopics)
            console.log("item:  ",item);
            console.log("selectedTopic:  ",selectedTopic);
            console.log("newQuestion:  ",newQuestion);
          });
          setNewTopicAdded(true)
        }
        
      } catch (error) {
        console.error("Failed to fetch:", error);
      }
    }
  };

  masterListTopicFetch();
}, [fileContent]);


  return (
    <div className={`fade-in ${styles.container}`}>
      <div className={styles.card}>
        <div className={styles.cardHeader}> 
          <div><h1 className={styles.cardTitle}>Master List</h1></div>
          
          <div className={styles.uploadBox}>
          
          <div  onClick={()=>{setShowMasterListModal(!showMasterListModal)}}><CloseOutlined /></div>
          
          </div>
          {/* <div><button className={styles.button} onClick={()=>{
            saveTopic()
          }}>Save</button></div> */}
        </div>
        <div className={styles.cardContent}>
          <AddItemForm onAdd={addTopic} placeholder="Add a new topic" />
          <TopicList
            topics={topics}
            handleFileUpload3={handleFileUpload3}
            onDeleteTopic={deleteTopic}
            onAddQuestion={addQuestion}
            onDeleteQuestion={deleteQuestion}
            onEditTopic={editTopic}
            onEditQuestion={editQuestion}
            setTopicFromList={setTopicFromList}
          />
        </div>
      </div>
    </div>
  )
}
