import React, { useRef, useState } from "react";
import FileUploadComponent from "./FileUploadComponent";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation, useHistory, useNavigate } from "react-router-dom";
import styles2 from './Workoption.module.css'
import sourceImg from "../../../assets/Vector.svg"

import "./AdvancedSummary.css";
import {
  Card,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Gear from "../../../Gear.png";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import generateIcon from "../../../assets/generateIcon.svg";
import Navbar from "../../Navbar/Navbar";
import Chatbot from "./Chatbot";
import { useAuth0 } from "@auth0/auth0-react";
import { pdfjs } from "react-pdf";
import MathList from "./Mathlist";

const Tab = styled(BaseTab)`
  color: black;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: transparent;
  width: 100%;
  text-wrap: nowrap;
  padding: 8px 8px;
  margin: 6px;
  border-radius: 20px;
  display: flex;
  justify-content: center;

  &.${tabClasses.selected} {
    background-color: #0066cc;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: white;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 200px;
  border-radius: 20px;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `
);


const test = {
  answer:
    "<h2>Professional Summary</h2>\n<ul>\n<li>Detail-oriented professional with 1 year of experience in software testing</li>\n<li>Expertise in various types of testing and testing phases</li>\n</ul>\n\n<h2>Work Experience</h2>\n<ul>\n<li>Worked as Manual Test Engineer at Fino Payments Bank</li>\n<li>Analyzed requirements, designed and executed test cases, reported and tracked defects</li>\n</ul>\n\n<h2>Projects Accomplished</h2>\n<ul>\n<li>Current & Saving Account Opening</li>\n<li>UPI Daily Limit Transaction - P2P & P2M</li>\n<li>Insurance Policy Booking - Shopkeeper Policy Booking & Health Policy Booking</li>\n</ul>\n\n<h2>Certification</h2>\n<ul>\n<li>Certificate in Manual Testing & Automation Testing from Qspider's vashi</li>\n</ul>\n\n<h2>Academic Qualifications</h2>\n<ul>\n<li>B.Sc. (CS) from Pune University in 2022</li>\n<li>H.S.C. from Maharashtra State Board in 2018</li>\n</ul>\n\n<h2>Personal Details</h2>\n<ul>\n<li>Female, Married, DOB: 01 Jan 2000</li>\n<li>Languages Known: English, Hindi, Marathi</li>\n<li>Permanent Address: Pune - 412303</li>\n<li>Current Address: Mumbai - 400071</li>\n<li>Nationality: Indian</li>\n</ul>",
};

function AdvancedSummary({planName,setPremiumError, premiumError,setIsPremium,isHomeWork,setIsHomeWork,usageData,setUsageData,showMasterListModal,setShowMasterListModal,
  setFileModal,
  sourceFileName,
  setSourceFileName,
  assigFileName,
  setAssigFileName,
  setHasMath,
  useOCR,
  setUseOCR,
  fileModal,
  setWordsCount,
  userFile,
  setUserFile,
  hasMath,
  selectedModel,
  setSelectedModel,
  uploadedFile,
  wordsCount,
  uploadError,
  setuploadError,
  showSummaryChatbot,
  setPdfUrls,
  chat,
  setChat,
  isSelected,
  setIsSelected,
  ocrProcessing,
  setOcrProcessing,
  isLoading,
  setIsLoading,
  response,
  setResponse,
  handleReset,
  urlStats,
  setUrlStats,
  isAuthenticated,
  setIsAuthenticated,
  chatHistory,
  setChatHistory,
  file,
  setFile,
  fileUrl, setFileUrl,
  caseName,
  setShowSummaryChatbot,
  fileContent,
  setFileContent,
  fileName,
  setFileName,
  callOverviewOnce,
  setUploadedfile

}) {
  const [userEmail, setUserEmail] = useState();
  const { logout, loginWithRedirect, loginWithPopup } = useAuth0();
  const [type1Value, setType1Value] = useState("topic");
  const [type2Value, setType2Value] = useState("short");
  const [curSummaryname, setcurSummaryname] = useState({ "val1": 'overview', "val2": '' });
  const [isSelectedMath, setSelectedMath] = useState(false);
  const [newCase, setNewCase] = useState();
  const [useNewCase, setUseNewCase] = useState();

  const [openModal, setOpenModal] = useState(false);
  const [caseSelected, setCaseSelected] = useState(false);
  const { state } = useLocation();
  const [shareableLink, setShareableLink] = useState('');
  const [modalOpen, setModalOpen] = useState(false)
  const { user } = useAuth0();
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate();
  

  // useEffect(() => {
  //   console.log(wordsCount);
    
  //   if (usageData && Math.floor(wordsCount / usageData["dailywordlimit"]) > 1 && usageData.plan_name != 'B') {
  //     setuploadError(
  //       "This document length exceeds the free account limit."
  //     );
  //     console.log("gg3")
  //     const timeoutId = setTimeout(() => {
  //       console.log("gg7")
  //       setIsPremium(true);
  //       setuploadError('');
  //       setErrorMessage("")
  //       handleReset();
  //     }, 8000);

  //     // Clean up the timeout on component unmount or when wordsCount changes
  //     return () => clearTimeout(timeoutId);
      
  //   }
  // }, [wordsCount])

  useEffect(() => {
    console.log("fileContent22", fileContent);
    console.log("state", state?.parsedResponse);
    setFileContent(state?.parsedResponse);
    console.log(urlStats, "urlstats");

    // if (urlStats != "home"){
    //   console.log("handleReset runs")
    //   handleReset()
    // }
  }, [state?.parsedResponse]);

  const handleType1Change = (event, newValue) => {
    setType1Value(newValue);
  };


  const handleType2Change = (event, newValue) => {
    setType2Value(newValue);
  };

  const handleSummaryClick = () => {
    setIsSelected(!isSelected);
  };
  const handleMap = () => {

  };

  const buttonStyle3 = (isSelectedModel) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px 15px",
    borderRadius: "2em",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    border: isSelectedModel ? "none" : "1px solid rgb(20, 114, 255)",
    color: isSelectedModel ? "#fff" : "rgb(20, 114, 255)",
    backgroundColor: isSelectedModel ? "rgb(20, 114, 255)" : "#fff",
  });
  const buttonStyle4 = (isSelectedModel) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px 15px",
    borderRadius: "2em",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    border: isSelectedModel ? "none" : "1px solid rgb(20, 114, 255)",
    color: isSelectedModel ? "#fff" : "rgb(20, 114, 255)",
    backgroundColor: isSelectedModel ? "rgb(20, 114, 255)" : "#fff",
  });

  const buttonStyle5 = (isSelectedMath) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 22px",
    borderRadius: "2em",
    fontSize: "17px",
    fontWeight: "bold",
    cursor: "pointer",
    border: isSelectedMath ? "none" : "1px solid rgb(20, 114, 255)",
    color: isSelectedMath ? "#fff" : "rgb(20, 114, 255)",
    backgroundColor: isSelectedMath ? "rgb(20, 114, 255)" : "#fff",
  });

  const buttonStyle = {
    color: isSelected ? "white" : "black",
    cursor: "pointer",
    fontSize: "0.875rem",
    fontWeight: 400,
    backgroundColor: isSelected ? "#0066cc" : "transparent",
    width: "100%",
    height: "30%",
    marginTop: "10px",
    border: "1px solid black",
    whiteSpace: "nowrap",
    padding: "8px 8px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    textAlign: "center",
    bgcolor: "background.paper",

    p: 4,
  };

  const ModalComponent = () => {
    return (
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-description" variant="h5">
            Process Finished
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            You will be further noitified by email.
          </Typography>
          <Button
            onClick={() => setOpenModal(false)}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    );
  };
  async function uploadFileToAPI(file, fileName) {
    const apiEndpoint = "https://pdfing.ai/api/pdfing/upload-img";
    const formData = new FormData();

    // Append the file and filename to the FormData object
    formData.append("file", file);
    formData.append("filename", `${file.name}_image.jpg`);
    formData.append("email", user.email);

    try {
        const response = await fetch(apiEndpoint, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Upload successful. Response:", data);
        return data; // Handle the response data
    } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
    }
}
const handleOcrCall = async (uploadedFile) => {
  console.log("CallinfOCR", uploadedFile)
  const apiLink = "https://pdfing.ai/api/usage/ocr";
  const formData = new FormData();
  formData.append('pdfFile', uploadedFile);
  formData.append('email', user.email);
  formData.append('date', new Date().toISOString().split('T')[0],);


  const requestOptions = {
    method: "POST",
    body: formData,
  };



  try {
    setIsLoading(true);
    let ocrResponse = await fetch(apiLink, requestOptions);
    let parsedOcrResponse = await ocrResponse.json();
    if(parsedOcrResponse['status'] == 'error'){
      setPremiumError(
        "Please upgrade the plan to continue."
      );
      setIsPremium(true)
      handleReset()
      setErrorMessage("")
      return
  }
    // setOcrResponse(parsedOcrResponse);
    console.log("ocr response: ", parsedOcrResponse.content)
    setFileContent(parsedOcrResponse.content)
    setWordsCount(parsedOcrResponse.content.trim().split(/\s+/).length)
    console.log("HERE55");
    callOverviewOnce(parsedOcrResponse.content,parsedOcrResponse.content.trim().split(/\s+/).length)


    // setIsLoading(false)
    console.log("ocr response", parsedOcrResponse)

  } catch (error) {
    console.log("error", error)
    setIsLoading(false)
  }
};
  const processFile = async () => {
    if (!file) return;

    if (
      hasMath &&
      ["image/jpeg", "image/png", "image/jpg","application/pdf"].includes(file.type)
    ) {
      try {
        setIsLoading(true);
        setSelectedMath(true);
        console.log("Processing math file...");

        setFileContent("parsedContent");
        setFileName(file.name);
        setFileUrl(URL.createObjectURL(file));

        if (file.type === "application/pdf") {
          const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise;
          const pages = [];
    
          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
    
            // Create a canvas to render the PDF page
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const viewport = page.getViewport({ scale: 2 }); // Adjust scale for higher quality
    
            canvas.width = viewport.width;
            canvas.height = viewport.height;
    
            await page.render({ canvasContext: context, viewport }).promise;
    
            // Convert canvas content to a JPG data URL
            const jpgData = canvas.toDataURL("image/jpeg", 1.0); // High-quality JPG
            pages.push(jpgData);
          }
    
            // Process each page
            for (let i = 0; i < pages.length; i++) {
              const jpgData = pages[i];

              // Convert data URL to a Blob for upload
              const blob = await (await fetch(jpgData)).blob();
              const fileForUpload = new File([blob], `page-${i + 1}.jpg`, {
                type: "image/jpeg",
              });

              try {
                // Upload the page (JPG file)
                const uploadData = await uploadFileToAPI(fileForUpload);
                console.log(`Uploaded page ${i + 1} response:`, uploadData);
                

                // Call the do_mathematics API
                const formatType = fileForUpload.type.split("/")[1];
                console.log(`page: ${i + 1} mathresponse222`, uploadData.file_url,formatType);
                const mathResponse = await fetch("https://pdfing.ai/api/pdfing/do_mathematics", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    image_url: uploadData.file_url,
                    format_type: formatType,
                  }),
                  mode: 'cors'
                });

                const mathResult = await mathResponse.json();
                console.log(`Math response for page ${i + 1}:`, mathResult);
                const renderedComponent = <MathList Items={mathResult} />;

                setResponse((prevResponse) => (
                  <>
                    {prevResponse}
                    {renderedComponent}
                  </>
                ));
            
                // Append mathResult to fileContent
                setFileContent((prevContent) => prevContent + JSON.stringify(mathResult));

                setShowSummaryChatbot(true);
                setIsLoading(false)
              } catch (error) {
                console.error(`Error processing page ${i + 1}:`, error);
              }
            }
        } else {
          // Upload file to API
          const uploadData = await uploadFileToAPI(file);
          console.log("Uploaded file response:", uploadData);
          const formatType = file.type.split("/")[1];
          const mathResponse = await fetch("https://pdfing.ai/api/pdfing/do_mathematics", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              image_url: uploadData.file_url,
              format_type: formatType,
            }),
          });

          if (!mathResponse.ok) {
            throw new Error(`Math API Error: ${mathResponse.statusText}`);
          }

          const responseData = await mathResponse.json();
          console.log("Mathematics API response:", responseData);

        // Update state with results
        const renderedComponent = <MathList Items={responseData} />;
        setResponse(renderedComponent);
        setFileContent(JSON.stringify(responseData));
        setShowSummaryChatbot(true);
        }
      } catch (error) {
        console.error("Error processing math file:", error);
        if (error.message === "Failed to fetch") {
          alert("Internet connection is lost. Please check your network.");
          navigate("/check-homework");
        } else {
          alert("An unexpected error occurred.");
        }
      } finally {
        setIsLoading(false); // Reset loading state
      }
    } else if (!hasMath && file && !isSelectedMath) {
      console.log("Processing non-math file...");
      setShowSummaryChatbot(false);
      setFileUrl(URL.createObjectURL(file));
      setFileName(file.name);
      setUploadedfile(file);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;

        if (file.type === "application/pdf") {
          // setIsLoading(true);
          if (ocrProcessing) {
            console.log("Calling OCR processing...");
            await handleOcrCall(file);
          } else {
            console.log("Extracting text from PDF...");
            const loadingTask = pdfjs.getDocument({ data: content });
            const pdf = await loadingTask.promise;

            let pageTexts = "";
            for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
              const page = await pdf.getPage(pageNumber);
              const textContent = await page.getTextContent();
              const pageText = textContent.items.map((item) => item.str).join(" ");
              pageTexts += `#-Page: ${pageNumber}-#${pageText}`;
            }

            console.log("Extracted PDF text:", pageTexts);
            
            setFileContent(pageTexts);
            setWordsCount(pageTexts.trim().split(/\s+/).length);
            
            callOverviewOnce(pageTexts,pageTexts.trim().split(/\s+/).length);
          }
        } else if (
          file.type === "application/msword" ||
          file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          setIsLoading(true)
          try {
            console.log("Processing Word document...");
          
            // Create FormData for file upload
            const formData = new FormData();
            formData.append("file", file);
            formData.append("email",user.email);
          
            // Make API call to convert Word to PDF
            const response = await fetch("https://www.pdfing.ai:2000/convert", {
              method: "POST",
              body: formData,
            });
          
            if (response.ok) {
              console.log("Received converted PDF. Extracting text...");
          
              // Convert the response into a Blob
              const blob = await response.blob();
          
              // Use FileReader to convert Blob to ArrayBuffer
              const reader = new FileReader();
              reader.onload = async (e) => {
                const content = e.target.result; // ArrayBuffer
          
                try {
                  if (ocrProcessing || useOCR.includes(file.name)) {
                    console.log("Calling OCR processing...");
                    await handleOcrCall(blob);
                  }else{
                    // Load the PDF using pdf.js
                    const loadingTask = pdfjs.getDocument({ data: content });
                    const pdf = await loadingTask.promise;
            
                    let pageTexts = "";
                    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                      const page = await pdf.getPage(pageNumber);
                      const textContent = await page.getTextContent();
                      const pageText = textContent.items.map((item) => item.str).join(" ");
                      pageTexts += `#-Page: ${pageNumber}-#${pageText}`;
                    }
            
                    console.log("Extracted PDF text:", pageTexts);
            
                    // Update the state with extracted content
                    
                    
                    setFileContent(pageTexts);
                    setWordsCount(pageTexts.trim().split(/\s+/).length);
                    
                    callOverviewOnce(pageTexts,pageTexts.trim().split(/\s+/).length);
                  }
                } catch (pdfError) {
                  console.error("Error extracting text from PDF:", pdfError);
                  setErrorMessage("Failed to extract text from the PDF. Please try again.");
                }
              };
          
              reader.onerror = (error) => {
                console.error("Error reading the PDF Blob:", error);
                setErrorMessage("Failed to read the converted PDF. Please try again.");
              };
          
              reader.readAsArrayBuffer(blob); // Trigger FileReader to process the Blob
            } else {
              const errorText = await response.text();
              console.error("Word document conversion failed:", response.statusText, errorText);
              setErrorMessage("Failed to convert the Word document. Please try again.");
            }
          } catch (error) {
            console.error("Error during Word document conversion:", error);
            setErrorMessage("An unexpected error occurred. Please try again.");
          }
          
        } else {
          setFileContent("");
          setErrorMessage(
            "Unsupported file format. Please upload a PDF or Document file."
          );
        }          
        
      };

      reader.readAsArrayBuffer(file);
      setIsLoading(false);
    }
  };



  const handleGenerate = async () => {
    if (!file) {
      return;
    }
    if(isHomeWork){
      if(assigFileName && userFile){
        setuploadError("")
        processFile()
        
      }else{
        console.log("call3")
        setuploadError(
          "The Source and the Assignment file both need to be uploaded"
        );
        return
      }
      
    }else{
      setIsLoading(true);
      setcurSummaryname({ "val1": type1Value, "val2": type2Value });
      setResponse("");
      let apiLink = "";
      if (isSelectedMath === true) {
        apiLink = ""
      }else {
        apiLink = "https://pdfing.ai/api/usage/redirect_func";
        
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          content: fileContent,
          category: isSelected ? "overview" : type1Value,
          length: isSelected ? "" : type2Value,
          model: selectedModel,
          email:user.email,
          date:new Date().toISOString().split('T')[0],
          api_name:"parse",
          word_count: wordsCount,
        }),
      };

      try {
        const response = await fetch(apiLink, requestOptions);
        const parsedResponse = await response.json();
        if(parsedResponse['status'] == 'error'){
          setPremiumError(
            "Your word limit has exceeded.Please upgrade the plan to continue."
          );
          setIsPremium(true)
          handleReset()
          setErrorMessage("")
          return
        }
        setResponse(parsedResponse.answer);

        console.log("API response---", parsedResponse);
        setIsLoading(false);
        setIsSelected(false);
      } catch (error) {
        console.log("ERROR:", error);
        setIsLoading(false);
      }
      }
    };

    const handleIngest = async () => {
      setIsLoading(true);
      setResponse("");

      const apiLink = "https://upload.verisage.ai:5000/ingest";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          bucket_name: "parvbucket12",
          email: userEmail,
          case_name: useNewCase ? newCase : caseName,
          text: fileContent,
        }),
    };

    try {
      const response = await fetch(apiLink, requestOptions);
      const parsedResponse = await response.json();
      setResponse(parsedResponse.answer);

      console.log("API response---", parsedResponse);
      setIsLoading(false);
      setIsSelected(false);
      setOpenModal(true);
    } catch (error) {
      console.log("ERROR:", error);
      setIsLoading(false);
    }
  };

  const uploadCase = async (caseN) => {
    setCaseSelected(true);

    const formData = new FormData();
    formData.append("casename", caseN);
    formData.append("email", userEmail);
    formData.append("files", uploadedFile);

    try {
      const response = await axios.post(
        "https://upload.verisage.ai:5000/upload",
        formData
      );
      console.log(`files uploaded:`, response.data.files_uploaded);
    } catch (error) {
      console.error(`Error uploading files:`, error.response.data);
    }
  };
  
  const smallTabStyle = {
    paddingLeft: "5px",
    paddingRight: "5px",
    Margin: "10px",
    borderRadius: "20px",
  };

  useEffect(() => {
    let email = localStorage.getItem("email");
    setUserEmail(email);
    setuploadError("");
    console.log("hh4",planName)
    return () => {
      handleReset();
      setuploadError("");
      setErrorMessage("")
      // setIsHomeWork(false)
    };
  }, [isHomeWork]);

  const data = "Get premium";

  return (
    
    console.log("response--", planName),
    (
      <>
        <Chatbot
          userFile={userFile}
          planName={planName}
          useOCR={useOCR}
          setPremiumError={setPremiumError}
          isHomeWork={isHomeWork}
          setIsPremium={setIsPremium}
          wordsCount={wordsCount}
          shareableLink={shareableLink}
          setShareableLink={setShareableLink}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setUserFile={setUserFile}
          isSelectedMath={isSelectedMath}
          chatHistory={chatHistory}
          setChatHistory={setChatHistory}
          showSummaryChatbot={showSummaryChatbot}
          fileName={fileName}
          fileContent={fileContent}
          setPdfUrls={setPdfUrls}
          chat={chat}
          setChat={setChat}
          fileUrl={fileUrl}
          setFileUrl={setFileUrl}
        />
        {/* <Navbar
          setFileModal={setFileModal}
          showMasterListModal={showMasterListModal}
          setShowMasterListModal={setShowMasterListModal}
          fileModal={fileModal}
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          data={data}
          file={file}
          setFile={setFile}
        /> */}
        <div style={{ minHeight: "90vh", paddingTop: "50px" }}>
          <div
            style={{
              fontSize: "28px",
              fontWeight: "bolder",
              textAlign: "center",
              paddingTop: "5px",
            }}
          >
            {/* File Summarization Tool */}
          </div>

          <div
            style={{
              display: "flex",
              flex: 1,
              padding: "10px",
              // height: "100%",
              backgroundColor: "white",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;",
              borderRadius: "20px",
              margin: "10px",
            }}
          >
            <div
              style={{
                flex: 0.6,
                height: "100%",
                width: "100%",
                // borderRight: "1px solid #CCD1D1",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  height: "8%",
                  width: "90%",
                  fontSize: "18px",
                  marginLeft: "30px",
                  paddingTop: "10px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  display: "flex",
                  fontWeight: "bold",
                }}
              >
                {/* {response? "Summarized Text" :"File Summarization Tool"} */}
                <div></div>
                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(20, 114, 255)", color: "#fff", padding: "3px 15px", borderRadius: "2em", fontSize: "17px", fontWeight: "bold", cursor: "pointer" }}>Get Premium</div> */}
              </div>
              <div style={{ height: "fit-content", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <FileUploadComponent
                setHasMath={setHasMath}
                planName={planName}
                setuploadError={setuploadError}
                setPremiumError={setPremiumError} 
                premiumError={premiumError}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                setIsPremium={setIsPremium}
                isHomeWork={isHomeWork}
                handleReset={handleReset}
                usageData={usageData}
                setUsageData={setUsageData}
                isSelected={isSelected}
                  hasMath={hasMath}
                  setUserFile={setUserFile}
                  userFile={userFile}
                  assigFileName={assigFileName}
                  setAssigFileName={setAssigFileName}
                  sourceFileName={sourceFileName}
                  setSourceFileName={setSourceFileName}
                  shareableLink={shareableLink}
                  setShareableLink={setShareableLink}
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  useOCR={useOCR}
                  setUseOCR={setUseOCR}
                  setSelectedMath={setSelectedMath}
                  isSelectedMath={isSelectedMath}
                  response={response}
                  setResponse={setResponse}
                  type1Value={type1Value}
                  type2Value={type2Value}
                  setWordsCount={setWordsCount}
                  urlStats={urlStats}
                  fileContent={fileContent}
                  fileName={fileName}
                  file={file}
                  setFile={setFile}
                  curSummaryname={curSummaryname}
                  fileUrl={fileUrl}
                  setFileUrl={setFileUrl}
                  setShowSummaryChatbot={setShowSummaryChatbot}
                  setFileName={setFileName}
                  setFileContent={setFileContent}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  ocrProcessing={ocrProcessing}
                  setUploadedfile={setUploadedfile}
                  callOverviewOnce={callOverviewOnce}
                  wordsCount={wordsCount}
                  uploadError={uploadError}
                />
              </div>

              <div style={{
                display: "flex",
                flexDirection: "column"
              }}>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "10%",
                    marginTop: "10px",
                    fontSize: "18px",
                    fontWeight: "bold",
                    paddingLeft: "30px",
                  }}
                >
                  <div className={styles2.iconContainer}>
                  <InfoIcon
                    fontSize="small"
                    sx={{ color: "#0066cc", marginRight: "5px" }}
                  />
                  <div className={styles2.iconTooltip2}>
                    You can choose the type of document summary you want here.
                  </div>
                </div>
                  Summary Type
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1em 1em",
                    paddingLeft: "3em",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={buttonStyle4(type1Value === "topic")}
                        onClick={() => setType1Value("topic")}
                      >
                        TOPIC
                      </div>
                      <div
                        style={buttonStyle4(type1Value === "page")}
                        onClick={() => setType1Value("page")}
                      >
                        PAGE
                      </div>
                    </div>

                    {/* Second set of buttons */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      <div
                        style={buttonStyle4(type2Value === "short")}
                        onClick={() => setType2Value("short")}
                      >
                        SHORT
                      </div>
                      <div
                        style={buttonStyle4(type2Value === "long")}
                        onClick={() => setType2Value("long")}
                      >
                        LONG
                      </div>
                    </div>
                    {/* <Box
                  sx={{
                    width: "100%",
                    marginTop: "10px",
                    marginLeft: "30px",
                    backgroundColor: "#D7DBDD",
                    width: "fit-content",
                    borderRadius: "40px",
                    paddingX: "10px",
                  }}
                >
                  <Tabs onChange={handleType1Change} defaultValue={"topic"}>
                    <TabsList>
                      <Tab disabled={!fileContent} value={"topic"}>
                        Topic
                      </Tab>
                      <Tab disabled={!fileContent} value={"page"}>
                        Page
                      </Tab>
                    </TabsList>
                  </Tabs>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    marginTop: "5px",
                    marginLeft: "30px",
                    backgroundColor: "#D7DBDD",
                    width: "fit-content",
                    borderRadius: "40px",
                    paddingX: "10px",
                  }}
                >
                  <Tabs onChange={handleType2Change} defaultValue={"small"}>
                    <TabsList>
                      <Tab disabled={!fileContent} value={"small"}>
                        Short
                      </Tab>
                      <Tab disabled={!fileContent} value={"long"}>
                        Long
                      </Tab>
                    </TabsList>
                  </Tabs>
                </Box> */}
                  </div>
                  {!isHomeWork &&<div>
                    <div style={{ display:"flex",alignItems:"center",justifyContent:"center", fontSize: "15px", marginTop: "20px" }}>
                    <div className={styles2.iconContainer}>
                      <InfoIcon
                        fontSize="19"
                        sx={{ color: "#0066cc", marginRight: "5px" }}
                      />
                      <div className={styles2.iconTooltip}>Enable OCR if your document is a scan or image</div>
                    </div>
                      Document requires OCR Processing
                      <div className={styles2.switchContainer}>
                        <Switch checked={ocrProcessing} onChange={() => setOcrProcessing(!ocrProcessing)} />
                        <div className={styles2.tooltip}>Enable / Disable</div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        paddingTop: "6px",
                      }}
                    >
                      <div
                          className={selectedModel == 'better'?styles2.btn_custom:styles2.btn_custom_active}
                          onClick={() => setSelectedModel("good")}
                        >
                          SPEED
                          <div className={styles2.btn_custom_tooltip}>
                            Faster Result
                          </div>
                        </div>
                        <div
                          className={selectedModel == 'good'?styles2.btn_custom:styles2.btn_custom_active}
                          onClick={() =>{
                            setSelectedModel("better")
                          }}
                        >
                          DEPTH
                          <div className={styles2.btn_custom_tooltip}>
                            Deeper Context
                          </div>
                        </div>
                      {/* <div
                        style={buttonStyle3(selectedModel === "best")}
                        onClick={() => setSelectedModel("best")}
                      >
                        BEST
                      </div> */}
                    </div>
                  </div>}
                </div>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "start",
                    alignItems: "center",
                    padding: "0 1em",
                    paddingLeft: "2em",
                    gap: "10px",
                    width: "38%",
                  }}
                >
                  <div
                    onClick={() => {
                      handleReset();
                      setuploadError("");
                    }}
                    className={styles2.Resentcp}
                  >
                    RESET
                    <div className={styles2.hoverText}>
                      Select new file
                    </div>
                  </div>
                  {/* <div
                    style={buttonStyle5(isSelectedMath)}
                    onClick={() => {
                      setSelectedMath(!isSelectedMath)
                      if (isSelectedMath) {
                        handleReset()
                      }
                    }}

                  >
                    Math
                  </div> */}
                  <div></div>
                  <div className={styles2.generateContainer}>
      <p
        onClick={handleGenerate}
        className={styles2.generateText}
      >
        GENERATE
      </p>
      <div className={styles2.additionalText}>
        Process Summary
      </div>
    </div>
                </div>
              </div>
            </div>
            <ModalComponent />

            <div style={{ flex: 0.4 }}>
              {/* {(response || isLoading) && (
            <div
              style={{
                borderBottom: "1px solid #B2BABB",
                height: "8%",
                width: "90%",
                fontSize: "22px",
                marginLeft: "30px",
                paddingTop: "10px",
                fontWeight: "bold",
              }}
            >
              Summarized Text
            </div>
          )} */}
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default AdvancedSummary;
