// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
  text-align: center;
  margin-top: 4em;
}

.bannerImage {
  width: 100%;
  height: 45vh;
  background-color: rgb(15, 91, 206);
  margin-bottom: 50px;
  position: relative; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.bannerImage h1 {
  font-size: 55px;
  margin: 5px;
  font-family: sans-serif;
}

.date {
  top: 10px;
  color: white;
  font-size: 15px;
  font-family: sans-serif;
}


@media (min-width: 360px) and (max-width: 740px) {
  .bannerImage {
    height: 30vh; 
    width: 100%;
  }
  
  .bannerImage h1 {
    font-size: 20px; 
  }
  
  .date {
    font-size: 12px; 
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/SingleBlog/HomePage/AllPage/Image.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kCAAkC;EAClC,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,eAAe;EACf,uBAAuB;AACzB;;;AAGA;EACE;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".banner {\r\n  text-align: center;\r\n  margin-top: 4em;\r\n}\r\n\r\n.bannerImage {\r\n  width: 100%;\r\n  height: 45vh;\r\n  background-color: rgb(15, 91, 206);\r\n  margin-bottom: 50px;\r\n  position: relative; \r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center; \r\n  align-items: center; \r\n}\r\n\r\n.titleContainer {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  color: white;\r\n}\r\n\r\n.bannerImage h1 {\r\n  font-size: 55px;\r\n  margin: 5px;\r\n  font-family: sans-serif;\r\n}\r\n\r\n.date {\r\n  top: 10px;\r\n  color: white;\r\n  font-size: 15px;\r\n  font-family: sans-serif;\r\n}\r\n\r\n\r\n@media (min-width: 360px) and (max-width: 740px) {\r\n  .bannerImage {\r\n    height: 30vh; \r\n    width: 100%;\r\n  }\r\n  \r\n  .bannerImage h1 {\r\n    font-size: 20px; \r\n  }\r\n  \r\n  .date {\r\n    font-size: 12px; \r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
