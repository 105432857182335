import React, { useState } from 'react';
import styles from './menuPage.module.css';
import { LinearProgress, Box, Typography } from "@mui/material";
import { Key, CreditCard, MapPin, Edit, Settings, User, X, LogOut, List, Verified, VerifiedIcon, LockIcon } from 'lucide-react';

export default function MenuPage({setFileModal,usageData,showMenu ,setShowMenu ,setShowMasterListModal,showMasterListModal,logout,user}) {
  const readingTime = Math.floor(usageData["monthlywordused"] / 250 * 60);
  const writingTime = Math.floor(usageData["monthlywordused"] / 250) * 3600;
    function handleMaster(){
        setShowMasterListModal(!showMasterListModal);
        setFileModal(false);
        setShowMenu(!showMenu)
        console.log("kkk",usageData)
    }
    console.log("mee2",usageData);
    const groupedData = [
      ["dailyfileused", "monthlyfileused"],
      ["dailypromptused", "dailywordsused"],
      ["monthlypromptused", "monthlywordused"],
    ];
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerTitle}>{user && user.given_name}</div>
        <div><LockIcon/></div>
      </header>

      <div className={styles.profileInfo}>
        <div className={styles.avatar}>
          <img className={styles.avatar} src={ user && user.picture}/>
        </div>
        {/* <h2 className={styles.name}>{user.name}</h2> */}
        <p className={styles.name}>{ user && user.email}</p>
        
        {/* <div className={styles.iconContainer}>
          <div className={styles.icon}>
            <Key size={20} />
          </div>
          <div className={styles.icon}>
            <CreditCard size={20} />
          </div>
          <div className={styles.icon}>
            <MapPin size={20} />
          </div>
        </div> */}
      </div>

      {/* <div className={styles.syncCard}>
        <h3 className={styles.syncTitle}>
          Sync and personalise Chrome across your devices
        </h3>
        <button className={styles.syncButton}>
          Turn on sync...
        </button>
      </div> */}

      <ul className={styles.menuList}>
        <li className={styles.menuItem} onClick={handleMaster}>
          <List className={styles.menuIcon} />
          Master List
        </li>

        <div>
        <ul className={styles.menuList}>
  {groupedData.map((group, index) => (
    <li key={index} className={styles.menuItemRow}>
      {group.map((key) => {
        const value = usageData[key] ?? 0;

        // Map keys to display-friendly labels
        const labelMap = {
          dailyfileused: "Files used today",
          monthlyfileused: "Files used this month",
          dailypromptused: "Prompts used today",
          monthlypromptused: "Prompts used this month",
          dailywordsused: "Words used today",
          monthlywordused: "Words used this month",
        };

        // Determine whether to show daily or monthly stats
        const isPlanNone = usageData["plan_name"] === "none";
        const isDailyKey = key.includes("daily");
        const isMonthlyKey = key.includes("monthly");

        // If the plan is "none", show only daily stats
        if (isPlanNone && !isDailyKey) {
          return null;
        }

        // If the plan is not "none", show only monthly stats
        if (!isPlanNone && !isMonthlyKey) {
          return null;
        }

        // Determine the corresponding label and limit key for progress calculation
        const displayLabel = labelMap[key] || key;
        let limitKey = "";
        if (key.includes("dailyfileused")) limitKey = "dailyfilelimit";
        else if (key.includes("monthlyfileused")) limitKey = "monthlyfilelimit";
        else if (key.includes("dailypromptused")) limitKey = "dailypromptlimit";
        else if (key.includes("monthlypromptused")) limitKey = "monthlypromptlimit";
        else if (key.includes("dailywordsused")) limitKey = "dailywordlimit";
        else if (key.includes("monthlywordused")) limitKey = "monthlywordlimit";

        const maxProgress = usageData[limitKey] ?? 0;
        const progressValue = maxProgress > 0 ? Math.min((value / maxProgress) * 100, 100) : 0;

        return (
          <Box key={key} className={styles.progressItem}>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" sx={{ width: "155px" }}>
                <strong>{displayLabel}</strong>
              </Typography>
            </Box>

            {/* Progress Bar */}
            {/* <Box width="100%" mr={1}>
              <LinearProgress
                variant="determinate"
                value={progressValue}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#1976d2"
                  },
                }}
              />
            </Box> */}

            {/* Display Actual Value */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: String(maxProgress).length > 5 ? 'column' : 'row',
                width: '40px',
                marginLeft: '8px',
              }}
            >
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                {value}/
              </Typography>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                {maxProgress}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </li>
  ))}
</ul>




        </div>

        {/* <li className={styles.menuItem}>
          <Edit className={styles.menuIcon} />
          Customise profile
        </li>
        <li className={styles.menuItem}>
          <User className={styles.menuIcon} />
          Manage your Account
        </li> */}
        {/* <li className={styles.menuItem}>
          <Settings className={styles.menuIcon} />
        Settings
        </li> */}
        <li className={styles.menuItem} style={{ color: "white" }}>
  Total writing time saved:
  {Math.floor(usageData["dailywordsused"] / 250) ? (
    <>
      <span style={{ color: "white",marginRight:"10px",marginLeft:"10px" }}> {Math.floor(usageData["dailywordsused"] / 250)} hr</span>  
      <span style={{ color: "white",marginRight:"10px" }}> {Math.floor((usageData["dailywordsused"] % 250) / (250 / 60))} min</span>
    </>
  ) : (
    <span style={{ color: "white",marginRight:"10px" }}> {Math.floor((usageData["dailywordsused"] % 250) / (250 / 60))} min</span>
  )}
</li>
<li className={styles.menuItem} style={{ color: "white" }}>
  Total reading time saved:
  {Math.floor(usageData["dailywordsused"] / 3600) ? (
    <>
      <span style={{ color: "white",marginRight:"10px",marginLeft:"10px" }}> {Math.floor(usageData["dailywordsused"] / 3600)} hr</span>  
      <span style={{ color: "white",marginRight:"10px" }}> {Math.floor((usageData["dailywordsused"] % 3600) / 60)} min</span>
    </>
  ) : (
    <span style={{ color: "white",marginRight:"10px",marginLeft:"10px" }}> {Math.floor((usageData["dailywordsused"] % 3600) / 60)} min</span>
  )}
</li>
<li className={styles.menuItem} style={{ color: "white", }}>
  Pages Summarized this month:
  {Math.floor(usageData["monthlywordused"] / 300)}
</li>
<li className={styles.menuItem} onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }>
          <LogOut className={styles.menuIcon} />
          Log out
        </li>


      </ul>
    </div>
  );
}

